import { createStyles, makeStyles } from '@mui/styles';
import React, { SetStateAction, useRef } from 'react';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from 'react-zoom-pan-pinch';
import { useMatchMedia } from 'src/common/useMatchMedia';
import MomentVideo from 'src/components/VideoPlayer/MomentVideo';
import WatchPlayer from 'src/components/VideoPlayer/WatchPlayer';

const useStyles = makeStyles(
  () =>
    createStyles({
      rootAvatar: {
        zIndex: 15,
        position: 'relative'
      },
      avatarFrame: {
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '50%',
        cursor: 'pointer'
      },
      boxZoom: {
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '1001'
      }
    }),
  { index: 1 }
);

interface Props {
  medias?: any;
  postMedia?: any;
  isFullScreen?: boolean;
  type?: string;
  typeWatch?: string;
  typeLive?: string;
  setWidthLeft?: React.Dispatch<SetStateAction<any>>;
  time?: any;
  indexMedia?: any;
  videoRef?: any;
  open?: boolean;
  handleClose?: any;
  setPostData?: any;
  mutedVideo?: boolean;
  setMutedVideo?: React.Dispatch<SetStateAction<boolean>>;
  openPreview?: boolean;
  setOpenPreview?: React.Dispatch<SetStateAction<boolean>>;
  controls?: any;
  openDialog?: boolean // Bắt sự kiện khi đóng dialog
}

const MediaPreview = (props: Props) => {
  const {
    medias,
    postMedia,
    isFullScreen,
    type,
    typeWatch,
    typeLive,
    setWidthLeft,
    time,
    indexMedia,
    videoRef,
    open,
    handleClose,
    setPostData,
    mutedVideo,
    setMutedVideo,
    openPreview,
    setOpenPreview,
    controls
  } = props;
  const isMobile = useMatchMedia('(min-width:500px)', true);
  const imageRef: any = React.useRef(null);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const classes = useStyles();

  React.useLayoutEffect(() => {
    if (transformComponentRef.current) {
      const { centerView } = transformComponentRef.current;
      centerView(); // Gọi hàm centerView khi component được render
    }
  }, [transformComponentRef.current]);

  const MediaImage = style => {
    return (
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        ref={transformComponentRef}
        wheel={{
          disabled: true
        }}
        panning={{
          velocityDisabled: true
        }}
      >
        {utils => (
          <React.Fragment>
            <div className={classes.boxZoom}>{controls({ ...utils })}</div>

            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%'
              }}
              contentStyle={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                id="image_media"
                style={{
                  ...style,
                  maxWidth: !isMobile
                    ? '100%'
                    : isFullScreen
                    ? '100vw'
                    : 'calc(100vw - 370px)',
                  maxHeight: !isMobile ? '40vh' : '100vh'
                }}
                src={medias[0].show_url ?? medias[0].url}
                alt="preview media"
                ref={imageRef}
              />
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    );
  };

  const renderMedia = () => {
    if (medias && medias.length) {
      if (
        medias[0].url.includes('.mp4') ||
        medias[0].url.includes('.mov') ||
        medias[0].url.includes('.qt') ||
        medias[0].remote_url
      ) {
        return (
          <WatchPlayer
            video={medias[0]}
            videoPost={postMedia}
            typePage={
              typeLive
                ? 'preview_live'
                : postMedia?.post_type === 'watch'
                ? 'preview-watch'
                : postMedia?.post_type === 'moment'
                ? 'preview-moment'
                : 'preview-video'
            }
            type={type}
            typeWatch={typeWatch}
            typeLive={typeLive}
            styleVideo={{
              objectFit: 'contain !important',
              margin: 'auto',
              width: 'fit-content',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              maxHeight: postMedia?.post_type === 'moment' ? 'none' : '506px'
            }}
            handleClose={handleClose}
            setPostData={setPostData}
            openPreview={openPreview}
            setOpenPreview={setOpenPreview}
            closeDialogVideo={open} // Thêm để bắt sự kiện đóng dialog
            autoPlay={true}
          />
        );
      } else {
        if (medias[0]?.frame) {
          return (
            <div className={classes.rootAvatar}>
              <MediaImage style={{ borderRadius: '50%' }} />
              {medias[0]?.frame && (
                <div
                  className={classes.avatarFrame}
                  style={{
                    backgroundImage: `url(${medias[0]?.frame?.url})`
                  }}
                ></div>
              )}
            </div>
          );
        } else {
          return <MediaImage />;
        }
      }
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: typeWatch === 'watch-selected' ? 'calc(100% - 45px)' : '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {typeWatch === 'watch-selected' ? null : (
        <div
          style={
            medias?.[0]?.typeWatch === 'video'
              ? {
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${
                    medias[0]?.show_url ?? medias[0]?.preview_url
                  })`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  position: 'absolute',
                  opacity: '0.4',
                  filter: 'blur(15px)'
                }
              : {}
          }
        ></div>
      )}

      {/* <TransformComponent> */}
      {renderMedia()}
      {/* </TransformComponent> */}
    </div>
  );
};

export default MediaPreview;
