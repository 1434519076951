import React from 'react';
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  useTheme,
  Theme
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { likeFollowPage } from 'src/apis/socialPages.api';
import ButtonCustom from 'src/components/Button/Button';
import bannerDefault from 'src/assets/images/group_cover.png';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { formatDateTimeToStringDateTime } from 'src/common/string';
import { joinEventApi } from 'src/apis/socialEvent.api';
import {
  interesteRecruitApi,
  noInteresteRecruitApi
} from 'src/apis/socialRecruit.api';
import { interesteGrowApi, noInteresteGrowApi } from 'src/apis/socialGrow.api';
import {
  interesteCourseApi,
  noInteresteCourseApi
} from 'src/apis/socialCourse.api';
import { buttonColor, lineClamp } from 'src/constants/styles';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { updatePostSuccessAction } from 'src/store/action/socialPostAction';
import { limitText } from 'src/common/limitText';

const useStyles = makeStyles((theme: Theme) => ({
  titlePost: {
    fontSize: '17px',
    fontWeight: '600 !important',
    width: '70%',
    wordBreak: 'break-word',
    ...(lineClamp(1) as any)
  }
}));

function GroupPageShared(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    post,
    group,
    page,
    type,
    event,
    project,
    recruit,
    course,
    music,
    postShare
  } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isLikePage, setIsLikePage] = React.useState<any>(false);
  const [isActionEvent, setIsActionEvent] = React.useState<any>('');
  const [isActionProject, setIsActionProject] = React.useState<any>('');
  const [isActionRecruit, setIsActionRecruit] = React.useState<any>('');
  const [isActionCourse, setIsActionCourse] = React.useState<any>('');

  React.useEffect(() => {
    if (page) {
      if (page.page_relationship?.like) {
        setIsLikePage(true);
      } else {
        setIsLikePage(false);
      }
    } else if (event) {
      if (event.event_relationship?.status === 'going') {
        setIsActionEvent('going');
      } else if (event.event_relationship?.status === 'interested') {
        setIsActionEvent('interested');
      } else {
        setIsActionEvent('');
      }
    } else if (recruit) {
      if (recruit.recruit_relationships?.follow_recruit) {
        setIsActionRecruit(true);
      } else {
        setIsActionRecruit(false);
      }
    } else if (project) {
      if (project.project_relationship?.follow_project) {
        setIsActionProject(true);
      } else {
        setIsActionProject(false);
      }
    } else if (course) {
      if (course.course_relationships?.follow_course) {
        setIsActionCourse(true);
      } else {
        setIsActionCourse(false);
      }
    }
  }, []);
  const handleLikeFollowPage = async (action: any) => {
    try {
      let response: any = await likeFollowPage(page.id, action);
      if (response.status === 200) {
        setIsLikePage(prev => !prev);
      }
    } catch (error) {}
  };

  const handleJoin = async (typeJoin: any) => {
    if (event) {
      let response = await joinEventApi(event.id, {
        status: typeJoin
      });
      if (response.status === 200) {
        dispatch(
          updatePostSuccessAction({
            ...post,
            shared_event: {
              ...event,
              event_relationship: {
                ...event.event_relationship,
                status: response.data.status ?? ''
              }
            }
          })
        );
        if (response.data.status) {
          setIsActionEvent(response.data.status);
        } else {
          setIsActionEvent('');
        }
      }
    } else if (recruit) {
      let response;
      if (typeJoin === 'interested' && recruit.id) {
        response = await interesteRecruitApi(recruit.id);
      } else if (typeJoin === 'no-interested' && recruit.id) {
        response = await noInteresteRecruitApi(recruit.id);
      }
      if (response.status === 200) {
        dispatch(
          updatePostSuccessAction({
            ...post,
            shared_recruit: {
              ...recruit,
              recruit_relationships: {
                ...recruit.recruit_relationships,
                follow_recruit: typeJoin === 'interested' ? true : false
              }
            }
          })
        );
        setIsActionRecruit(prev => !prev);
      }
    } else if (project) {
      let response;
      if (typeJoin === 'interested' && project.id) {
        response = await interesteGrowApi(project.id);
      } else if (typeJoin === 'no-interested' && project.id) {
        response = await noInteresteGrowApi(project.id);
      }
      if (response.status === 200) {
        setIsActionProject(prev => !prev);
        dispatch(
          updatePostSuccessAction({
            ...post,
            shared_project: {
              ...project,
              project_relationship: {
                ...project.project_relationship,
                follow_project: typeJoin === 'interested' ? true : false
              }
            }
          })
        );
      }
    } else {
      let response;
      if (typeJoin === 'interested' && course.id) {
        response = await interesteCourseApi(course.id);
      } else if (typeJoin === 'no-interested' && course.id) {
        response = await noInteresteCourseApi(course.id);
      }
      if (response.status === 200) {
        dispatch(
          updatePostSuccessAction({
            ...post,
            shared_course: {
              ...course,
              course_relationships: {
                ...course.course_relationships,
                follow_course: typeJoin === 'interested' ? true : false
              }
            }
          })
        );
        setIsActionCourse(prev => !prev);
      }
    }
  };

  const renderName = () => {
    if (isActionEvent === 'going') {
      return 'Sẽ tham gia';
    } else if (isActionEvent === 'interested') {
      return 'Quan tâm';
    } else {
      return null;
    }
  };
  const checkInPostPage = !!post;
  return (
    <Box
      sx={
        checkInPostPage
          ? { width: type === 'create' ? '96%' : '100%', position: 'relative' }
          : {
              width: type === 'create' ? '96%' : '100%',
              position: 'relative',
              pointerEvents: 'none'
            }
      }
    >
      <Card
        style={{
          width: '100%',
          borderRadius: postShare ? '0px 0px 10px 10px' : 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: 'none',
          backgroundColor:
            theme.palette.mode === 'light' ? '#f0f2f5' : '#3a3b3c'
        }}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={() => {
          if (group) {
            history.push(`/group/${group?.username ?? group.id}`);
          } else if (page) {
            history.push(`/page/${page.id}`);
          } else if (event) {
            history.push(`/event/${event.id}/about`);
          } else if (project) {
            history.push(`/grow/${project.id}/about`);
          } else if (recruit) {
            history.push(`/recruit/${recruit.id}/about`);
          } else if (course) {
            history.push(`/course/${course.id}/about`);
          } else if (music) {
            history.push(`/music_space/track/${music.id}`);
          }
        }}
      >
        <CardMedia
          component="img"
          height="245px"
          image={
            (group?.banner?.url || group?.banner?.preview_url) ??
            (page?.banner?.url || page?.banner?.preview_url) ??
            (event?.banner?.url || event?.banner?.preview_url) ??
            (project?.banner?.url || project?.banner?.preview_url) ??
            (recruit?.banner?.url || recruit?.banner?.preview_url) ??
            (course?.banner?.url || course?.banner?.preview_url) ??
            (music?.banner?.url || music?.banner?.preview_url) ??
            bannerDefault
          }
          alt="Không hiển thị ảnh"
          style={{
            objectFit: 'cover'
          }}
        />
        <CardContent
          sx={{
            marginTop: 0,
            paddingTop: '5px',
            paddingBottom: '5px !important',
            minHeight: '80px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          {group && (
            <>
              <Typography fontWeight="400" fontSize="15px">
                {group?.is_private ? 'Nhóm riêng tư' : 'Nhóm công khai'}
                &nbsp;-&nbsp;
                {group?.member_count} thành viên
              </Typography>
              <Typography fontWeight="500" fontSize="18px">
                {group?.title}
              </Typography>
            </>
          )}
          {event && (
            <>
              <Typography
                sx={{ fontSize: '13px', fontWeight: 400, color: '#f0284a' }}
              >
                {!!event.start_time &&
                  formatDateTimeToStringDateTime(event.start_time, true)}
              </Typography>
              <Typography className={classes.titlePost}>
                {event.title}
              </Typography>
              {!!event.address && (
                <Typography
                  sx={{ fontSize: '15px', fontWeight: 400, color: '#656761' }}
                >
                  {event.address}
                </Typography>
              )}

              <Typography
                sx={{ fontSize: '15px', fontWeight: 400, color: '#656761' }}
              >
                {event.users_interested_count} người quan tâm
              </Typography>
            </>
          )}
          {project && (
            <>
              <Typography
                sx={{ fontSize: '13px', fontWeight: 400, color: '#f0284a' }}
              >
                Thời gian kết thúc: {''}
                {!!project.due_date &&
                  formatDateTimeToStringDateTime(project.due_date, true)}
              </Typography>
              <Typography
                sx={{
                  fontSize: '17px',
                  fontWeight: 600,
                  width: '77%',
                  wordBreak: 'break-word'
                }}
              >
                {limitText(project.title, 90)}
              </Typography>
              <Typography
                sx={{ fontSize: '15px', fontWeight: 400, color: '#656761' }}
              >
                {project.backers_count} người ủng hộ
              </Typography>
            </>
          )}
          {music && (
            <>
              <Typography
                sx={{
                  fontSize: '17px',
                  fontWeight: 600,
                  width: '77%'
                }}
              >
                {`${music.name} | ${music.page_owner.title}`}
              </Typography>
              <Typography
                sx={{ fontSize: '15px', fontWeight: 400, color: '#656761' }}
              >
                {music.views_count} lượt nghe
              </Typography>
            </>
          )}
          {recruit && (
            <>
              <Typography
                sx={{ fontSize: '13px', fontWeight: 400, color: '#f0284a' }}
              >
                Hạn nộp hồ sơ: {''}
                {!!recruit.due_date &&
                  formatDateTimeToStringDateTime(recruit.due_date, true)}
              </Typography>
              <Typography className={classes.titlePost}>
                {recruit.title}
              </Typography>
              <Typography
                sx={{ fontSize: '15px', fontWeight: 400, color: '#656761' }}
              >
                {recruit.followers_count} người quan tâm
              </Typography>
            </>
          )}
          {course && (
            <>
              <Typography
                sx={{ fontSize: '13px', fontWeight: 400, color: '#f0284a' }}
              >
                {!!course.created_at &&
                  formatDateTimeToStringDateTime(course.created_at, true)}
              </Typography>
              <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>
                {course.title}
              </Typography>
              <Typography
                sx={{ fontSize: '15px', fontWeight: 400, color: '#656761' }}
              >
                {course.followers_count} người quan tâm
              </Typography>
            </>
          )}
          {page && (
            <ListItem sx={{ paddingLeft: 0 }}>
              <ListItemAvatar>
                <Avatar
                  style={{
                    width: 45,
                    height: 45
                  }}
                  src={page.avatar_media?.url}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography fontSize="15px" fontWeight="500">
                    {page.title}
                  </Typography>
                }
                secondary={
                  <Typography fontSize="12px" fontWeight="400">
                    {page.page_categories[0]?.text}
                  </Typography>
                }
              />
            </ListItem>
          )}
        </CardContent>
      </Card>
      {event && !event.event_relationship?.host_event ? (
        isActionEvent === '' ? (
          <ButtonInherit
            label="Quan tâm"
            icon={
              <i
                style={{
                  color: 'text.primary',
                  fontSize: 17
                }}
                className="fa-light fa-star"
              ></i>
            }
            style={{
              width: '131.5px',
              fontSize: 15,
              position: 'absolute',
              right: '10px',
              bottom: '20px'
            }}
            action={() => handleJoin('interested')}
          />
        ) : (
          <IconButtonOptions
            name={renderName()}
            disabled={checkInPostPage ? false : true}
            startIcon={'fa-solid fa-circle-check'}
            startIconStyle={{
              fontSize: '16px',
              color: buttonColor.backgroundColor,
              margin: '0px 5px'
            }}
            styleNameButton={{ color: buttonColor.backgroundColor }}
            icon={
              <i
                style={{ fontSize: '16px', color: buttonColor.backgroundColor }}
                className="fa-solid fa-caret-down"
              ></i>
            }
            openPopup={true}
            style={{
              margin: '0px 16px',
              width: 'auto',
              backgroundColor:
                theme.palette.mode === 'dark' ? '#4B4B4B' : '#E4E6EB',
              position: 'absolute',
              right: '10px',
              bottom: '20px'
            }}
            options={[
              [
                {
                  id: 'going',
                  label: 'Sẽ tham gia',
                  action: () => handleJoin('going'),
                  checkbox: true
                },
                {
                  id: 'interested',
                  label: 'Quan tâm',
                  action: () => handleJoin('interested'),
                  checkbox: true
                },
                {
                  id: 'no-going',
                  label: 'Không tham gia',
                  action: () => handleJoin(''),
                  checkbox: true
                }
              ]
            ]}
            optionCheckDefault={isActionEvent}
            styleListMenu={{ minWidth: '350px' }}
          />
        )
      ) : null}
      {(recruit && !recruit?.recruit_relationships?.host_recruit) ||
      (project && !project?.project_relationship?.host_project) ||
      (course && !course?.course_relationships?.host_course) ? (
        <ButtonInherit
          label="Quan tâm"
          icon={
            <i
              style={{
                fontSize: '16px',
                color:
                  isActionRecruit || isActionProject || isActionCourse
                    ? buttonColor.backgroundColor
                    : theme.palette.text.primary
              }}
              className={
                isActionRecruit || isActionProject || isActionCourse
                  ? 'fa-solid fa-star'
                  : 'fa-light fa-star'
              }
            ></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? isActionRecruit || isActionProject || isActionCourse
                  ? '#263951'
                  : '#4B4B4B'
                : isActionRecruit || isActionProject || isActionCourse
                ? '#E7F3FF'
                : '#E4E6EB',
            color:
              isActionRecruit || isActionProject || isActionCourse
                ? buttonColor.backgroundColor
                : theme.palette.text.primary,
            width: '131.5px',
            fontSize: 15,
            position: 'absolute',
            right: '10px',
            bottom: '20px',
            whiteSpace: 'nowrap'
          }}
          action={() =>
            handleJoin(
              isActionRecruit || isActionProject || isActionCourse
                ? 'no-interested'
                : 'interested'
            )
          }
        />
      ) : null}

      {/* {music ? (
        <ButtonInherit
          label="Nghe"
          icon={
            <i
              style={{
                fontSize: '16px'
              }}
              className={'fa-solid fa-play'}
            ></i>
          }
          style={{
            backgroundColor:
              theme.palette.mode === 'dark' ? '#4B4B4B' : '#E4E6EB',
            color: theme.palette.text.primary,
            width: '131.5px',
            fontSize: 15,
            position: 'absolute',
            right: '10px',
            bottom: '20px',
            whiteSpace: 'nowrap'
          }}
        />
      ) : null} */}
      {page && (
        <ButtonCustom
          label={isLikePage ? 'Đã thích' : 'Thích'}
          icon={
            <i
              style={{
                color: isLikePage ? '#050505' : buttonColor.backgroundColor
              }}
              className="fa-solid fa-thumbs-up"
            ></i>
          }
          style={{
            backgroundColor: isLikePage ? '#e4e6eb' : '#e7f3ff',
            color: isLikePage ? '#050505' : buttonColor.backgroundColor,
            width: isLikePage ? '130px' : '110px',
            position: 'absolute',
            right: '10px',
            bottom: '20px'
          }}
          disabled={type === 'create'}
          action={() => {
            handleLikeFollowPage(isLikePage ? 'unlikes' : 'likes');
          }}
        />
      )}
    </Box>
  );
}

export default GroupPageShared;
