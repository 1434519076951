import React from 'react';
import AutocompleteTimeOfDay from '../AutoComplete/AutocompleteTimeOfDay';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import {
  TextField,
  TextFieldProps,
  OutlinedInputProps,
  Box
} from '@mui/material';

const RedditTextField = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : 'transparent',
    padding: '16px 8px 2px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color'
      // 'box-shadow'
    ]),
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#4f4f4f'
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#4f4f4f',
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    },
    '& .MuiFilledInput-input': {
      padding: '8px 4px'
    }
  },
  '& .MuiFormLabel-root': {
    marginLeft: '40px'
  }
}));

function TimeDate(props) {
  const {
    placeholder,
    field,
    formik,
    name,
    label,
    type,
    minDay,
    maxDay,
    minTime,
    maxTime,
    time,
    setTime,
    date,
    setDate,
    styleFieldDate,
    styleTimeInput,
    styleTime,
    styleInput,
    typeRecruit,
    index,
    setNotificationLeave,
    disabled
  } = props;

  const checkDay = () => {
    if (typeRecruit === 'recruit') {
      const currentDate = new Date(minDay);
      const nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
      return nextDate;
    } else {
      return minDay;
    }
  };
  const dataDetailInformation = formik.values
    ?.product_attribute_informations_attributes
    ? formik.values?.product_attribute_informations_attributes[index]?.value
        ?.value
    : null;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '8px 0px',
        ...styleTime
      }}
    >
      <div style={{ width: '100%', display: 'flex' }}>
        {type === 'date' && (
          <Box style={styleFieldDate ? styleFieldDate : { width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled={disabled}
                maxDate={maxDay ?? undefined}
                minDate={checkDay() ?? undefined}
                inputFormat={'dd-MM-yyyy'}
                label={placeholder}
                value={(() => {
                  // IIFE
                  if (date) return date;
                  if (formik) {
                    if (formik.values[name]) return formik.values[name];
                    if (dataDetailInformation) return dataDetailInformation;
                  }
                  return null;
                })()}
                onChange={newValue => {
                  setNotificationLeave && setNotificationLeave(true);
                  if (formik) {
                    if (field) formik.setFieldValue(field, { value: newValue });
                    else formik.setFieldValue(name, newValue);
                  } else if (setDate) {
                    setDate(newValue);
                  }
                }}
                renderInput={params => (
                  <RedditTextField
                    {...params}
                    variant="filled"
                    label={label}
                    name={name}
                    placeholder={placeholder}
                    onFocus={e => e.target.blur()}
                    sx={{
                      width: '100%',
                      fontWeight: '700',
                      borderRadius: '15px',
                      marginRight: '2px',
                      ...styleInput
                    }}
                  />
                )}
                InputAdornmentProps={{
                  position: 'start',
                  variant: 'standard'
                }}
              />
            </LocalizationProvider>

            <FormHelperText sx={{ color: '#f00' }}>
              {formik.errors.product_attribute_informations_attributes?.[index]}
              {/* {formik?.errors[name] && 'Vui lòng chọn ngày hợp lệ'} */}
            </FormHelperText>
          </Box>
        )}
        {type === 'time' && (
          <AutocompleteTimeOfDay
            disabled={disabled}
            minTime={minTime}
            maxTime={maxTime}
            formik={formik ? formik : null}
            time={time}
            setTime={setTime}
            name={name}
            placeholder={placeholder || 'Đang mở cửa'}
            label={label || 'Giờ mở cửa'}
            styleTextField={{
              width: '160px',
              marginLeft: '2px',
              borderRadius: '15px',
              '& .MuiFilledInput-root': {
                borderRadius: '15px'
              },
              ...styleTimeInput
            }}
          />
        )}
      </div>
    </div>
  );
}

export default TimeDate;
