/* eslint-disable react-hooks/exhaustive-deps */ import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { getPostDetailApi } from 'src/apis/socialPost.api';
import PostListComment from './Comment';
import PostActionButton from './PostActionButton';
import PostActionComment from './PostActionComment';
import PostActionInformation from './PostActionInformation';

import { createStyles, makeStyles, useTheme } from '@mui/styles';
import {
  publishSchedulePostGroupApi,
  updateSchedulePostGroupApi
} from 'src/apis/socialGroup';
import {
  publicScheduledPostPage,
  updateScheduledPostPage
} from 'src/apis/socialPages.api';
import {
  createUpdatePostApi,
  getCommentPostApi,
  getPreviewUrlApi
} from 'src/apis/socialPost.api';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import DialogSchedulePost from 'src/components/Dialog/DialogSchedulePost';
import Loading from 'src/components/Loading/Loading';
import { buttonColor } from 'src/constants/styles';
import { urlify } from 'src/helpers/common';
import {
  commentTotal,
  getSchedulePostReq
} from 'src/store/action/socialPostAction';
import { uploadMediaSaga } from 'src/store/saga/socialPostSaga';
import { ResponseApi } from 'src/@types/api';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';
import produce from 'immer';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      lineClamp: {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
      },
      boxVideo: {
        display: 'flex',
        padding: '0px 0px 8px 34px'
      }
    }),
  { index: 1 }
);

interface Props {
  post?: any;
  setPostData?: React.Dispatch<React.SetStateAction<any>> | any;
  setMediaData?: React.Dispatch<React.SetStateAction<any>> | any;
  type?: String;
  styleWatch?: any;
  typeAction?: String;
  typeLive?: String;
  chooseFocus?: any;
  setChooseFocus?: React.Dispatch<React.SetStateAction<any>> | any;
  typePost?: String;
  typeComment?: String;
}

const Index = React.memo((props: Props) => {
  const {
    post,
    setPostData,
    setMediaData,
    type,
    styleWatch,
    typeAction,
    typeLive,
    chooseFocus,
    setChooseFocus,
    typePost,
    typeComment = 'default'
  } = props;
  const scrollRef: any = React.useRef();
  const classes = useStyles();
  const theme = useTheme() as any;
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [commentInfo, setCommentInfo] = React.useState<any>({
    commentTotal: post.replies_total,
    reactions: post.reactions,
    shareCount: post.reblogs_count
  });
  const [showComment, setShowComment] = React.useState(false);
  const [listComment, setListComment] = React.useState<any>([]);
  const [visibility, setVisibility] = React.useState('newest');
  const [reactionCount, setReactionCount] = React.useState<any>(0);
  const [gifSelected, setGifSelected] = React.useState<any>(null);
  const [stickySelected, setStickySelected] = React.useState<any>(null);
  const [meReaction, setMeReaction] = React.useState<any>([]);
  const [focus, setFocus] = React.useState<any>(false);
  const [openSchedule, setOpenSchedule] = React.useState(false);
  const [listReaction, setListReaction] = React.useState<any>([]);
  const [buttonSelected, setButtonSelected] = React.useState(
    typeLive ? 'chat' : ''
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const roleInteract: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit
  );
  const listActionLive = [
    [
      {
        id: 'save_video',
        label: 'Lưu video',
        icon: 'fa-light fa-bookmark',
        styleIcon: {
          fontSize: '20px',
          color: theme.palette.mode === 'dark' ? '#fff' : '#050505'
        },
        description: 'Thêm vào mục Video đã lưu'
      },
      {
        id: 'copy_link',
        label: 'Sao chép liên kết',
        icon: 'fa-light fa-link',
        styleIcon: {
          fontSize: '20px',
          color: theme.palette.mode === 'dark' ? '#fff' : '#050505'
        }
      },
      {
        id: 'follow',
        label: `Theo dõi ${post?.page?.title}`,
        icon: 'fa-light fa-folder-medical',
        styleIcon: {
          fontSize: '20px',
          color: theme.palette.mode === 'dark' ? '#fff' : '#050505'
        },
        description:
          'Bắt đầu xem video trong danh sách xem và bài viết trên Bảng feed'
      }
    ],
    [
      {
        id: 'report_videos',
        label: 'Báo cáo video',
        icon: 'fa-light fa-circle-exclamation',
        styleIcon: {
          fontSize: '20px',
          color: theme.palette.mode === 'dark' ? '#fff' : '#050505'
        },
        description: 'Tôi lo ngại về video này'
      }
    ]
  ];
  const meInfo = useSelector((state: any) => state.meReducer.info);

  React.useEffect(() => {
    if (post && Object.keys(post).length) {
      setMeReaction(post.favourites);
    }
  }, [post.viewer_reaction]);
  React.useEffect(() => {
    if (commentInfo?.reactions) {
      setListReaction(commentInfo?.reactions);
    }
  }, [JSON.stringify(commentInfo?.reactions)]);
  const handleGetComment = async (
    postId,
    params,
    setState,
    setHasMore: any = null
  ) => {
    setIsLoading && setIsLoading(true);
    let response: ResponseApi = await getCommentPostApi(postId, params);
    if (response.status === 200) {
      setIsLoading && setIsLoading(false);
      if (params?.max_id) {
        //see more comment
        typeComment === 'liveChat'
          ? setState(prev => {
              return [...prev, ...response.data].reverse();
            })
          : setState(prev => {
              return [...prev, ...response.data];
            });
      }
      if (!params?.max_id) {
        //sort comment
        typeComment === 'liveChat'
          ? setState(response.data?.reverse())
          : setState(response.data);
      }

      if (response.data.length < 10) {
        setHasMore && setHasMore(false);
      } else {
        setHasMore && setHasMore(true);
      }
    }
  };

  function handleBackClick() {
    const inputElement: any = document.getElementById(
      `textInputCommentBox${post.id}`
    );
    inputElement.focus();
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    setTimeout(() => {
      scrollRef?.current?.firstChild.focus();
    }, 1000);
  }

  const handleTextContent = textContent => {
    const regex = /@\[([^\]]+)\]\((\d+)\)/g;
    let contentPost = textContent.replace(regex, '[$2]');
    return contentPost;
  };

  const handlePostNow = async () => {
    if (type && type === 'page' && post?.page?.id && post?.id_scheduled_post) {
      let res = await publicScheduledPostPage(
        post?.page?.id,
        post?.id_scheduled_post
      );
      if (res.status === 200) {
        dispatch(getSchedulePostReq(post?.page?.id, 'page'));
      }
    } else if (
      type &&
      type === 'stream_group' &&
      post?.group?.id &&
      post?.id_scheduled_post
    ) {
      let res = await publishSchedulePostGroupApi(
        post?.group?.id,
        post?.id_scheduled_post
      );
      if (res.status === 200) {
        dispatch(getSchedulePostReq(post?.group?.id, 'group'));
      }
    }
  };

  const handleUpdateScheduledPost = async (
    typeAction: any,
    scheduled_post: any
  ) => {
    if (type && type === 'page' && post?.page?.id && post?.id_scheduled_post) {
      let res = await updateScheduledPostPage(
        post?.page?.id,
        post?.id_scheduled_post,
        { scheduled_at: scheduled_post.scheduled_at }
      );
      if (res.status === 200) {
        dispatch(getSchedulePostReq(post?.page?.id, 'page'));
      }
    } else if (
      type &&
      type === 'stream_group' &&
      post?.group?.id &&
      post?.id_scheduled_post
    ) {
      let res = await updateSchedulePostGroupApi(
        post?.group?.id,
        {
          scheduled_status_id: post?.id_scheduled_post
        },
        { scheduled_at: scheduled_post.scheduled_at }
      );
      if (res.status === 200) {
        dispatch(getSchedulePostReq(post?.group?.id, 'group'));
      }
    }
  };

  const handleCloseSchedule = type => {
    setOpenSchedule(false);
  };
  const handleCheckLink = async (linkFirst: any, card: any) => {
    try {
      let res: any = await getPreviewUrlApi({
        urls: [linkFirst + '']
      });
      if (res.status === 200) {
        card = res.data[0];
      }
    } catch (error: any) {}
  };

  const createComment = async (data: any, type, functionUpdate) => {
    let _files: any = data?.files ?? [];
    let listIdMedia;
    let date = new Date();
    let common: any = {
      id: Math.random(),
      uuid: Math.random(),
      account: meInfo,
      page_owner: roleInteract?.page_owner_id ? roleInteract : null,
      content: handleTextContent(data.textInputCommentBox),
      in_reply_to_id: data.in_reply_to_id,
      created_at: date,
      pre_comment: true,
      haveError: false,
      status_tags: data?.status_tags
    };
    let preResponse: any =
      type === 'create'
        ? _files?.length === 0
          ? {
              ...common,
              card:
                gifSelected || stickySelected
                  ? {
                      title: '',
                      description: stickySelected ? 'sticky' : '',
                      provider_name: gifSelected ? 'GIPHY' : '',
                      link:
                        gifSelected ??
                        stickySelected?.url ??
                        stickySelected?.link
                    }
                  : null
            }
          : {
              ...common,
              media_attachments: [_files]
            }
        : {
            id: data.id,
            account: meInfo,
            content: handleTextContent(data.textInputCommentBox),
            in_reply_to_id: data.in_reply_to_id,
            created_at: date,
            pre_comment: true
          };
    if (type === 'create') {
      typeComment === 'liveChat'
        ? setListComment(prev => [...prev, preResponse])
        : setListComment(prev => [preResponse, ...prev]);
    } else {
      setListComment(prev =>
        prev.map(el =>
          el?.id === data.id
            ? {
                ...el,
                content: handleTextContent(data.textInputCommentBox),
                pre_comment: true
              }
            : el
        )
      );
    }
    let media_ids;
    if (_files?.length === 0) {
      media_ids = [];
    } else if (_files && !_files[0]?.id) {
      media_ids = _files;
    }
    let _data: any =
      type === 'create'
        ? {
            id: preResponse.id,
            status: handleTextContent(data.textInputCommentBox),
            in_reply_to_id: data.in_reply_to_id,
            sensitive: false,
            media_ids: _files,
            spoiler_text: '',
            visibility: 'public',
            poll: null,
            extra_body:
              gifSelected || stickySelected
                ? {
                    title: '',
                    description: stickySelected ? 'sticky' : '',
                    link:
                      gifSelected ?? stickySelected?.url ?? stickySelected?.link
                  }
                : null,
            tags: data.status_tags?.map((el: any) => ({
              entity_id: el.id,
              entity_type: el.type,
              position: el.position,
              name: el.name
            })),
            page_owner_id: roleInteract.page_owner_id
              ? roleInteract.page_owner_id
              : null
          }
        : {
            media_ids: media_ids,
            status: handleTextContent(data.textInputCommentBox),

            extra_body:
              gifSelected || stickySelected
                ? {
                    title: '',
                    description: stickySelected ? 'sticky' : '',
                    link:
                      gifSelected ?? stickySelected?.url ?? stickySelected?.link
                  }
                : null,
            tags: data.status_tags?.map((el: any) => ({
              entity_id: el.id,
              entity_type: el.type,
              position: el.position,
              name: el.name
            }))
          };

    if (_files.length && !_files[0].id) {
      listIdMedia = await uploadMediaSaga(_files, 'comment');
    }

    let card;

    if (_data.status && urlify(_data.status).length) {
      let linkFirst = urlify(_data.status)[0];
      handleCheckLink(linkFirst, card);
    }
    try {
      let response: ResponseApi = await createUpdatePostApi(
        listIdMedia
          ? {
              ..._data,
              media_ids: listIdMedia
            }
          : {
              ..._data
            },
        type === 'create' ? null : data.id
      );

      if (response.status === 200) {
        if (type === 'create') {
          let res: ResponseApi = await getPostDetailApi(
            response?.data?.in_reply_to_id
          );
          if (res.status === 200) {
            setListComment(prev => {
              return produce(prev, draftState => {
                const index = prev.findIndex(
                  comment => comment.id === common.id
                );
                if (index !== -1) draftState[index] = response.data;
              });
            });
            setCommentInfo(prev => ({
              ...prev,
              commentTotal: res.data.replies_total
            }));
            dispatch(commentTotal(res.data, post.id));
          }
        }
        functionUpdate(preResponse?.id, {
          ...response.data,
          card: card !== undefined ? card.data[0] : response.data.card
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 422) {
        setOpenSnackbar(true);
        setNoti({
          code: 422,
          message: error?.response?.data?.error
        });
      }
      //trường hợp cho tạo comment bị lỗi 429
      preResponse.haveError = true;
    }
  };

  React.useEffect(() => {
    if (listComment.length) {
      handleGetComment(
        post.id,
        {
          sort_by: visibility
        },
        setListComment,
        null
      );
    }
  }, [visibility]);

  React.useEffect(() => {
    if (post?.newComment?.id) {
      let checkComment = listComment.find(
        el => el.id === post?.newComment?.id || el.id === post?.newComment?.uuid
      );
      if (!checkComment) {
        setShowComment(true);
        typeComment === 'liveChat'
          ? setListComment(prev =>
              [...prev, post?.newComment].filter(
                item => item.in_reply_to_account_id
              )
            )
          : setListComment(prev =>
              [post?.newComment, ...prev].filter(
                item => item.in_reply_to_account_id
              )
            );
      }
    }
  }, [post?.newComment?.id]);

  React.useEffect(() => {
    if (post && Object.keys(post).length) {
      setCommentInfo(prev => ({
        ...prev,
        reactions: post.reactions
      }));
    }
  }, [JSON.stringify(post.reactions)]);

  React.useEffect(() => {
    if (
      (post?.replies?.length && post?.replies_total) ||
      type === 'previewMedia' ||
      type === 'watch-selected' ||
      type === 'post-detail'
    ) {
      setShowComment(true);
    } else {
      setShowComment(false);
    }
  }, [type]);

  React.useEffect(() => {
    if (post.reblogs_count) {
      setCommentInfo(prev => ({
        ...prev,
        shareCount: post.reblogs_count
      }));
    }
  }, [post.reblogs_count]);

  React.useEffect(() => {
    //update total comment
    if (post.replies_total)
      setCommentInfo(prev => ({
        ...prev,
        commentTotal: post.replies_total
      }));
  }, [post.replies_total]);
  return (
    <div
      style={
        type === 'watch-selected'
          ? { padding: '10px 0 0 0', height: styleWatch?.height }
          : type === 'previewMedia'
          ? { padding: '10px 6px 0px 6px' }
          : typePost === 'pinned'
          ? {
              padding: '0px 16px 4px 16px',
              position: 'absolute',
              bottom: 0,
              width: '100%'
            }
          : { padding: '0px 16px 8px 16px' }
      }
    >
      {typeAction === 'scheduled_action' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '8px'
          }}
        >
          <ButtonInherit
            action={() => handlePostNow()}
            style={{
              textTransform: 'none',
              width: '48%',
              backgroundColor: buttonColor.backgroundColor,
              color: '#e7f3ff'
            }}
            label="Đăng ngay"
          ></ButtonInherit>
          <ButtonInherit
            action={() => setOpenSchedule(true)}
            style={{
              textTransform: 'none',
              width: '48%'
            }}
            label="Lên lịch lại"
          ></ButtonInherit>
        </div>
      ) : (
        type !== 'watch-selected' && (
          <div
            style={
              match.path.includes('watch')
                ? {
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse',
                    width: 'calc(100% - 0px)'
                  }
                : {}
            }
          >
            <PostActionInformation
              typePost={typePost}
              type={type}
              post={post}
              listComment={listComment}
              setListComment={setListComment}
              setShowComment={setShowComment}
              commentTotal={commentInfo?.commentTotal}
              reactions={commentInfo?.reactions}
              shareCount={commentInfo?.shareCount}
              handleGetComment={handleGetComment}
              reactionCount={reactionCount}
              setReactionCount={setReactionCount}
              meReaction={meReaction}
              listReaction={listReaction}
              buttonSelected={buttonSelected}
              setButtonSelected={setButtonSelected}
              typeLive={typeLive}
              setHasMore={setHasMore}
            />
            {buttonSelected === 'chat' || buttonSelected === 'video' ? null : (
              <PostActionButton
                typePost={typePost}
                ref={scrollRef}
                post={post}
                type={type}
                pageInfo={post?.page}
                listComment={listComment}
                setListComment={setListComment}
                handleGetComment={handleGetComment}
                setPostData={setPostData}
                setMediaData={setMediaData}
                setShowComment={setShowComment}
                setReactionCount={setReactionCount}
                setMeReaction={setMeReaction}
                setFocus={setFocus}
                roleInteract={roleInteract}
                setListReaction={setListReaction}
                listReaction={listReaction}
                setButtonSelected={setButtonSelected}
                typeLive={typeLive}
                setChooseFocus={setChooseFocus}
              />
            )}
          </div>
        )
      )}
      {(showComment && !post.in_reply_to_parent_id) ||
      type === 'watch-selected' ? (
        <>
          <Divider />
          {buttonSelected === 'overview' ||
          buttonSelected === 'video' ? null : typeComment === 'liveChat' ? (
            <>
              <PostListComment
                info={post}
                type={type}
                styleWatch={styleWatch}
                createComment={createComment}
                listComment={listComment}
                visibility={visibility}
                commentTotal={commentInfo?.commentTotal}
                handleGetComment={handleGetComment}
                setListComment={setListComment}
                handleBackClick={handleBackClick}
                gifSelected={gifSelected}
                setGifSelected={setGifSelected}
                stickySelected={stickySelected}
                setStickySelected={setStickySelected}
                setCommentInfo={setCommentInfo}
                hasMore={hasMore}
                setHasMore={setHasMore}
                typeComment={typeComment}
              />{' '}
              {isLoading ? (
                <Loading
                  style={{
                    margin: '20px 0',
                    '& > *:not(:first-child)': {
                      display: 'none'
                    }
                  }}
                />
              ) : null}
              <PostActionComment
                ref={scrollRef}
                post={post}
                createComment={createComment}
                visibility={visibility}
                setVisibility={setVisibility}
                listComment={listComment}
                setListComment={setListComment}
                handleGetComment={handleGetComment}
                gifSelected={gifSelected}
                setGifSelected={setGifSelected}
                stickySelected={stickySelected}
                setStickySelected={setStickySelected}
                focus={focus}
                setFocus={setFocus}
                type={type}
                chooseFocus={chooseFocus}
                setChooseFocus={setChooseFocus}
                typeLive={typeLive}
                setHasMore={setHasMore}
                typeComment={typeComment}
                info={post}
              />
            </>
          ) : (
            <>
              <PostActionComment
                ref={scrollRef}
                post={post}
                createComment={createComment}
                visibility={visibility}
                setVisibility={setVisibility}
                listComment={listComment}
                setListComment={setListComment}
                handleGetComment={handleGetComment}
                gifSelected={gifSelected}
                setGifSelected={setGifSelected}
                stickySelected={stickySelected}
                setStickySelected={setStickySelected}
                focus={focus}
                setFocus={setFocus}
                type={type}
                chooseFocus={chooseFocus}
                setChooseFocus={setChooseFocus}
                typeLive={typeLive}
                setHasMore={setHasMore}
                typeComment={typeComment}
                info={post}
              />
              <PostListComment
                info={post}
                type={type}
                styleWatch={styleWatch}
                createComment={createComment}
                listComment={listComment}
                visibility={visibility}
                commentTotal={commentInfo?.commentTotal}
                handleGetComment={handleGetComment}
                setListComment={setListComment}
                handleBackClick={handleBackClick}
                gifSelected={gifSelected}
                setGifSelected={setGifSelected}
                stickySelected={stickySelected}
                setStickySelected={setStickySelected}
                setCommentInfo={setCommentInfo}
                hasMore={hasMore}
                setHasMore={setHasMore}
                typeComment={typeComment}
              />

              {isLoading ? (
                <Loading
                  style={{
                    margin: '20px 0',
                    '& > *:not(:first-child)': {
                      display: 'none'
                    }
                  }}
                />
              ) : null}
            </>
          )}

          {buttonSelected === 'overview' && typeLive ? (
            <Card sx={{ maxWidth: 378, marginTop: '20px' }}>
              <CardMedia
                sx={{ height: 140 }}
                image="https://www.facebook.com/images/assets_DO_NOT_HARDCODE/fan_funding_icons/Subs-Gifting-header.png"
                title="green iguana"
              />
              <CardContent sx={{ padding: '6px 12px' }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{ fontSize: '18px', fontWeight: '500' }}
                >
                  Lợi ích dành cho người đăng ký
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Là người đăng ký, bạn sẽ được tận hưởng những đặc quyền độc
                  đáo như chế độ chat dành riêng cho người đăng ký và quyền xem
                  sớm các buổi phát trực tiếp.
                </Typography>
              </CardContent>
              <CardActions>
                <ButtonInherit label="Đăng ký" style={{ width: '100%' }} />
              </CardActions>
            </Card>
          ) : null}

          {buttonSelected === 'video' && typeLive
            ? Array.from(Array(5))?.map((item: any, index) => (
                <Box className={classes.boxVideo}>
                  <img
                    src="https://scontent.fhan5-2.fna.fbcdn.net/v/t15.5256-10/337328496_933155084546049_7950566208800923571_n.jpg?stp=c0.23.160.90a_dst-jpg_p160x160&_nc_cat=102&ccb=1-7&_nc_sid=ad6a45&_nc_ohc=aHN4imILm2IAX_CjUGD&_nc_ht=scontent.fhan5-2.fna&oh=00_AfAEFLT8jEV9XA8th_pXmwq8sou8hj-G-lN_RX6DH_josA&oe=642949A1"
                    alt="video"
                    style={{ objectFit: 'cover', borderRadius: '4px' }}
                  />
                  <Box sx={{ marginLeft: '10px' }}>
                    <Typography
                      className={classes.lineClamp}
                      sx={{ fontWeight: '500' }}
                    >
                      Atrox vị tướng hot nhất 8.5
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                      KIM Gaming
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>
                      8 giờ trước . 2k lượt xem
                    </Typography>
                  </Box>
                  <IconButtonOptions
                    icon={
                      <i
                        style={{ fontSize: 14 }}
                        className="fa fa-ellipsis-h"
                        aria-hidden="true"
                      ></i>
                    }
                    options={listActionLive}
                    openPopup={true}
                    style={{
                      backgroundColor: 'transparent',
                      width: '20px'
                    }}
                    styleListMenu={{ maxWidth: '328px' }}
                    horizontalAnchor="right"
                    horizontalTransform="right"
                  />
                </Box>
              ))
            : null}
        </>
      ) : null}

      {openSchedule && (
        <DialogSchedulePost
          minDay={new Date()}
          open={openSchedule}
          handleClose={handleCloseSchedule}
          postSelected={post}
          handleActionUpdate={handleUpdateScheduledPost}
        />
      )}
      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
    </div>
  );
});

export default Index;
