import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputBase,
  Theme,
  Typography
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import IconButtonOptions from '../Button/IconButtonOption';
import InputText from '../Input/SocialInput/InputText';
import ListButton from '../List/ListButton';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      }
    }),
  { index: 1 }
);

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void | null;
  back?: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, back, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {back ? (
        <IconButton
          aria-label="close"
          onClick={back}
          sx={{
            position: 'absolute',
            left: 12,
            top: 12,
            height: 36,
            width: 36,
            color: theme => theme.palette.grey[500],
            backgroundColor: '#e5e5e5'
          }}
        >
          <i
            className="fa-regular fa-arrow-left-long"
            style={{ fontSize: 18 }}
          ></i>
        </IconButton>
      ) : null}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            height: 36,
            width: 36,
            color: theme => theme.palette.grey[500],
            backgroundColor: '#e5e5e5'
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface Props {
  title: string;
  text: string;
  open: boolean;
  styleText?: React.CSSProperties;
  handleClose: () => void;
  action?: () => void;
  labelButton?: string;
  loading?: boolean;
  noButtonUpdate?: any;
  optionUpdate?: any;
  indexSelected?: any;
  type?: string;
  line?: any;
}

export default function DialogUpdatePage(props: Props) {
  const classes = useStyles();
  const {
    title,
    text,
    open,
    styleText,
    handleClose,
    action,
    labelButton,
    loading,
    noButtonUpdate,
    optionUpdate,
    indexSelected,
    type,
    line
  } = props;

  const [fieldUpdate, setFieldUpdate] = React.useState<any>(null);
  const [fieldConfirm, setFieldConfirm] = React.useState<any>(null);
  const theme: any = useTheme();
  let [tempTextField, setTempTextField] = React.useState<any>('');
  const [disableButton, setDisableButton] = React.useState<any>(false);

  React.useEffect(() => {
    if (
      fieldUpdate?.validate === 'website_validate' &&
      (tempTextField.slice(0.7) !== 'http://' ||
        tempTextField.slice(0.8) !== 'https://') &&
      !tempTextField.slice(7).includes('.')
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [fieldUpdate?.validate, tempTextField]);

  const renderContentAction = (option: any) => {
    switch (option.field_type) {
      case 'button':
        return (
          <IconButtonOptions
            startIcon={option.startIcon}
            name={option.title}
            style={{
              width: '100%',
              justifyContent: 'space-between',
              height: 56,
              backgroundColor:
                indexSelected === option?.id
                  ? 'button.primary.hover'
                  : 'button.primary.background',
              marginLeft: '0px'
            }}
            styleNameButton={{ color: 'text.primary' }}
            icon={option.icon}
            startIconStyle={{
              fontSize: 18,
              margin: '0px 10px 0px 0px',
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? indexSelected === option?.id
                    ? buttonColor.backgroundColor
                    : '#3A3B3C'
                  : indexSelected === option?.id
                  ? buttonColor.backgroundColor
                  : '#D4D6DC',
              color:
                indexSelected === option?.id
                  ? '#fff'
                  : theme.palette.mode === 'dark'
                  ? '#fff'
                  : '#050505',
              padding: '8px',
              borderRadius: '50%',
              border: '1px solid #e2e2e2'
            }}
            description={option.description}
            action={() => {
              if (option.openDialogUpdate) {
                setFieldUpdate({
                  id: option.id,
                  title: option.title,
                  description: option.descriptionDialogUpdate,
                  action: option.action,
                  validate: option.validate
                });
              } else {
                option?.action({ key: option.id });
              }
            }}
          />
        );
      case 'textField':
        return (
          <>
            <InputText
              title={option.title}
              style={{ padding: '0px 8px', ...option.style }}
              fullWidth={option.fullWidth}
              placeholder={option.placeholder}
              value={option.value}
              action={option.action}
              multiline={option.multiline}
              rows={option.rows}
              required={option.required}
              disabled={option.disabled}
              styleInput={option.styleInput}
              styleTitle={{ fontWeight: 600, ...option.styleTitle }}
              secondTitle={option.secondTitle}
              styleSecondTitle={{
                marginRight: '5px',
                ...option.styleSecondTitle
              }}
            />
            <Typography
              variant="caption"
              sx={{ fontSize: 14, color: '#737373' }}
            >
              {option.description}
            </Typography>
          </>
        );
      case 'listButton':
        return (
          <ListButton
            item={[
              {
                ...option,
                action: () => {
                  option?.action();
                  if (option.openDialogConfirm) {
                    setFieldConfirm({
                      id: option.id,
                      description: option.descriptionConfirm
                    });
                  }
                }
              }
            ]}
            type={type}
            line={line || 2}
            typeAction={'action'}
          />
        );
      default:
        break;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '300px'
      }}
    >
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        BackdropProps={modalBackdrop}
        maxWidth={'sm'}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          back={() => {
            setFieldUpdate(null);
            setFieldConfirm(null);
          }}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 21 }}
            textAlign={'center'}
          >
            {fieldUpdate?.title ? fieldUpdate.title : title}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers className={classes.root}>
          <Typography
            style={{
              fontSize: 16,
              marginLeft: '8px',
              fontWeight: 'bold',
              ...styleText
            }}
          >
            {fieldUpdate?.description
              ? fieldUpdate.description
              : fieldConfirm?.id
              ? fieldConfirm.description
              : text}
          </Typography>
          {fieldUpdate?.id ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <BootstrapInput
                fullWidth
                placeholder={fieldUpdate.title}
                multiline
                rows={1}
                name={fieldUpdate.name}
                onBlur={(event: any) => {
                  let newFieldUpdate: any = cloneDeep(fieldUpdate);
                  fieldUpdate.action({
                    key: newFieldUpdate.id,
                    value: event.target.value
                  });
                }}
                onChange={(event: any) => {
                  setTempTextField(event.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {fieldUpdate?.loading && (
                      <LoadingButton
                        loading={fieldUpdate?.loading ? true : false}
                        style={{ marginLeft: '-70px' }}
                      ></LoadingButton>
                    )}
                  </InputAdornment>
                }
              />
              {fieldUpdate?.validate && (
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 12,
                    color: tempTextField === '' ? '#fff' : '#ff0000'
                  }}
                >
                  {fieldUpdate.validate === 'website_validate' &&
                    (tempTextField.slice(0.7) !== 'http://' ||
                      tempTextField.slice(0.8) !== 'https://') &&
                    !tempTextField.slice(7).includes('.') &&
                    'Vui lòng nhập đúng dạng http:// hoặc https://'}
                </Typography>
              )}
            </div>
          ) : fieldConfirm?.id ? null : (
            optionUpdate.map((el: any, index) => (
              <div key={index} style={{ width: '100%', margin: '5px 0px' }}>
                {renderContentAction(el)}
              </div>
            ))
          )}
        </DialogContent>
        {!noButtonUpdate && (fieldUpdate?.id || fieldConfirm?.id) ? (
          <DialogActions>
            <ButtonInherit
              action={() => {
                handleClose();
                setFieldConfirm(null);
                setFieldUpdate(null);
              }}
              label={'Hủy'}
            />
            <ButtonInherit
              action={() => {
                action && action();
                setFieldUpdate(null);
                setTimeout(() => setFieldConfirm(null), 200);
              }}
              color={buttonColor.backgroundColor}
              label={labelButton ? labelButton : 'Cập nhật'}
              loading={loading}
              disabled={disableButton}
            />
          </DialogActions>
        ) : null}
      </BootstrapDialog>
    </div>
  );
}
