const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();

    // Event listener for successful image loading
    image.addEventListener('load', () => {
      resolve(image);
    });

    // Event listener for image loading error
    image.addEventListener('error', error => {
      reject(error);
    });

    // Setting crossOrigin attribute to avoid cross-origin issues (if needed)
    image.setAttribute('crossOrigin', 'anonymous');

    // Setting the image source
    image.src = `${url}?cache_control=no-store&pragma=no-cache`;
  });

export function getRadianAngle(degreeValue) {
  return new Promise((resolve, reject) => {
    if (typeof degreeValue !== 'number') {
      reject(new Error('Input must be a number'));
      return;
    }

    let value = (degreeValue * Math.PI) / 180;
    resolve(value);
  });
}
export function isBase64(str) {
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}
/**
 * Returns the new bounding area of a rotated rectangle.
 */
export async function rotateSize(width, height, rotation) {
  const rotRad = await getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const rotRad = await getRadianAngle(rotation);

  if (!ctx) {
    return null;
  }

  if (imageSrc) {
    const image = await createImage(imageSrc);
    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = await rotateSize(
      image.width,
      image.height,
      rotation
    );
    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;
    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);
  }

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  if (pixelCrop !== null) {
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);
  }
  // console.log(isBase64(canvas.toDataURL('image/jpeg')));
  // As Base64 string
  return canvas.toDataURL('image/jpeg');
}
