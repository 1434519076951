export const GET_GROUP_DETAIL_REQ = 'GET_GROUP_DETAIL_REQ';
export const GET_GROUP_DETAIL_SUCCESS = 'GET_GROUP_DETAIL_SUCCESS';
export const GET_GROUP_DETAIL_ERROR = 'GET_GROUP_DETAIL_ERROR';

export const GET_GROUP_ACCOUNT_REQ = 'GET_GROUP_ACCOUNT_REQ';
export const GET_GROUP_ACCOUNT_SUCCESS = 'GET_GROUP_ACCOUNT_SUCCESS';

export const DELETE_ACCOUNT_REQ = 'DELETE_ACCOUNT_REQ';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';

export const GET_ACCOUNT_REMOVE_REQ = 'GET_ACCOUNT_REMOVE_REQ';
export const GET_ACCOUNT_REMOVE_SUCCESS = 'GET_ACCOUNT_REMOVE_SUCCESS';

export const GET_WAITING_POST_REQ = 'GET_WAITING_POST_REQ';
export const GET_WAITING_POST_SUCCESS = 'GET_WAITING_POST_SUCCESS';

export const GET_WAITING_MEMBER_REQ = 'GET_WAITING_MEMBER_REQ';
export const GET_WAITING_MEMBER_SUCCESS = 'GET_WAITING_MEMBER_SUCCESS';
export const UPDATE_WAITTING_MEMBER = 'UPDATE_WAITTING_MEMBER';

export const GET_REPORT_POST_REQ = 'GET_REPORT_POST_REQ';
export const GET_REPORT_POST_SUCCESS = 'GET_REPORT_POST_SUCCESS';
export const UPDATE_REPORT_POST = 'UPDATE_REPORT_POST';

export const GET_RULE_GROUP_REQ = 'GET_RULE_GROUP_REQ';
export const GET_RULE_GROUP_SUCCESS = 'GET_RULE_GROUP_SUCCESS';

export const GET_MEMBER_QUESTION_REQ = 'GET_MEMBER_QUESTION_REQ';
export const GET_MEMBER_QUESTION_SUCCESS = 'GET_MEMBER_QUESTION_SUCCESS';
export const CREATE_MEMBER_QUESTION = 'CREATE_MEMBER_QUESTION';
export const UPDATE_MEMBER_QUESTION = 'UPDATE_MEMBER_QUESTION';
export const RESET_MEMBER_QUESTION = 'RESET_MEMBER_QUESTION';

export const GET_SCHEDULE_POST_REQ = 'GET_SCHEDULE_POST_REQ';
export const GET_SCHEDULE_POST_SUCCESS = 'GET_SCHEDULE_POST_SUCCESS';
export const APPROVED_GROUP_SCHEDULE = 'UPDATE_GROUP_SCHEDULE';
export const CREATE_SCHEDULE_GROUP = 'CREATE_SCHEDULE_GROUP';
export const UPDATE_SCHEDULE_GROUP = 'UPDATE_SCHEDULE_GROUP';
export const CHANGE_SCHEDULE_GROUP = 'CHANGE_SCHEDULE_GROUP';

export const GET_CENSORSHIP_REQ = 'GET_CENSORSHIP_REQ';
export const GET_CENSORSHIP_SUCCESS = 'GET_CENSORSHIP_SUCCESS';

export const GET_ACTIVITY_LOG_REQ = 'GET_ACTIVITY_LOG_REQ';
export const GET_ACTIVITY_LOG_SUCCESS = 'GET_ACTIVITY_LOG_SUCCESS';
export const LOADING_ACTIVITY_LOG = 'LOADING_ACTIVITY_LOG';
export const NOTE_ACTIVITY_LOG_REQ = 'NOTE_ACTIVITY_LOG_REQ';
export const NOTE_ACTIVITY_LOG_SUCCESS = 'NOTE_ACTIVITY_LOG_SUCCESS';

export const GROUP_RELATIONSHIP = 'GROUP_RELATIONSHIP';
export const UPDATE_GROUP_RELATIONSHIP = 'UPDATE_GROUP_RELATIONSHIP';

export const GROUP_PREVIEW = 'GROUP_PREVIEW';

export const GET_INVITATION_REQ = 'GET_INVITATION_REQ';
export const GET_INVITATION_SUCCESS = 'GET_INVITATION_SUCCESS';

export const RESET_GROUP = 'RESET_GROUP';

export const GROUP_SEARCH = 'GROUP_SEARCH';
export const UPDATE_GROUP_SEARCH = 'UPDATE_GROUP_SEARCH';

export const IS_LOADING_GROUP_MEMBER = 'IS_LOADING_GROUP_MEMBER';

export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';

export const GET_SEARCH_POST_IN_GROUP_ALL = 'GET_SEARCH_POST_IN_GROUP_ALL';

export const LOADING_SEARCH_GROUP = 'LOADING_SEARCH_GROUP';

export const GET_ACTIVITIES_USER_IN_GROUP_SUCCESS =
  'GET_ACTIVITIES_USER_IN_GROUP_SUCCESS';

export const GET_ACTIVITIES_USER_IN_GROUP_REQUEST =
  'GET_ACTIVITIES_USER_IN_GROUP_REQUEST';

export const CHANGE_ISLOADING_ACTIVITIES_LOG =
  'CHANGE_ISLOADING_ACTIVITIES_LOG';

export const SET_ID_DELETE_GROUP = 'SET_ID_DELETE_GROUP';
