import { Avatar, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getPostDetailApi, getPostMediaApi } from 'src/apis/socialPost.api';
import DialogPreviewMedia from 'src/components/Dialog/DialogPreviewMedia';
import PopupInformation from '../Popup/PopupInformation';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: '100% !important',
    height: '100% !important'
  },
  rootAvatar: {
    margin: '0px 20px',
    marginTop: -35,
    zIndex: 9,
    border: '2px solid #fff',
    cursor: 'pointer',
    backgroundColor: '#bdbdbd',
    position: 'relative',
    borderRadius: '50%'
  },
  rootBanner: {
    zIndex: 15,
    cursor: 'pointer',
    backgroundColor: '#bdbdbd',
    position: 'relative',
    borderRadius: '8px'
  },
  rootFeed: {
    position: 'relative'
  },
  avatarFrame: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '50%',
    cursor: 'pointer'
  },
  rootAvatarChat: {
    margin: '0px 15px 0px 5px',
    marginTop: 0,
    zIndex: 15,
    cursor: 'pointer',
    position: 'relative',
    borderRadius: '50%'
  }
}));

interface AvatarSocialProps {
  type?: any;
  typePopup?: any;
  avatarObj?: any;
  style?: any;
  src?: any;
  isPopup?: any;
  object?: any;
  isBanner?: any;
  noAction?: any;
  isAvatarGroupChat?: any;
  action?: () => void;
  isInvitePage?: boolean;
}

const AvatarSocial = (props: AvatarSocialProps) => {
  const {
    type,
    typePopup,
    avatarObj,
    style,
    src,
    isPopup,
    object,
    isBanner,
    noAction,
    isAvatarGroupChat,
    action,
    isInvitePage
  } = props;
  const history = useHistory();
  const classes = useStyles();

  const meInfo = useSelector((state: any) => state.meReducer.info);

  const [open, setOpen] = React.useState(false);
  const [mediaSelected, setMediaSelected] = React.useState<any>(null);
  const [indexMedia, setIndexMedia] = React.useState(0);
  const [postMedia, setPostMedia] = React.useState<any>(null);

  const isAdminPageSeller =
    object?.page_purpose === 'sell_products' &&
    object?.page_relationship?.role === 'admin';

  const getPostMediaDetail = async () => {
    let res: any = await getPostMediaApi(mediaSelected);

    if (res.status === 200) {
      const postId = res.data.id;
      let response: any = await getPostDetailApi(postId);
      if (response.status === 200) {
        setPostMedia({
          ...res.data,
          comment_moderation: response.data.comment_moderation,
          off_comment: response.data.off_comment
        });
      }
    }
  };

  const handleSelectedBanner = () => {
    if (
      !noAction &&
      avatarObj?.id &&
      (type === 'avatar' || type === 'avatar-user')
    ) {
      setMediaSelected(avatarObj?.id);
    }
    if (!object?.id) return;

    let destinationURL = '';

    if (object?.display_name) {
      destinationURL = `/user/${object.username ?? object.id}`;
    } else if (object?.page_categories || object.hasOwnProperty('page_type')) {
      if (isAdminPageSeller) {
        destinationURL = `/marketplace/shop/home?page_id=${object?.id}`;
      } else if (object?.page_purpose === 'sell_products') {
        destinationURL = `/page/${object.username ?? object.id}/store`;
      } else {
        destinationURL = `/page/${object.username ?? object.id}`;
      }
    } else if (type === 'course') {
      destinationURL = `/course/${object?.id}/about`;
    } else if (type === 'grow') {
      destinationURL = `/grow/${object?.id}/about`;
    } else {
      destinationURL = `/group/${object?.username ?? object.id}`;
    }
    history.push(destinationURL);
  };

  React.useEffect(() => {
    if (mediaSelected) {
      setOpen(true);

      getPostMediaDetail();
    }
  }, [mediaSelected]);

  const handleClick = () => {
    if (!object?.id) return;

    if (noAction) return;

    let destinationURL = '';

    if (object?.display_name) {
      destinationURL = `/user/${object.username ?? object.id}`;
    } else if (object?.page_categories || object?.page_relationship) {
      if (isAdminPageSeller) {
        destinationURL = `/marketplace/shop/home?page_id=${object?.id}`;
      } else if (object?.page_purpose === 'sell_products') {
        destinationURL = `/page/${object.username ?? object.id}/store`;
      } else {
        destinationURL = `/page/${object.username ?? object.id}`;
      }
    } else if (type === 'course') {
      destinationURL = `/course/${object?.id}/about`;
    } else if (type === 'grow') {
      destinationURL = `/grow/${object?.id}/about`;
    } else {
      destinationURL = `/group/${object?.username ?? object.id}`;
    }

    history.push(destinationURL);
  };

  return (
    <>
      {isPopup ? (
        <PopupInformation
          object={object}
          typePopup={typePopup}
          type={type}
          onClick={handleClick}
          isInvitePage={isInvitePage}
        >
          {(popupState, bindHover) => {
            return (
              <div
                {...bindHover(popupState)}
                onClick={handleSelectedBanner}
                className={
                  type === 'feed' || type === 'course' || type === 'grow'
                    ? classes.rootFeed
                    : isBanner
                    ? classes.rootBanner
                    : isAvatarGroupChat
                    ? classes.rootAvatarChat
                    : classes.rootAvatar
                }
                style={style}
              >
                <Avatar
                  sx={{
                    borderRadius: isBanner && '8px',
                    '&:hover': {
                      opacity: 0.8,
                      cursor: 'pointer'
                    }
                  }}
                  onClick={() => {
                    handleClick();
                    action && action();
                  }}
                  aria-label="recipe"
                  className={classes.avatar}
                  src={
                    avatarObj?.show_url ??
                    avatarObj?.preview_url ??
                    src ??
                    avatarObj ??
                    meInfo?.avatar_static
                  }
                  variant={isBanner ? 'square' : 'circular'}
                />
                {avatarObj?.frame && (
                  <div
                    className={classes.avatarFrame}
                    style={{
                      backgroundImage: `url(${avatarObj?.frame?.url})`
                    }}
                  ></div>
                )}
              </div>
            );
          }}
        </PopupInformation>
      ) : (
        <div
          onClick={handleSelectedBanner}
          className={
            type === 'feed' || type === 'course' || type === 'grow'
              ? classes.rootFeed
              : isBanner
              ? classes.rootBanner
              : isAvatarGroupChat
              ? classes.rootAvatarChat
              : classes.rootAvatar
          }
          style={style}
        >
          <Avatar
            sx={{
              borderRadius: isBanner && '8px',
              '&:hover': {
                opacity: 0.8,
                cursor: 'pointer'
              },
              width: type === 'avatar' ? 'unset' : '100%'
            }}
            onClick={() => {
              handleClick();
              action && action();
            }}
            aria-label="recipe"
            className={classes.avatar}
            src={
              type === 'avatar-user'
                ? avatarObj?.show_url ?? avatarObj?.preview_url
                : avatarObj?.show_url ??
                  avatarObj?.preview_url ??
                  src ??
                  avatarObj
            }
            variant={isBanner ? 'square' : 'circular'}
          />
          {avatarObj?.frame && (
            <div
              className={classes.avatarFrame}
              style={{
                backgroundImage: `url(${avatarObj?.frame?.url})`
              }}
            ></div>
          )}
        </div>
      )}

      {open && postMedia && Object.keys(postMedia)?.length ? (
        <DialogPreviewMedia
          open={open}
          setOpen={setOpen}
          indexMedia={indexMedia}
          post={{
            media_attachments: postMedia?.media_attachments ?? []
          }}
          setIndexMedia={setIndexMedia}
          setMediaSelected={setMediaSelected}
          postMedia={postMedia}
          setPostMedia={setPostMedia}
        />
      ) : null}
    </>
  );
};

export default React.memo(AvatarSocial);
