export const PATHS = {
  HOME: '/',
  POST: '/posts',
  LOGIN: '/login',
  LOGIN_RESET: '/reset_password',
  TEST: '/main/test',
  LESSON_REGISTRATION: '/space/people/lesson-registration',
  SUPPORT: '/space/support',
  USER: '/user/:userId?/:key?/:tab?',
  PAGE_HASHTAG: '/hashtag/:tag?',
  PAGE_SAVED: '/saved',
  PAGE_SAVED_DETAIL: '/saved/:id?',
  POST_DETAIL: '/:userName?/posts/:id?',
  SETTINGS_USER: '/settings/:key?',
  GROUP: '/groups/timeline',
  GROUP_ACTIVITIES_USER: '/groups/:groupId/user/:userId/:tab?',
  GROUP_DISCOVER: '/groups/discover',
  GROUP_CATEGORY: '/groups/category',
  GROUP_REQUEST: '/groups/request',
  GROUP_CREATE: '/groups/create',
  GROUP_DETAIL: '/group/:id?/:key?/:tab?',
  GROUP_SEARCH: '/groups/search',
  GROUP_POST_DETAIL: '/groups/:groupId?/:groupName?/:id?',

  POST_IMAGE_DETAIL: '/photo/:id?',
  POST_VIDEO_DETAIL: '/video/:id?',

  EVENT: '/events/home',
  EVENT_MINE: '/events/:key?',
  EVENT_CREATE: '/events/create/:key?',
  SCHEDULE_MANAGEMENT: '/schedule-management-new',
  EVENT_DETAIL: '/event/:id?/:key?/:tab?',
  EVENT_SEARCH: '/events/search',
  EVENT_CATEGORY: '/events/categories/:id?',

  PAGE: '/pages/management',
  PAGE_DISCOVER: '/pages/discover',
  PAGE_LIKED: '/pages/liked',
  PAGE_INVITES: '/pages/invites',
  PAGE_CREATE: '/pages/creation',
  PAGE_DETAIL: '/page/:id?/:key?/:tab?',

  MOMENT: '/moment/:key?',
  MOMENT_TAG: '/moment/hashtag/:tag?',
  MOMENT_USER: '/moment/:key/:idUser?',
  MOMENT_SEARCH: '/moments/search/:key?',

  WATCH: '/watch/:key?',
  WATCH_PAGE: '/watch/page/:id?',
  WATCH_SEARCH: '/watchs/search/:key?',
  WATCH_SHOW_DETAIL: '/watch/watch-show/:id?',

  FRIEND: '/friends/:keyFriend?/:userId?/:key?/:tab?',

  SEARCH_GLOBAL: '/search/:key?',

  PATH_TEST: '/test',
  PATH_DETAILRECRUIT: '/recruit_detail/:id?',
  DIARY_USER: '/diary/:key?/:id?',
  GROW: '/grows/home',
  GROW_CATEGORY: '/grows/categories/:id?',
  GROW_MINE: '/grows/:key?',
  GROW_CREATE: '/grows/create/:key?',
  GROW_DETAIL: '/grow/:id?/:key?/:tab?',
  GROW_SEARCH: '/grows/search',
  GROW_NOTI_IDENTITY: '/grows/not/approve/:id',

  NOTI: '/notifications',
  RECRUIT: '/recruits/home',
  RECRUIT_CATEGORY: '/recruits/categories/:id?',
  RECRUIT_MINE: '/recruits/:key?',
  RECRUIT_CREATE: '/recruits/create/:key?',
  RECRUIT_DETAIL: '/recruit/:id?/:key?/:tab?',
  RECRUIT_SEARCH: '/recruits/search',
  RECRUIT_CREATECV: '/recruit/createCV',

  COURSE: '/courses/home',
  COURSE_CATEGORY: '/courses/categories/:id?',
  COURSE_MINE: '/courses/:key?',
  COURSE_CREATE: '/courses/create/:key?',
  COURSE_DETAIL: '/course/:id?/:key?/:tab?',
  COURSE_SEARCH: '/courses/search',

  MARKETPLACE: '/marketplace/home',
  MARKETPLACE_CATEGORY: '/marketplace/categories/:idParent?/:idChild?',
  MARKETPLACE_MINE: '/products/:key?/:id?',
  MARKETPLACE_SHOP: '/marketplace/shop/:key?/:id?',
  PRODUCT_CREATE: '/products/create/:key?',
  PRODUCT_DETAIL: '/product/:id?/:key?/:tab?',
  ORDER_DETAIL: '/order/:id?',
  MANAGE_ORDER_DETAIL: '/product_order/:id?',
  PRODUCT_SEARCH: '/marketplace/search',
  SALE: '/sales/:key?',
  CATEGORY_GUIDE: '/category-guide',
  MARKETPLACE_TOP_PRODUCTS: '/marketplace/top_products',
  MARKETPLACE_FLASH_SALE: '/marketplace/flash_sale/:id?',
  MARKETPLACE_CAMPAIGN: '/marketplace/campaign/:id',
  MARKETPLACE_BEST_SELLER: '/marketplace/best_seller',
  MARKETPLACE_FULL_STAR: '/marketplace/full_star',
  MARKETPLACE_MALL: '/marketplace/mall',
  MARKETPLACE_CART_RECOMMEND: '/marketplace/cart_recommend',
  MARKETPLACE_EMSO_COMMIT: '/marketplace/emso_commit',

  PAYMENT: '/emso_payment/home',
  PAYMENT_MONEY: '/emso_payment/money',
  PAYMENT_HISTORY: '/emso_payment/payment_history',
  PAYMENT_COIN: '/emso_payment/payment_coin',
  PAYMENT_BUY: '/vnpay_return',
  PAYMENT_VTV_PAY: '/vtcpay_return',
  RECURRING_PAYMENTS: '/emso_payment/recurring_payment',
  REFUND_PAYMENTS: '/emso_payment/refund_payment',
  REQUEST_PAYMENTS: '/emso_payment/request_payment',
  SETTINGS_PAYMENT: '/emso_payment/setting_payment',
  HELP_PAYMENT: '/emso_payment/help_payment',
  PAYMENT_TERMS: '/emso_payment/payment_terms',
  PAYMENT_BANK: '/emso_payment/account_bank',

  CHAT: '/messages/:id?',
  CHAT_INVITES: '/invite/:token',

  LIVESTREAM: '/live',
  LIVESTREAM_CREATE: '/live/create/:id?',
  LIVESTREAM_REALTIME: '/live/realtime/:id?',
  LIVESTREAM_HOME: '/live/home',
  LIVESTREAM_FOLLOW: '/live/follow',
  LIVESTREAM_SELL: '/live/sell',
  LIVESTREAM_GAME: '/live/game',
  LIVESTREAM_DETAIL: '/live/detail/:id?',

  MUSICSPACE: '/music_space',
  MUSICSPACE_CHART: '/music_space/charts',
  MUSICSPACE_ALBUM: '/music_space/album',
  MUSICSPACE_DETAIL_ALBUM: '/music_space/album/:id',
  MUSICSPACE_SEARCH: '/music_space/search_music',
  MUSICSPACE_MY_PLAYLIST: '/music_space/my_Playlist',
  MUSICSPACE_DETAIL_PLAYLIST: '/music_space/my_Playlist/:id',
  MUSICSPACE_TRACK: '/music_space/track/:id',
  MUSICSPACE_DETAIL_SEARCH: '/music_space/search_music/:id',
  MUSICSPACE_CREATE_ALBUM: '/music_space/cre_Album',
  MUSICSPACE_INVITE: '/music_space/invites',
  MUSICSPACE_ARTIST: '/music_space/artist',
  MUSICSPACE_UPLOADED: '/music_space/music/:key',
  MUSICSPACE_DETAIL: '/music_space/detail_artist/:id?/:key?/:tab?',
  MUSICSPACE_NEW_RELEASE_SONG: '/music_space/new-release/song/:tab?',
  MUSICSPACE_NEW_RELEASE_ALBUM: '/music_space/new-release/album/:tab?',
  MUSICSPACE_UPDATE_ALBUM: '/music_space/album/album_detail/:key?',
  MUSICSPACE_RANK_SONG: '/music_space/rank/:key?',
  MUSICSPACE_NEW_CHART: '/music_space/new_chart',
  MUSICSPACE_MUSIC_AUTHOR_LIST: '/music_space/music_author_list/:id?',

  ALBUM_CREATE: '/:url?/:id?/:key?/:params?/create_album',
  ALBUM_UPDATE: '/:url?/:id?/:key?/:params?/edit_album',
  ALBUM_DETAIL: '/:url?/:id?/:key?/:params?/albums_detail',

  ABOUTUS: '/about_us/:key?',
  INVESTORS: '/investors/:key?',
  CONTACT: '/aboutUs/:key?',

  HELPER: '/help/:key?',
  HELPER_ITEM: '/help_item/:key?',
  TERMS_ITEM: '/terms_item/:key?',
  POLICY_DOC: '/policy_doc',
  HElPER_WRAP: '/user_emso/:key?',

  BLOOD_DONATION: '/blood_donation/home',
  CELEBRATE: '/celebrate/home',
  CELEBRATENOTIFI: '/celebrate/notifi',
  CELEBRATEHIDE: '/celebrate/hide',

  WELCOME: '/welcome'
};
