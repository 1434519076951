import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: 300
  },
  textSee: {
    marginLeft: 5,
    fontSize: 14,
    fontWeight: 600,
    color: buttonColor.backgroundColor,
    '&:hover': {
      textDecoration: 'none'
    },
    cursor: 'pointer'
  },
  textLink: {
    color: buttonColor.backgroundColor,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    '&:focus': {
      color: '#6f2da8'
    },
    cursor: 'pointer'
  },
  wrapText: {
    whiteSpace: 'pre-wrap',
    fontSize: '16px',
    '& > a': {
      color: `${buttonColor.backgroundColor} !important`,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  linkTag: {
    textDecoration: 'inherit ',
    fontSize: '24px ',
    fontWeight: '500 ',
    '&:hover': {
      textDecoration: 'underline 2px'
    }
  }
}));

interface PropType {
  data?: any;
  type?: any;
  status_tags?: any;
  info?: any;
  typeShare?: any;
  style?: any;
  styleHastag?: any;
}

function TextCollapse(props: PropType) {
  const meInfor = useSelector((state: any) => state.meReducer.info);
  let { data, type, status_tags, info, typeShare, style, styleHastag } = props;
  const classes = useStyles();
  const contentRef: any = React.useRef(null);
  const [checked, setChecked] = React.useState(false);
  const [longParagraph, setLongParagraph] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const history = useHistory();
  const theme = useTheme();

  let textRender;

  React.useEffect(() => {
    // get number of line
    let ref = contentRef?.current?.clientHeight;
    let lineHeightEl = Number(
      contentRef?.current?.style?.lineHeight?.slice(0, -2)
    );
    let lineAmount = ref / lineHeightEl;

    // set true whether number of line larger than 6
    if (lineAmount > 2) {
      setLongParagraph(true);
    } else {
      setLongParagraph(false);
    }
  }, [contentRef]);
  const convertText = (data: any) => {
    if (typeof data === 'string') {
      if (data?.startsWith('<') && typeof data === 'string') {
        let temp = document.createElement('div');
        temp.innerHTML = data;
        return temp.innerText || temp.textContent;
      }
    }

    return reactStringReplace(
      reactStringReplace(
        reactStringReplace(
          data,
          /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
          (match, i) => {
            return (
              <a
                key={match + i}
                href={match}
                target="_blank"
                rel="noreferrer"
                className={classes.textLink}
              >
                {match}
              </a>
            );
          }
        ),
        /(#\w+[\d]*)/gi, // Updated regex to include hashtags with numbers
        (match, i) => {
          const clear_icon = match.slice(1).replace(/[^\p{L}\d]+/gu, '');
          return (
            <a
              key={clear_icon + i}
              href={`/hashtag/${clear_icon}`}
              className={classes.textLink}
              style={{ ...styleHastag, fontWeight: 500 }}
            >
              {match[0] + clear_icon}
            </a>
          );
        }
      ),
      /(\[\d+\])/gm,
      (match, i, offset) => {
        let objectMentions = status_tags?.find(
          el => el.entity_id === match.slice(1, match.length - 1)
        );
        if (
          objectMentions?.account_relationships?.blocking ||
          objectMentions?.account_relationships?.blocked_by
        ) {
          return (
            <Typography component={'span'}>{objectMentions?.name}</Typography>
          );
        }
        let href = `/${
          objectMentions?.entity_type === 'Account'
            ? 'user'
            : objectMentions?.entity_type === 'Page'
            ? 'page'
            : 'group'
        }/${objectMentions?.entity_id}`;
        return (status_tags?.find(
          item => item.entity_id === objectMentions?.entity_id
        )?.silent === false &&
          info.account.id !== meInfor.id) ||
          info.account.id === meInfor.id ? (
          <Typography
            className={classes.linkTag}
            sx={{
              color: '#7165e0',
              fontWeight: '600',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer'
              },
              ...style
            }}
            component="span"
            key={match + i}
            onClick={() => {
              history.push(href);
            }}
          >
            {info?.post_type === 'moment'
              ? `#${objectMentions?.name}`
              : objectMentions?.name}
          </Typography>
        ) : (
          <Typography style={{ fontSize: 'inherit', display: 'inline' }}>
            {info?.post_type === 'moment'
              ? `#${objectMentions?.name}`
              : objectMentions?.name}
          </Typography>
        );
      }
    );
  };

  textRender = (
    <Box sx={{ color: theme.palette.mode === 'dark' ? '#e5e5e5' : '#050505' }}>
      <p
        ref={contentRef}
        style={{
          ...style,
          wordBreak: 'break-word',
          lineHeight: '28px'
        }}
        className={longParagraph && !checked ? classes.lineClamp : ''}
      >
        {convertText(data)}
      </p>

      {longParagraph &&
        typeShare !== 'share-messenger' &&
        (!checked ? (
          <span className={classes.textSee} onClick={handleChange}>
            &nbsp;Xem thêm
          </span>
        ) : (
          <span className={classes.textSee} onClick={handleChange}>
            Thu gọn
          </span>
        ))}
    </Box>
  );
  return (
    <div
      className={classes.wrapText}
      style={{
        width: '100%',
        // fontSize:
        //   data?.length < 30 && type && typeShare !== 'share-messenger'
        //     ? 24
        //     : 15,
        opacity: info?.forbidden_words?.length > 0 ? '0.5' : '1'
      }}
    >
      {textRender}
    </div>
  );
}

export default TextCollapse;
