import * as types from 'src/constants/store/pageSocial';
const _ = require('lodash');

export interface RolePreviewEdit {
  role: '' | 'admin' | 'moderator';
  // '' cho trường hợp role của người hoặc vào trang của người khác
  // 'admin' | 'moderator' cho trường hợp role page (truy cập vào page detail hoặc switch role trong PostActionButton)
  page_owner_id: null | string;
  avatar_media: any;
  title: null | string;
}

interface InitialState {
  info: any;
  info_page_target: any;
  rolePreviewEdit: RolePreviewEdit;
  hasMore: true;
  error: any;
  my_pages: any;
  loading: any;
  settingPage: any;
  no_myPages: any;
  totalCensorshipDiary: any;
  pageNameNotiVerify: any;
}

const initialState: InitialState = {
  info: {},
  info_page_target: {},
  rolePreviewEdit: {
    role: '',
    page_owner_id: null,
    avatar_media: null,
    title: null
  },
  hasMore: true,
  error: null,
  my_pages: [],
  loading: false,
  settingPage: null,
  no_myPages: false,
  totalCensorshipDiary: 0,
  pageNameNotiVerify: ''
};

export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PAGE_PREVIEW_INFO: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, ...newState };
    }

    case types.ROLE_EDIT_PREVIEW_DETAIL_USER:
    case types.ROLE_EDIT_PREVIEW_DETAIL_PAGE: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return {
        ...state,
        rolePreviewEdit: { ...state.rolePreviewEdit, ...newState }
      };
    }

    case types.GET_LIST_MY_PAGE_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return {
        ...state,
        my_pages: _.uniqBy([...state.my_pages.concat(newState)], 'id'),
        no_myPages:
          [...state.my_pages.concat(newState)].length > 0 ? false : true
      };
    }

    case types.FILTER_LIST_MY_PAGE: {
      let { data } = action.payload;
      return {
        ...state,
        my_pages: data,
        no_myPages: data.length > 0 ? false : true
      };
    }

    case types.GET_PAGE_DETAIL_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        info: { ...state.info, ...data },
        isLoading: false
      };
    }
    case types.SET_PAGE_TARGET: {
      let { data } = action.payload;
      return {
        ...state,
        info_page_target: { ...state.info_page_target, ...data }
      };
    }

    case types.RESET_PAGE_TARGET: {
      return {
        ...state,
        info_page_target: {}
      };
    }
    case types.GET_PAGE_DETAIL_ERROR: {
      return { ...state, error: true };
    }

    case types.GET_SETTING_PAGE_SUCCESS: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, settingPage: newState };
    }

    case types.RESET_PAGE: {
      return {
        ...state,
        info: {},
        rolePreviewEdit: {
          role: '',
          page_owner_id: null,
          avatar_media: null,
          title: null
        },
        error: null
      };
    }

    case types.RESET_LIST_PAGE: {
      return {
        ...state,
        my_pages: [],
        no_myPages: false
      };
    }

    case types.UPDATE_LOCATION_PAGE: {
      let { data } = action.payload;
      return {
        ...state,
        info: { ...state.info, location: data.location, address: data.address }
      };
    }
    case types.UPDATE_HAS_MORE: {
      let { value } = action.payload;
      return { ...state, hasMore: value };
    }
    case types.LOADING_LIST_PAGE: {
      let { value } = action.payload;
      return { ...state, loading: value };
    }
    case types.GET_TOTAL_CENSORSHIP_DIARY: {
      return {
        ...state,
        totalCensorshipDiary: action.payload
      };
    }
    case types.UPDATE_TOTAL_CENSORSHIP_DIARY: {
      let numberUpdate = state.totalCensorshipDiary - action.payload;
      return {
        ...state,
        totalCensorshipDiary: numberUpdate
      };
    }
    case types.UPDATE_PAGE_NAME_NOTIFY_VERIFY: {
      let pageId = action.payload;
      return {
        ...state,
        pageNameNotiVerify: pageId
      };
    }
    default:
      return state;
  }
};
