import React from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';
import {
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  ListItem,
  ListItemButton,
  Popover,
  Popper,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { urlWebEmso } from 'src/util/config';
import { urlRocketChat } from 'src/util/config';

import {
  commandVote,
  createDirectMessage,
  getListMemberGroupChat,
  getRoomRoles,
  seenMessagePage,
  sendMessageApi,
  turnOnPopupVote,
  unBlockPage,
  unBlockRoom
} from 'src/apis/socialChat.apis';
import { collapseString } from 'src/common/string';

import { actionTyping } from 'src/socket/RocketChatSubscriptions';

import DialogSendAudio from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogSendAudio';
import DialogCreateVote from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogCreateVote';
import Emoji from 'src/components/Emoji/EmojiPopup';
import Gif from 'src/components/Emoji/GifPopup';
import Sticky from 'src/components/Emoji/StickyPopup';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import Button from 'src/components/Button/Button';

import Dexie from 'dexie';
import {
  closeBoxCreate,
  handleReadUnreadPageMessageSuccess,
  selectedUserBoxChat,
  unBlockerPage,
  unBlockerUser
} from 'src/store/action/socialChatAction';
import { removeObjectBlock } from 'src/apis/socialUser';
import { colorBlue } from 'src/constants/styles';
import { PATHS } from 'src/constants/paths';
import { useRouteMatch } from 'react-router-dom';
import Mentions from 'src/components/Mentions/Mentions';
const useStyles = makeStyles((theme: any) => ({
  rootFooterCenter: {
    width: '100%',
    display: 'flex',
    margin: '12px 0 12px 0',
    alignItems: 'flex-end',
    position: 'relative'
  },
  form: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    borderRadius: '19px',
    // overflow: 'hidden',
    position: 'relative',
    backgroundColor: theme.palette.background.secondary,
    justifyContent: 'space-between'
  },
  text_input: {
    position: 'absolute',
    height: '100%',
    maxHeight: '140px',
    marginLeft: 8,
    width: '100%',
    fontSize: '14px',
    padding: '8px 5px',
    overflowY: 'auto',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    marginBottom: 0,

    '&:hover': {
      // overflowY: 'auto'
    },
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    }
  },
  lineClamp: {
    background: theme.palette.background.primary,
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  group_button: {
    display: 'flex',
    alignItems: 'center'
  },
  action_button: {
    width: 32,
    height: 32
  },

  iconButtonSendOption: {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    alignItems: 'center',
    backgroundColor: 'white !important',
    '&:hover': {
      backgroundColor: '#e4e6eb !important'
    },
    marginLeft: 0,
    marginRight: 0
  },
  mentionsList: {
    zIndex: 105,
    paddingLeft: '-20px',
    backgroundColor: '#e4e6eb',
    width: '200px',
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '8px',
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    }
  },
  styleRootInput: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const styleIconButtonSendOption = {
  width: '30px',
  height: '30px',
  borderRadius: '15px',
  marginBottom: '3px',
  alignItems: 'center',
  backgroundColor: 'white !important',
  '&:hover': {
    backgroundColor: '#e4e6eb !important'
  },
  color: 'inherit',
  marginLeft: 0,
  marginRight: 0
};

export const checkMentions = message => {
  try {
    if (message && message.mentions && message.mentions.length > 0) {
      let isReplyMessage = message.msg.startsWith(`[ ](${urlRocketChat}/`)
        ? message.msg.split(') ')[1]
        : message.msg;
      const regexp = /@\[([\w\s]+)\]/g;
      const text = isReplyMessage.replace(regexp, username => {
        const objectMentions = message.mentions.find(
          el => `@[${el._id}]` === username
        );
        if (objectMentions) {
          if (objectMentions.username !== 'all') {
            return `<a href="${urlWebEmso}/user/${objectMentions.username}" target="_blank" style="color:inherit;text-decoration:none; font-weight:'400px'"><b>@${objectMentions.name}</b></a>`;
          } else return `<b>@mọi người</b>`;
        } else {
          return message.msg;
        }
      });
      let newText = text.replace(/\([^)]*\)/g, '');
      let result = '';
      if (newText) {
        result = newText?.replace(/\n/g, '<br/>');
      }
      return parse(`<p>${result}</p>`);
    } else {
      return message.msg;
    }
  } catch (error) {
    console.log('[checkMentions]', error);
  }
};

function Footer(props) {
  const {
    conversation,
    repliedMessage,
    setRepliedMessage,
    handleCancelReplied,
    handleSendMessage,
    formik,
    getRootProps,
    getInputProps,
    open,
    setGifSelected,
    setStickySelected,
    handleQuickSendIcon,
    stickySelected,
    updatedMessage,
    inputRef,
    listMemberGrChat,
    gifSelected,
    colorIcon,
    optionsAutomaticReply,
    handleSeenMessage,
    listConversationSelected,
    type,
    createBoxChat,
    handleCreateGroupChat,
    setObjectMentions,
    setUpdatedMessage
  } = props;
  const theme: any = useTheme();
  const mainTopic = conversation?.topic
    ? conversation?.topic?.colors
    : conversation?.RoomsList?.[0]?.topic?.colors || [
        colorBlue.color,
        '#179df7'
      ];
  const classes = useStyles();
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const rocketId = meInfo?.id || '';
  const [anchorElGif, setAnchorElGif] =
    React.useState<HTMLButtonElement | null>(null);
  const [anchorElSticky, setAnchorElSticky] =
    React.useState<HTMLButtonElement | null>(null);
  const [anchorElEmoji, setAnchorElEmoji] =
    React.useState<HTMLButtonElement | null>(null);
  const [anchorElOtherOPtionMessage, setAnchorElOtherOPtionMessage] =
    React.useState<HTMLButtonElement | any>(null);
  const [anchorElAutomaticReply, setAnchorElAutomaticReply] = React.useState<
    HTMLButtonElement | any
  >(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openPopperSticky, setOpenPopperSticky] = React.useState(false);
  const [openPopoverReply, setOpenPopoverReply] =
    React.useState<boolean>(false);
  const [typing, setTyping] = React.useState<Boolean>(false);

  const [page, setPage] = React.useState<number>(0); // chua co infinityscroll, hien lay 25 members dau tien
  const [localContentMessage, setLocalContentMessage] = React.useState('');

  const [openDialogSendAudio, setOpenDialogSendAudio] =
    React.useState<any>(false);
  const [openDialogCreateVote, setOpenDialogCreateVote] =
    React.useState<boolean>(false);

  const [isRecording, setIsRecording] = React.useState(false);
  const [mediaRecorder, setMediaRecorder] = React.useState<any>(null);
  const [audioUrl, setAudioUrl] = React.useState('');
  const [chunks, setChunks] = React.useState<any>([]);
  const recorderRef = React.useRef<any>(null);
  let counter = 0;
  const [listAdmin, setListAdmin] = React.useState<any>([]);

  let timerInterval = React.useRef(null as unknown as any);
  const isMounted = React.useRef<boolean>(true);

  const match: any = useRouteMatch();

  const [ms, setMs] = React.useState(counter);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setAnchorElGif(null);
    setAnchorElSticky(null);
  }, [stickySelected, gifSelected]);

  React.useEffect(() => {
    if (updatedMessage) {
      if (updatedMessage.msg.startsWith('[ ](https')) {
        setLocalContentMessage(updatedMessage.md?.[0]?.value?.[1]?.value);
      } else {
        setLocalContentMessage(updatedMessage.msg);
      }
    }
  }, [updatedMessage]);

  React.useEffect(() => {
    const roomId: string = conversation?.rid;
    const displayName: string = meInfo?.display_name;
    const username: string = meInfo?.username;
    // const avatar: string = meInfo?.avatar_media?.url;

    if (localContentMessage?.length > 0) {
      if (typing === false) {
        const event = 'user-typing';
        actionTyping(roomId, displayName, event, username);
        setTyping(true);
      } else {
        const timer = setTimeout(function () {
          const event = null;
          actionTyping(roomId, displayName, event, username);
          setTyping(false);
        }, 3000);
        return () => clearTimeout(timer);
      }
    } else {
      if (typing === true) {
        const event = null;
        actionTyping(roomId, displayName, event, username);
        setTyping(false);
      }
    }
  }, [localContentMessage]);

  React.useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [repliedMessage, inputRef]);

  React.useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [formik.values.files.length, inputRef]);

  const fetchListAdmin = async () => {
    try {
      if (conversation && conversation.rid) {
        const response = await getRoomRoles(conversation?.rid);
        if (response.status === 200) {
          const listAdmin = JSON.parse(response.data.message).result;
          if (isMounted.current) {
            setListAdmin(listAdmin);
          }
        }
      }
    } catch (error) {
      console.log('[fecth list admin]', error);
    }
  };
  React.useEffect(() => {
    if (conversation?.type === 'group') {
      fetchListAdmin();
    }
    return () => {
      isMounted.current = false;
    };
  }, [conversation?.rid]);
  const isAdmin = listAdmin
    ? listAdmin?.find((el: any) => el.u._id === meInfo.id)
    : [];

  const handleOpenDialogSendAudio = () => {
    setOpenDialogSendAudio(true);
    startRecording();
    setAnchorElOtherOPtionMessage(false);
  };

  const handleChooseIcon = e => {
    let value = localContentMessage + ' ' + `${e}`;
    setLocalContentMessage(value);
  };

  const handleClickOptionMessage = (
    event: React.MouseEvent<HTMLButtonElement>,
    type
  ) => {
    if (type === 'gif') {
      if (Boolean(anchorElOtherOPtionMessage)) {
        setAnchorElOtherOPtionMessage(null);
        setAnchorElGif(inputRef.current);
      } else {
        setAnchorElGif(event.currentTarget);
      }
    } else if (type === 'sticky') {
      if (Boolean(anchorElOtherOPtionMessage)) {
        setAnchorElOtherOPtionMessage(null);
        setAnchorElSticky(inputRef.current);
      } else {
        setAnchorElSticky(event.currentTarget);
      }
    } else if (type === 'emoji') {
      setAnchorElEmoji(event.currentTarget);
    } else if (type === 'others') {
      setAnchorElOtherOPtionMessage(event.currentTarget);
    }
  };
  // assuming you have an SVG element with ID "mySvg" and a variable named "mainTopic"

  const handleOpenPopupCreateVote = async () => {
    try {
      const _response = await commandVote();
      if (_response.status !== 200) return;
      const response = await turnOnPopupVote(conversation.rid);
      if (response.status === 200) {
        setOpenDialogCreateVote(true);
        setAnchorElOtherOPtionMessage(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const [optionsAutomaticReply, setOptionsAutomaticReply] = React.useState<any>(
  //   []
  // );

  const readMessagePage = async () => {
    try {
      if (conversation.countUnreadPage > 0) {
        const response = await seenMessagePage(conversation.rid);
        if (response.status === 200) {
          // handles
          dispatch(
            handleReadUnreadPageMessageSuccess(
              conversation.rid,
              'read',
              'chatPage'
            )
          );
        }
      }
    } catch (error) {
      console.log('error:', error);
    }
  };
  const listOptionMessage = [
    conversation &&
      conversation.t === 'cp' &&
      optionsAutomaticReply &&
      optionsAutomaticReply.length > 0 && {
        label: 'Tin nhắn tự động',
        icon: 'fa-solid fa-message-dots',
        action: e => {
          handleClickOpenPopoverReply(e);
        }
      },
    {
      label: 'Mở hành động khác',
      id: 'otherAction',
      icon: 'fa-solid fa-circle-plus',
      disabled: updatedMessage ? true : false,
      action: e => {
        handleClickOptionMessage(e, 'others');
      }
    },

    {
      label: 'Đính kèm file',
      id: 'attachedFile',
      svg: (
        <svg
          id="mySvg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 41.2 42.49"
          style={{ width: '30px', height: '30px' }}
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M38.36,3.27,14.46,0a3.28,3.28,0,0,0-3.69,2.81L6.34,35.53a3.27,3.27,0,0,0,2.81,3.69L33,42.46a3.28,3.28,0,0,0,3.69-2.81L41.17,7A3.29,3.29,0,0,0,38.36,3.27Zm-.43,17.27-2.49-2.79a2.74,2.74,0,0,0-3.92-.2l-8.6,7.9-5-5.06a3.19,3.19,0,0,0-4.57.05l-4.5,4.67,3-21.86a2.21,2.21,0,0,1,2.48-1.89L37.88,4.57A2.19,2.19,0,0,1,39.76,7Z"
                style={{
                  fill: colorIcon
                    ? mainTopic?.[1] || colorBlue.color
                    : '#bcc0c4'
                }}
              />
              <ellipse
                className="cls-1"
                cx="19.06"
                cy="9.59"
                rx="3.44"
                ry="3.32"
                transform="translate(6.37 26.75) rotate(-80.22)"
                style={{
                  fill: colorIcon
                    ? mainTopic?.[1] || colorBlue.color
                    : '#bcc0c4'
                }}
              />
              <path
                className="cls-1"
                d="M5.29,36.07,9.72,3.38a2.86,2.86,0,0,1,.17-.67L4,2.84A4.1,4.1,0,0,0,0,7L.66,35.79a4.09,4.09,0,0,0,4.18,4l3.05-.07A3.26,3.26,0,0,1,5.29,36.07Z"
                style={{
                  fill: colorIcon
                    ? mainTopic?.[1] || colorBlue.color
                    : '#bcc0c4'
                }}
              />
            </g>
          </g>
        </svg>
      ),
      action: () => {
        open();
      }
    },
    {
      label: 'Chọn file gif',
      id: 'attachedGif',
      svg: (
        <svg
          id="mySvg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 42.79 42.79"
          style={{ width: '30px', height: '30px' }}
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M31.69,0H11.09A11.1,11.1,0,0,0,0,11.09v20.6a11.1,11.1,0,0,0,11.09,11.1h20.6a11.1,11.1,0,0,0,11.1-11.1V11.09A11.1,11.1,0,0,0,31.69,0ZM9.3,25.82A3.2,3.2,0,0,0,12,27.07,6,6,0,0,0,13,27a4.78,4.78,0,0,0,.73-.19,3,3,0,0,0,.51-.25,1.58,1.58,0,0,0,.33-.26V23.61H11.69V21.24h6.14v6.23a5.37,5.37,0,0,1-.76.71,6.24,6.24,0,0,1-1.22.71,8.21,8.21,0,0,1-1.71.54,10.44,10.44,0,0,1-2.28.22,7.41,7.41,0,0,1-2.76-.49A5.9,5.9,0,0,1,7,27.69a6.61,6.61,0,0,1-1.37-2.36,9.68,9.68,0,0,1-.49-3.22v-.92A10.26,10.26,0,0,1,5.56,18,6.79,6.79,0,0,1,6.88,15.6a5.6,5.6,0,0,1,2.06-1.47,6.89,6.89,0,0,1,2.69-.5,7.94,7.94,0,0,1,2.63.38,5.27,5.27,0,0,1,1.86,1.06,5,5,0,0,1,1.16,1.64,7.3,7.3,0,0,1,.55,2.09H14.7a5.38,5.38,0,0,0-.3-1.07,2.55,2.55,0,0,0-.56-.81A2.52,2.52,0,0,0,13,16.4a3.82,3.82,0,0,0-1.24-.18,2.84,2.84,0,0,0-2.51,1.25,6.55,6.55,0,0,0-.85,3.7v.94A6.19,6.19,0,0,0,9.3,25.82Zm14.55,3.62H20.63V13.84h3.22ZM37,16.44H30v4h6.17v2.59H30v6.38H26.83V13.84H37Z"
                style={{
                  fill: colorIcon
                    ? mainTopic?.[1] || colorBlue.color
                    : '#bcc0c4'
                }}
              />
            </g>
          </g>
        </svg>
      ),
      action: e => {
        handleClickOptionMessage(e, 'gif');
        setOpenPopper(true);
      }
    },
    {
      label: 'Chọn nhãn dán',
      id: 'selectSticker',
      svg: (
        <svg
          id="mySvg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 41.31 42.49"
          style={{ width: '30px', height: '30px' }}
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                className="cls-1"
                d="M11.58,15.27c-1.73.21-2.91,2.25-2.63,4.56s1.91,4,3.64,3.8,2.91-2.25,2.63-4.56S13.31,15.06,11.58,15.27Z"
                style={{
                  fill: colorIcon
                    ? mainTopic?.[1] || colorBlue.color
                    : '#bcc0c4'
                }}
              />
              <path
                className="cls-2"
                d="M41.31,22A22.74,22.74,0,0,0,24.19,41C27.25,40.3,41.48,36.35,41.31,22Z"
                style={{
                  fill: colorIcon
                    ? mainTopic?.[1] || colorBlue.color
                    : '#bcc0c4'
                }}
              />
              <path className="cls-2" d="M24.05,41.87s0,0,0,.06h0Z" />
              <path
                className="cls-2"
                d="M39.23,4.75A5.35,5.35,0,0,0,33.31,0L4.75,3.26A5.35,5.35,0,0,0,0,9.18L3.26,37.74a5.36,5.36,0,0,0,5.92,4.72L22.32,41a25.2,25.2,0,0,1,3.74-10.59c-7.55,1.48-10.61-3.06-11.19-4.09l0-.08a.23.23,0,0,1-.05-.1h0a1,1,0,0,1-.08-.38.85.85,0,0,1,.78-.9.78.78,0,0,1,.7.48c4.35,6.56,11.47,2.7,11.41,3l0,0A24.39,24.39,0,0,1,41,20.23ZM12.77,21.12c-1.76.21-3.41-1.52-3.7-3.86s.91-4.42,2.67-4.64,3.42,1.52,3.7,3.86S14.53,20.9,12.77,21.12Zm17-2.07c-1.76.22-3.42-1.51-3.7-3.86s.91-4.42,2.67-4.63,3.41,1.51,3.7,3.86S31.52,18.84,29.76,19.05Z"
                style={{
                  fill: colorIcon
                    ? mainTopic?.[1] || colorBlue.color
                    : '#bcc0c4'
                }}
              />
            </g>
          </g>
        </svg>
      ),
      action: e => {
        handleClickOptionMessage(e, 'sticky');
        setOpenPopperSticky(true);
      }
    },

    {
      label: 'Gửi clip âm thanh',
      id: 'sendAudio',
      icon: 'fa-solid fa-microphone',
      action: () => {
        // handle send record
        handleOpenDialogSendAudio();
      }
    },
    // conversation &&
    //   conversation.type === 'group' && {
    //     label: 'Tạo thăm dò ý kiến',
    //     icon: 'fa-solid fa-poll-people',

    //     action: () => {
    //       // handle call video
    //       handleOpenPopupCreateVote();
    //     }
    //   },

    {
      label: 'Chọn biểu tượng cảm xúc',
      id: 'chooseIcon',
      icon: 'fa-solid fa-face-smile',
      action: e => {
        handleClickOptionMessage(e, 'emoji');
      }
    },
    {
      label: 'Gửi lượt thích',
      id: 'sendLikeCount',
      icon: 'fa-solid fa-thumbs-up',
      action: () => {
        // handleQuickSendIcon();
      }
    }
  ];

  const handleUnBlockRoom = async () => {
    const updatedAt = Date.now();
    const roomId = conversation.rid;
    const userId = conversation.userId;
    if (conversation.t === 'cp') {
      const res = await unBlockPage(
        roomId,
        conversation?.user_chat_page ? true : false // check theo params
      );
      if (res.status === 200) {
        dispatch(unBlockerPage(conversation._id));
      }
    } else {
      const response = await unBlockRoom(roomId, userId);
      await removeObjectBlock('block_messages', userId);

      if (response.status === 200) {
        // getSubscription();
        dispatch(unBlockerUser(conversation._id));
        const db: any = await new Dexie(`chat_emso_db_${rocketId}`);
        db.version(1).stores({
          conversations: '++rid',
          conversations_pinned: '++rid'
        });
        db.conversations
          .update(roomId, {
            blocker: false,
            '_updatedAt.$date': updatedAt
          })
          .then(update => {
            if (update) {
            }
          });
      }
    }
  };

  const SendMessage = () => {
    if (localContentMessage.length) {
      let contentMessage = localContentMessage.trim();
      setLocalContentMessage('');
      handleSendMessage(
        repliedMessage
          ? `[ ](${urlRocketChat}/messages/${conversation.rid}?msg=${repliedMessage._id}) ${contentMessage}`
          : contentMessage
      );
    } else {
      handleSendMessage();
      setLocalContentMessage('');
    }
  };

  // effect mouse click

  const handleOnChange = (event, newValue, newPlainTextValue) => {
    setLocalContentMessage(newValue);
    if (updatedMessage && newValue.length === 0) {
      setUpdatedMessage(null);
    }
  };

  const handleOnKeyDown = event => {
    // sửa lại
    if (event.shiftKey && event.keyCode === 13) {
      //shift enter
      event.preventDefault();
      let start = event.target?.selectionStart;
      let end = event.target?.selectionEnd;
      setLocalContentMessage(
        localContentMessage.substring(0, start) +
          '\n' +
          localContentMessage.substring(end)
      );
    } else if (event.keyCode === 13) {
      event.preventDefault();
      SendMessage();
    }
  };
  // const debounceSearchGlobal = keyword => {
  //   if (conversation.t !== 'c' || conversation.t !== 'p') {
  //     return [];
  //   }

  //   const memberList = listMemberGrChat;

  //   const newListMemberGrChat = [
  //     {
  //       _id: 'all',
  //       displayName: 'Mọi người',
  //       avatar_media: {
  //         show_url: listMemberGrChat.length === 0 ? '' : null
  //       }
  //     },
  //     ...memberList
  //   ];

  //   const listMember = newListMemberGrChat.map(item => ({
  //     id: item._id,
  //     display: item.displayName || item.name,
  //     avatar_media: {
  //       show_url:
  //         item.avatar_media?.show_url ||
  //         (item.username ? `${urlRocketChat}/avatar/${item.username}` : null)
  //     }
  //   }));

  //   if (keyword === ' ') {
  //     return [];
  //   }

  //   const filteredArray = listMember.filter(el =>
  //     el.display.toLowerCase().includes(keyword.toLowerCase())
  //   );
  //   return filteredArray;
  // };
  const debounceSearchGlobal = React.useCallback(
    _.debounce(async function (q, callback) {
      const count: number = 30;
      const offset: number = 0;
      const roomId: string = conversation.rid;
      if (conversation.t === 'd' || conversation.t === 'cp') {
        return [];
      }
      if (!q) {
        const memberList = listMemberGrChat;

        const newListMemberGrChat = [
          {
            _id: 'all',
            displayName: 'Mọi người',
            avatar_media: {
              show_url: listMemberGrChat.length === 0 ? '' : null
            }
          },
          ...memberList
        ];

        callback(
          newListMemberGrChat.map((item: any) => ({
            id: item._id,
            display: item.displayName || item.name,
            avatar_media: {
              show_url:
                item.avatar_media?.show_url ||
                (item.username
                  ? `${urlRocketChat}/avatar/${item.username}`
                  : null)
            }
          }))
        );
      }
      // response = await getListMemberGroupChat(count, offset, roomId);
      let response: any = await getListMemberGroupChat(
        count,
        offset,
        roomId,
        q
      );

      if (response.status === 200) {
        let accounts: any = response.data.members.slice(0, 3);

        if (accounts.length) {
          const results = accounts.map((item: any, index: any) => ({
            id: item._id,
            display: item.displayName || item.name,
            avatar_media: {
              show_url:
                item.avatar_media?.show_url ||
                (item.username
                  ? `${urlRocketChat}/avatar/${item.username}`
                  : null)
            }
          }));

          callback(results);
        }
      }
    }, 800),
    []
  );

  const handleActionMention = id => {
    const newListMemberGrChat = [
      {
        _id: 'all',
        displayName: 'All',
        avatar_media: {
          show_url: null
        }
      },
      ...listMemberGrChat
    ];
    const item = newListMemberGrChat?.find(item => {
      return item._id === id;
    });
    setObjectMentions(prev => [
      ...prev,
      {
        _id: item?._id ?? '',
        username: item?.username,
        name: item?.displayName === 'All' ? 'mọi người' : item?.displayName
      }
    ]);
    inputRef.current.focus();
  };

  const handleCreateZoomChat = async (time: any) => {
    if (listConversationSelected.length === 1) {
      let response = await createDirectMessage(
        listConversationSelected[0]?.username
      );
      let room;
      room = JSON.parse(response.data.message).result.rid;

      const iconLike = JSON.parse(response.data.message)?.result?.customFields
        ?.icon;
      const conversationSelected = {
        displayName: listConversationSelected[0].displayName,
        avatar_media: {
          show_url: `${urlRocketChat}/avatar/${listConversationSelected[0].username}`
        },
        rid: room,
        lastMessage: {
          msg: '',
          unread: true
        },
        blocked: false,
        blocker: 0,
        userId: listConversationSelected[0].userId,
        username: listConversationSelected[0].username,
        type: 'user',
        t: 'd',
        unread: 1,
        ...JSON.parse(response.data.message).result
      };
      dispatch(selectedUserBoxChat(conversationSelected));
      let _response = await sendMessageApi(
        time === 0
          ? `:like_small: ${iconLike ? iconLike : ''}`
          : time > 0 && time < 3
          ? `:like_big: ${iconLike ? iconLike : ''}`
          : `:like_largest: ${iconLike ? iconLike : ''}`,
        room
      );
      if (_response.status === 200) {
        dispatch(closeBoxCreate());
      }
    } else {
      const room = await handleCreateGroupChat();
      if (room) {
        const _response = await sendMessageApi(
          time === 0
            ? `:like_small: `
            : time > 0 && time < 3
            ? `:like_big:  `
            : `:like_largest:  `,
          room._id
        );
        if (_response.status === 200) {
          dispatch(closeBoxCreate());
        }
      }
    }
  };
  const handlePaste = e => {
    if (Array.from(e.clipboardData.files).length > 0) {
      setGifSelected(null);
      setStickySelected(null);
      let file_: any = Array.from(e.clipboardData.files).map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      let preFiles = formik.values.files;
      formik.setFieldValue('files', [...preFiles, ...file_]);
      inputRef?.current?.focus();
    }
  };

  const timer = (start: any) => {
    if (start === true && counter >= 1) {
      timerInterval.current = setInterval(() => {
        setMs(counter); //When I remove this, the infinite loop disappears.
        counter += 1;
        //@ts-ignore
      }, [800]);
    } else {
      setMs(0);
    }
  };

  const pressingDown = (e: any) => {
    e.preventDefault();
    counter = 1;
    timer(true);
  };

  const notPressingDown = (e: any) => {
    e.preventDefault();
    timer(false);
    setMs(0);
    clearInterval(timerInterval.current);
  };
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorderRef.current = recorder;
      recorder.addEventListener('stop', () => {});

      recorder.start();
      setIsRecording(true);
      setMediaRecorder(recorder);
    } catch (error) {
      console.log(' error:', error);
    }
  };
  React.useEffect(() => {
    setLocalContentMessage('');
  }, [conversation?.rid]);

  const stopRecording = () => {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        if (mediaRecorder) {
          recorderRef.current.addEventListener(
            'dataavailable',
            (event: any) => {
              if (event.data.size > 0) {
                setAudioUrl(URL.createObjectURL(event.data));
                setChunks([event.data]);
                resolve([event.data]);
              }
            }
          );
          await mediaRecorder.stop();
          setIsRecording(false);
          if (mediaRecorder.stream) {
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
          }
          setMediaRecorder(null);
        } else {
          reject(new Error('Không có đối tượng mediaRecorder.'));
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleClickOpenPopoverReply = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElAutomaticReply(event.currentTarget);
    setOpenPopoverReply(previousOpen => !previousOpen);
  };

  const canBeOpenPopoverReply =
    openPopoverReply && Boolean(anchorElAutomaticReply);
  const idReply = canBeOpenPopoverReply ? 'transition-popper-reply' : undefined;

  const handleAutomaticReply = async (option: any) => {
    try {
      const roomId = conversation && conversation?.rid;
      await sendMessageApi(
        option.question,
        roomId,
        undefined,
        undefined,
        undefined,
        undefined,
        option.option
      );
      setOpenPopoverReply(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ContentFooterAction = props => {
    const { content } = props;
    return (
      <Box className={classes.styleRootInput}>
        <Box sx={{ width: '60%', textAlign: 'center', margin: '12px' }}>
          <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
            {content}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderTooltipIcon = (index: any, el: any) => {
    return (
      <Tooltip key={el.label} title={el.label}>
        <IconButton
          style={styleIconButtonSendOption}
          size="small"
          className={classes.action_button}
          disabled={el?.disabled ? el.disabled : false}
          onClick={e => el.action(e)}
        >
          {el && el.svg ? (
            el.svg
          ) : (
            <i
              className={el.icon}
              style={{
                color: colorIcon
                  ? updatedMessage && el?.disabled === true
                    ? '#bcc0c4'
                    : mainTopic
                    ? mainTopic?.[1]
                    : colorBlue.color
                  : '#bcc0c4',
                fontSize: 20,
                margin: '4px 4px 0px 4px'
              }}
            />
          )}
        </IconButton>
      </Tooltip>
    );
  };
  const showTypeRepliedMessage = () => {
    if (repliedMessage.msg.startsWith('[ ](https:')) {
      return collapseString(repliedMessage.md?.[0]?.value?.[1]?.value, 60);
    } else if (repliedMessage?.md?.[0]?.value?.[0]?.type === 'MENTION_USER') {
      return collapseString(repliedMessage?.msg, 60);
    } else if (repliedMessage.files?.[0]?.type === 'image/jpeg') {
      return 'Hình ảnh';
    } else if (repliedMessage.files?.[0]?.type === 'video/mp4') {
      return 'Video';
    } else if (repliedMessage?.msg.startsWith('https://media')) {
      return 'Giphy';
    } else if (repliedMessage?.msg.startsWith('https://sdk.mojitok.im/')) {
      return 'Nhãn dán';
    } else if (
      repliedMessage.msg.startsWith(':like_small:') ||
      repliedMessage.msg.startsWith(':like_big:') ||
      repliedMessage.msg.startsWith(':like_largest:')
    ) {
      return repliedMessage.msg.split(' ').slice(-1);
    } else if (repliedMessage.msg !== '' && !repliedMessage?.file) {
      return collapseString(checkMentions(repliedMessage), 100);
    } else {
      return 'File đính kèm';
    }
  };
  React.useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.style.color = theme.palette.text.primary;
    }
  }, [inputRef, theme.palette.mode]);

  const handleFocus = () => {
    if (type === 'chatPage') {
      readMessagePage();
    } else {
      handleSeenMessage && handleSeenMessage();
    }
  };
  const renderWhoReply = () => {
    if (conversation.t === 'cp') {
      if (match.params.key !== 'chat' && repliedMessage?.isPage) {
        return conversation?.displayName;
      } else if (match.params.key !== 'chat' && !repliedMessage?.isPage) {
        return 'chính mình';
      } else if (match.params.key === 'chat' && repliedMessage?.isPage) {
        return 'chính mình';
      } else if (match.params.key === 'chat' && !repliedMessage?.isPage) {
        return repliedMessage.u.name;
      }
    } else {
      if (repliedMessage.u._id === rocketId) {
        return 'chính mình';
      } else {
        return repliedMessage.u.name;
      }
    }
  };
  return (
    <Box className="input-chat">
      {conversation?.blocker ||
      (conversation?.t === 'cp' &&
        conversation?.userBlock &&
        match.params.key !== 'chat') ||
      (conversation?.t === 'cp' &&
        conversation?.pageBlock &&
        match.params.key === 'chat') ? (
        <Box className={classes.styleRootInput}>
          <Typography
            style={{ fontSize: '14px', fontWeight: '600' }}
          >{`Bạn đã chặn ${
            conversation.t === 'cp'
              ? conversation?.real_name
              : conversation?.fname
          }`}</Typography>
          <Typography style={{ fontSize: '12px', fontWeight: 400 }}>
            Bạn không thể nhắn tin hoặc nhận tin nhắn từ họ
          </Typography>
          <ButtonInherit
            style={{
              width: '120px',
              height: '28px',
              marginTop: '6px',
              marginBottom: '10px'
            }}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                  Bỏ chặn
                </Typography>
              </Box>
            }
            action={() => {
              handleUnBlockRoom();
            }}
          />
        </Box>
      ) : conversation?.blocked ||
        (conversation?.t === 'cp' && conversation?.pageBlock) ||
        (conversation?.t === 'cp' && conversation?.userBlock) ? (
        <ContentFooterAction
          content={
            ' Bạn không thể nhắn tin hoặc nhận tin nhắn từ người dùng này.'
          }
        />
      ) : ((conversation && conversation.ro === true) ||
          (conversation && conversation?.RoomsList?.[0]?.ro)) === true &&
        !isAdmin ? (
        <ContentFooterAction
          content={'Chỉ quản trị viên có thể gửi tin nhắn vào nhóm.'}
        />
      ) : (
        <>
          {repliedMessage ? (
            <Box
              sx={{
                width: '100%',
                height: '40px',
                padding: '10px 15px 3px 15px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  lineHeight: '1.2308',
                  wordBreak: 'break-word',
                  textAlign: 'left',
                  maxWidth: '100%'
                }}
              >
                <Typography>
                  Đang trả lời <b>{renderWhoReply()}</b>
                </Typography>

                <Typography className={classes.lineClamp}>{showTypeRepliedMessage()}</Typography>
              </Box>
              <Box
                sx={{
                  height: '100%',
                  width: '30px'
                }}
              >
                <IconButton
                  sx={{ width: '15px', height: '15px' }}
                  onClick={handleCancelReplied}
                >
                  <i
                    style={{ fontSize: '14px', color: '#8f9296' }}
                    className="fa-solid fa-xmark"
                  ></i>
                </IconButton>
              </Box>
            </Box>
          ) : null}
          <Box className={classes.rootFooterCenter}>
            {openDialogSendAudio
              ? null
              : listOptionMessage
                  .slice(
                    conversation &&
                      conversation.t === 'cp' &&
                      optionsAutomaticReply &&
                      optionsAutomaticReply.length > 0 &&
                      !localContentMessage
                      ? 0
                      : 1,
                    !localContentMessage ? 5 : 2
                  )
                  .map((el, index) => {
                    return renderTooltipIcon(index, el);
                  })}

            <Box
              className={classes.form}
              sx={{ display: openDialogSendAudio ? 'none' : 'flex' }}
            >
              <Mentions
                inputRef={inputRef}
                idInput={'input-chat'}
                name="textInputCreatePost"
                placeholder={'Aa...'}
                style={{
                  width: '100%',
                  wordBreak: 'break-word',
                  backgroundColor: 'transparent',
                  resize: 'none',
                  marginLeft: '12px',
                  fontWeight: '400',

                  fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif',
                  maxHeight: '140px'
                }}
                handleFocus={handleFocus}
                handleChange={handleOnChange}
                handlePaste={handlePaste}
                debounceSearchGlobal={debounceSearchGlobal}
                handleActionMention={handleActionMention}
                handleKeyDown={handleOnKeyDown}
                localContent={localContentMessage}
                type="chat"
              />
              <Box
                {...getRootProps({ className: 'dropzone' })}
                className={classes.group_button}
              >
                <input {...getInputProps()} id="files" name="files" />
                {/* icon emoji */}
                {listOptionMessage
                  .slice(-2, -1)
                  .map((el, index) => renderTooltipIcon(index, el))}
              </Box>
            </Box>

            {/* icon like */}
            {listOptionMessage.slice(-1).map((el: any, index) => (
              <Tooltip
                key={el.id}
                title={
                  localContentMessage.length > 0 ||
                  formik.values.files.length > 0
                    ? 'Nhấn Enter để gửi'
                    : el.label
                }
              >
                <IconButton
                  style={{
                    ...styleIconButtonSendOption,
                    margin:
                      match.path !== PATHS.CHAT && match.params.key !== 'chat'
                        ? 'unset'
                        : '0 15px 0 5px'
                  }}
                  size="small"
                  className={classes.action_button}
                  onClick={e => el.action(e)}
                  onMouseDown={pressingDown}
                  onMouseUp={e => {
                    if (
                      createBoxChat &&
                      localContentMessage.length < 1 &&
                      formik.values.files.length < 1
                    ) {
                      handleCreateZoomChat(ms);
                    } else if (
                      localContentMessage.length > 0 ||
                      formik.values.files.length > 0
                    ) {
                      SendMessage();
                    } else {
                      handleQuickSendIcon && handleQuickSendIcon(ms);
                    }
                    notPressingDown(e);
                  }}
                  onTouchStart={pressingDown}
                  onTouchEnd={e => {
                    notPressingDown(e);
                  }}
                >
                  {localContentMessage.length > 0 ||
                  formik.values.files.length > 0 ? (
                    <i
                      className="fa-solid fa-paper-plane-top"
                      style={{
                        color: colorIcon
                          ? mainTopic
                            ? mainTopic?.[1]
                            : colorBlue.color
                          : '#bcc0c4',
                        fontSize: 20,
                        margin: '0px 4px 0px 4px',
                        display: openDialogSendAudio ? 'none' : 'block'
                      }}
                    />
                  ) : conversation?.customFields?.icon ? (
                    <Typography
                      sx={{
                        fontSize: 20,

                        display: openDialogSendAudio ? 'none' : 'block'
                      }}
                    >
                      {conversation?.customFields?.icon}
                    </Typography>
                  ) : (
                    <i
                      className={el.icon}
                      style={{
                        color: colorIcon
                          ? mainTopic
                            ? mainTopic?.[1]
                            : colorBlue.color
                          : '#bcc0c4',
                        fontSize: 20,
                        margin: '0px 4px 0px 4px',
                        display: openDialogSendAudio ? 'none' : 'block'
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            ))}
            {openDialogSendAudio ? (
              <DialogSendAudio
                repliedMessage={repliedMessage}
                setRepliedMessage={setRepliedMessage}
                open={openDialogSendAudio}
                setOpen={setOpenDialogSendAudio}
                colorIcon={colorIcon}
                stopRecording={stopRecording}
                isRecording={isRecording}
                audioUrl={audioUrl}
                setAudioUrl={setAudioUrl}
                conversation={conversation}
                setIsRecording={setIsRecording}
                chunks={chunks}
                setChunks={setChunks}
                listConversationSelected={listConversationSelected}
              />
            ) : null}
          </Box>
        </>
      )}

      {anchorElGif && (
        <Gif
          anchorEl={anchorElGif}
          setAnchorEl={setAnchorElGif}
          setGifSelected={setGifSelected}
          openPopper={openPopper}
          setOpenPopper={setOpenPopper}
          type="chat"
        />
      )}

      {anchorElSticky && (
        <Sticky
          anchorEl={anchorElSticky}
          setAnchorEl={setAnchorElSticky}
          setStickySelected={setStickySelected}
          openPopper={openPopperSticky}
          setOpenPopper={setOpenPopperSticky}
          type="chat"
        />
      )}

      {anchorElEmoji && (
        <Emoji
          iconSelect={e => {
            handleChooseIcon(e);
          }}
          anchorEl={anchorElEmoji}
          handleCloseEmoji={() => {
            setAnchorElEmoji(null);
          }}
          popover={anchorElEmoji}
        />
      )}

      {anchorElOtherOPtionMessage && (
        <Popover
          open={Boolean(anchorElOtherOPtionMessage)}
          onClose={() => {
            setAnchorElOtherOPtionMessage(null);
          }}
          anchorEl={anchorElOtherOPtionMessage}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <Box sx={{ width: '190px', height: 'auto', padding: '5px' }}>
            {listOptionMessage
              .slice(
                localContentMessage.length ? 2 : 5,
                // conversation?.type === 'group' ? 7 : 6  comment func poll => mở để dùng poll
                6
              )
              .map((el: any, index) => (
                <ListItem
                  key={el.id}
                  style={{ width: '100%', height: '36px', padding: '2px' }}
                >
                  <ListItemButton
                    sx={{ width: '100%', height: '100%', padding: 0 }}
                    onClick={e => el.action(e)}
                  >
                    <IconButton
                      key={index}
                      style={styleIconButtonSendOption}
                      size="small"
                      className={classes.action_button}
                    >
                      {el.icon ? (
                        <i
                          className={el.icon}
                          style={{
                            color: colorIcon
                              ? mainTopic
                                ? mainTopic?.[1]
                                : colorBlue.color
                              : '#bcc0c4',
                            fontSize: 20,
                            margin: '4px 4px 0px 4px'
                          }}
                        />
                      ) : (
                        el.svg
                      )}
                    </IconButton>
                    <Typography fontSize="14px" fontWeight="300">
                      {el.label}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
          </Box>
        </Popover>
      )}

      {openDialogCreateVote ? (
        <DialogCreateVote
          open={openDialogCreateVote}
          setOpen={setOpenDialogCreateVote}
        />
      ) : null}

      <Popper
        id={idReply}
        open={openPopoverReply}
        anchorEl={anchorElAutomaticReply}
        transition
        style={{ zIndex: 10000 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              setOpenPopoverReply(false);
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 1,
                  bgcolor: 'background.paper',
                  padding: '16px',
                  borderRadius: '12px',
                  boxShadow: '0 12px 24px rgba(0, 0, 0, 0.15)',
                  position: 'relative',
                  minWidth: '300px'
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '16px',
                    width: '100%',
                    textAlign: 'center',
                    padding: '8px 0'
                  }}
                >
                  Câu hỏi thường gặp
                </Typography>
                <IconButton
                  sx={{
                    display: 'flex',
                    position: 'absolute',
                    top: 12,
                    right: 12,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onClick={() => setOpenPopoverReply(false)}
                >
                  <ClearIcon sx={{ fontSize: '20px' }} />
                </IconButton>
                {optionsAutomaticReply.map(option => {
                  return (
                    <Button
                      key={option.option}
                      action={() => {
                        handleAutomaticReply(option);
                      }}
                      style={{
                        padding: '4px 12px',
                        borderRadius: '8px',
                        fontSize: ' 14px',
                        border: 'none',
                        textAlign: 'center',
                        color: colorBlue.color,
                        textDecoration: 'none',
                        width: 'fit-content',
                        textTransform: 'none'
                      }}
                      label={option.question}
                    />
                  );
                })}
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
}

export default React.memo(Footer);
