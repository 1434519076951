import axios, { AxiosRequestConfig } from 'axios';
import { socialTokenVideo } from 'src/apis/socialAuth.api';
import { videoUrlApi } from 'src/constants/common';
import {
  dataErrorManyReq,
  errorManyReq
} from 'src/store/action/socialUserAction';
import store from 'src/store/store';
import {
  BASE_ROOT,
  TOKEN_VIDEO_UPLOAD,
  USER_TOKEN,
  updateTokenVideoUpload
} from 'src/util/config';

export interface Response<T> {
  data: T | null | undefined;
  status: number;
  isError: boolean;
  errorMessage: string;
}

export async function apiGet<R>(
  endPoint: string,
  params: any,
  data: any
): Promise<Response<R>> {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: 'GET',
    // cancelToken: params?.source?.token ? params.source.token : '',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };
  try {
    let response = await axios(configs);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
        isError: false,
        errorMessage: ''
      };
    }

    return {
      data: null,
      status: response.status,
      isError: true,
      errorMessage: 'Lỗi khi thực hiện yêu cầu.'
    };
  } catch (error) {
    throw error;
  }
}

export async function fetchVideoApi(endPoint, method, params, data = null) {
  let response;
  try {
    let configs: AxiosRequestConfig = {
      url: `${videoUrlApi}/api/v1/${endPoint}`,
      method: method,
      headers: {
        Authorization: `Bearer ${TOKEN_VIDEO_UPLOAD}`
      },
      params,
      data
    };
    response = await axios(configs);
  } catch (error: any) {
    //Nếu access token video hết hạn thì trả về lỗi 400 và thực hiện lại api
    // 400 for token error, 401 for token expired
    if (error.response.status === 400 || error.response.status === 401) {
      const res = await socialTokenVideo();
      if (res.status === 200) {
        localStorage.setItem('token_video', res.data.access_token);
        updateTokenVideoUpload(res.data.access_token);
        response = await fetchVideoApi(endPoint, method, params, data);
      }
    }
  }
  return response;
}

export async function apiPost<R>(
  endPoint: string,
  params: any,
  data: any
): Promise<Response<R>> {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };
  try {
    let response = await axios(configs);
    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
        isError: false,
        errorMessage: ''
      };
    }
    return {
      data: null,
      status: response.status,
      isError: true,
      errorMessage: 'Lỗi khi thực hiện yêu cầu.'
    };
  } catch (error: any) {
    if (error.response.status === 429) {
      store.dispatch(errorManyReq(true));
      store.dispatch(dataErrorManyReq(error.response.data));
    } else {
      store.dispatch(errorManyReq(false));
    }
    throw error;
  }
}

export async function apiPatch<R>(
  endPoint: string,
  params: any,
  data: any
): Promise<Response<R>> {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };
  try {
    let response = await axios(configs);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
        isError: false,
        errorMessage: ''
      };
    }

    return {
      data: null,
      status: response.status,
      isError: true,
      errorMessage: 'Lỗi khi thực hiện yêu cầu.'
    };
  } catch (error: any) {
    if (error.response.status === 429) {
      store.dispatch(errorManyReq(true));
    } else {
      store.dispatch(errorManyReq(false));
    }
    throw error;
  }
}

export async function apiPut<R>(
  endPoint: string,
  params: any,
  data: any
): Promise<Response<R>> {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };
  try {
    let response = await axios(configs);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
        isError: false,
        errorMessage: ''
      };
    }

    return {
      data: null,
      status: response.status,
      isError: true,
      errorMessage: 'Lỗi khi thực hiện yêu cầu.'
    };
  } catch (error) {
    throw error;
  }
}

export async function apiDelete<R>(
  endPoint: string,
  params: any,
  data: any
): Promise<Response<R>> {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/${endPoint}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };
  try {
    let response = await axios(configs);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
        isError: false,
        errorMessage: ''
      };
    }

    return {
      data: null,
      status: response.status,
      isError: true,
      errorMessage: 'Lỗi khi thực hiện yêu cầu.'
    };
  } catch (error) {
    throw error;
  }
}
