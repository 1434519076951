import React from 'react';
import {
  Typography,
  List,
  Box,
  IconButton,
  Theme,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles, createStyles, styled, useTheme } from '@mui/styles';
import Conversation from 'src/pages/Chat/GlobalPopupChat/Conversation';
import Loading from 'src/components/Loading/Loading';
import { useSelector } from 'react-redux';
import {
  searchConversation,
  searchConversationGroup,
  getListGroupsChat
} from 'src/apis/socialChat.apis';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import { urlRocketChat } from 'src/util/config';
import ForwardMessageComponent from 'src/components/Dialog/DialogForward/ForwardMessageComponent';
import { buttonColor } from 'src/constants/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const DialogForwardMessage = (props: any) => {
  const { open, handleClose, message } = props;
  const [keyword, setKeyword] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [listGroup, setListGroup] = React.useState<any>('');
  const [listResultSearch, setListResultSearch] = React.useState([]);
  const [statusForwardMessage, setStatusForwardMessage] = React.useState<any>(
    []
  );
  const friends =
    useSelector((state: any) => state.socialFriendsReducer.listFriends) ?? [];
  const listConversations = useSelector(
    (state: any) => state.socialChatReducer.listConversations
  );

  async function getListGroupChat() {
    let response = await getListGroupsChat(5);
    if (response.status === 200) {
      setListGroup(response.data);
    }
  }
  const recentlyChat = listConversations
    ?.filter((item: any) => item.t !== 'cp')
    ?.splice(0, 5);
  const debounceSearchRocketChat = React.useCallback(
    _.debounce(async function (q) {
      let response = await searchConversation(q);
      let responseGr = await searchConversationGroup(q);
      if (response.status === 200 && responseGr.status === 200) {
        let listConversations = response.data.map(el => {
          if (el.t && el.t === 'p') {
            el.type = 'group';
            el.avatar_media = {
              avatar_top: `${urlRocketChat}/avatar/${el.members[0]}`,
              avatar_bottom: `${urlRocketChat}/avatar/${el.members[1]}`
            };
            el.rid = el._id;
            el.displayName = el.name;
          } else {
            el.displayName = el.name;
            el.avatar_media = {
              show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                el?.userId ?? el?._id
              }`
            };
            el.userId = el._id;
            el.type = 'user';
          }

          return el;
        });
        setIsLoading(false);
        setListResultSearch(listConversations);
      }
    }, 800),
    []
  );
  React.useEffect(() => {
    getListGroupChat();
  }, []);

  React.useEffect(() => {
    if (keyword) {
      handleSearch();
    }
  }, [keyword]);

  const handleSearch = () => {
    debounceSearchRocketChat(keyword);
  };
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      buttonGroup: {
        '& .css-1daoff1-MuiButtonBase-root-MuiButton-root:hover': {
          backgroundColor: '#cccccc36 !important'
        },
        width: '100%',
        justifyContent: 'flex-start !important',
        color: '#333 !important',
        marginTop: '20px !important',
        padding: '12px 15px !important',
        borderRadius: '10px !important',
        fontSize: '17px !important'
      },
      sendGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      },
      sendFriend: {
        minWidth: '30px !important',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? `${buttonColor.backgroundColor} !important`
            : '#E7F3FF !important',
        color:
          theme.palette.mode === 'dark'
            ? '#00b2ff !important'
            : `${buttonColor.backgroundColor} !important`,
        fontWeight: '500 !important'
      },
      textBold: {
        fontWeight: '500 !important'
      },
      textFriend: {
        fontSize: '17px !important',
        fontWeight: '500 !important',
        marginLeft: '15px !important',
        marginBottom: '5px !important'
      },
      title: {
        fontSize: '20px !important',
        fontWeight: 'bold !important',
        textAlign: 'center'
      },
      dialogContent: {
        height: '100%',
        maxHeight: '548px',
        '&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
          borderRadius: '100px'
        }
      }
    })
  );
  const classes = useStyles();

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography className={classes.title}>Chuyển tiếp </Typography>
      </BootstrapDialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <InputSearch
          label="Tìm kiếm người và nhóm"
          keyword={keyword}
          setKeyword={setKeyword}
          type={'share-group'}
          styleInput={{
            backgroundColor: ' #ccc3',
            borderRadius: '30px'
          }}
        />
        {keyword?.length < 1 ? (
          <div>
            <List>
              {recentlyChat?.length ? (
                <>
                  <Typography className={classes.textFriend}>
                    Gần đây
                  </Typography>
                  {recentlyChat?.map((item: any, index) => {
                    return (
                      <ForwardMessageComponent
                        message={message}
                        key={item?._id}
                        item={item}
                        statusForwardMessage={statusForwardMessage}
                        setStatusForwardMessage={setStatusForwardMessage}
                      />
                    );
                  })}
                </>
              ) : null}
            </List>
            <List>
              {listGroup?.length ? (
                <>
                  <Typography className={classes.textFriend}>Nhóm</Typography>
                  {listGroup?.map((item: any, index) => {
                    return (
                      <ForwardMessageComponent
                        message={message}
                        key={item?._id}
                        item={item}
                        statusForwardMessage={statusForwardMessage}
                        setStatusForwardMessage={setStatusForwardMessage}
                      />
                    );
                  })}
                </>
              ) : null}
            </List>
            <List>
              {friends?.length ? (
                <>
                  <Typography className={classes.textFriend}>
                    Người liên hệ
                  </Typography>
                  {friends?.map((item: any, index) => (
                    <ForwardMessageComponent
                      message={message}
                      item={item}
                      key={item?._id}
                      statusForwardMessage={statusForwardMessage}
                      setStatusForwardMessage={setStatusForwardMessage}
                    />
                  ))}
                </>
              ) : null}
            </List>
          </div>
        ) : (
          <>
            {isLoading ? (
              <Loading style={{ marginTop: '20px' }} />
            ) : listResultSearch ? (
              listResultSearch.map((el, index) => {
                return (
                  <Box key={el} sx={{ width: '100%', height: 'auto' }}>
                    <ForwardMessageComponent
                      item={el}
                      message={message}
                      statusForwardMessage={statusForwardMessage}
                      setStatusForwardMessage={setStatusForwardMessage}
                    />
                  </Box>
                );
              })
            ) : (
              <div style={{ textAlign: 'center' }}>
                Không tìm thấy cuộc trò chuyện.
              </div>
            )}
          </>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DialogForwardMessage;
