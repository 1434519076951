import ClearIcon from '@mui/icons-material/Clear';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  IconButton,
  Link,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { getListInvitedPageApi } from 'src/apis/socialPages.api';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import DialogPauseGroup from 'src/components/Dialog/DialogPauseGroup';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import ListButton from 'src/components/List/ListButton';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import SearchGlobalLeft from 'src/pages/SeachGlobal/SeachGlobalLeft';
import { getListConversationsChatPageReq } from 'src/store/action/socialChatAction';
import {
  getListMyPageReq,
  getPageDetailReq
} from 'src/store/action/socialPageAction';
import PageCreateUpdate from '../PageCreateUpdate';
import avatar_default from 'src/assets/images/avatar_default.jpg';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        height: '100%',
        position: 'fixed',
        padding: '5px 0px 0px 6px !important'
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 10px'
      },
      footerPage: {
        position: 'absolute',
        bottom: '51px',
        left: 0,
        width: '100%',
        zIndex: 1200
      },
      rootPage: {
        width: '100%',
        height: '80%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
          borderRadius: 10
        }
      },
      inputSearch: {
        width: '100%'
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '30px !important',
        height: 35,
        padding: '7px !important',
        margin: 'auto'
      },
      notchedOutline: {
        border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
      },
      multilineColor: {
        fontSize: 13
      },
      avatar: {
        width: '56px !important',
        height: '56px !important'
      },
      list: {
        width: '100%'
      },
      selected: {
        backgroundColor: '#ECF3FF !important'
      },
      titlePageName: {
        display: 'inline-block',
        fontSize: 12,
        width: '300px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color:
          theme.palette.mode === 'dark' ? '#fff' : buttonColor.backgroundColor
      },
      createPage: {
        width: 300,
        padding: '10px 10px 0px 10px'
      }
    }),
  { index: 1 }
);

const styleLeftNoChat = {
  margin: '0px 0px',
  width: '100%',
  minHeight: '64px',
  display: 'flex',
  justifyContent: 'space-between'
};
const styleLeftChat = {
  margin: '0px 0px',
  display: 'flex',
  minHeight: '48px',
  justifyContent: 'space-between',
  padding: '6px',
  backgroundColor: 'transparent',
  border: '1px solid #dee3e9'
};

const Index = props => {
  const { isShown, setIsShown } = props;
  const matchCreate = useMediaQuery('(max-width:1000px)');
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const theme = useTheme();
  const dispatch = useDispatch();
  const totalCensorshipDiary: number = useSelector(
    (state: any) => state.pageReducer.totalCensorshipDiary
  );
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const hasMore = useSelector((state: any) => state.pageReducer.hasMore);
  const [keyParams, setKeyParams] = React.useState<any>(null);
  const [keyword, setKeyword] = React.useState<any>('');
  const [selectedIndex, setSelectedIndex] = React.useState<any>('feed');
  const [openDialogPauseGroup, setOpenDialogPauseGroup] = React.useState(false);
  const [createPage, setCreatePage] = useState(false);
  const [checkCreatePage, setCheckCreatePage] = useState(false);
  const [accountInvites, setAccountInvites] = useState(0);
  const [totalMessage, setTotalMessage] = useState(0);
  let { id } = match.params;
  const pageInfo = useSelector((state: any) => state.pageReducer.info);

  React.useEffect(() => {
    if (id && pageInfo?.id !== id) {
      dispatch(getPageDetailReq(id));
    }
  }, [id]);

  const rolePreviewEdit: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer.rolePreviewEdit
  );

  React.useEffect(() => {
    if (id) {
      if (rolePreviewEdit.role && pageInfo.id) {
        dispatch(getListConversationsChatPageReq(pageInfo?.id, 10));
      }
    }
  }, [pageInfo?.id, rolePreviewEdit?.role]);
  const listConversationsChatPage = useSelector(
    (state: any) => state.socialChatReducer.listConversationsChatPage
  );

  const pagesAdmin = useSelector(
    (state: any) => state.pageReducer.my_pages
  )?.map((el: any) => ({
    id: el.id,
    username: el.username,
    title: el.title,
    avatar_social: el.avatar_media
      ? el.avatar_media
      : {
          ...meInfo.avatar_media,
          frame: null,
          show_url: meInfo?.avatar_static
        },
    action: () => {
      if (el?.page_purpose === 'sell_products') {
        history.push(`/marketplace/shop/home?page_id=${el.id}&page=1&type=all`);
      } else {
        match.params.key === 'chat'
          ? history.push(`/page/${el.id}/chat`)
          : history.push(`/page/${el?.username ? el.username : el.id}`);
      }
    },
    label: el.title
  }));

  const [hiddenPageLeft, setHiddenPageLeft] = useState<Boolean>(false);
  // const getCountMessageUnreadPage = async (idPage: any) => {
  //   try {
  //     if (!id) {
  //       throw new Error('Invalid idPage');
  //     }
  //     let res = await getCountMessagePage([idPage]);
  //     if (res.status === 200) {
  //       console.log(
  //         '🚀 ~ file: index.tsx:236 ~ getCountMessageUnreadPage ~ res:',
  //         res
  //       );
  //       if (res.data.rooms[0].countUnreadPage > 0) {
  //         setTotalMessage(1);
  //       }
  //     }
  //   } catch (error) {}
  // };
  // React.useEffect(() => {
  //   getCountMessageUnreadPage(id);
  // }, []);

  React.useEffect(() => {
    if (listConversationsChatPage && listConversationsChatPage.length > 0) {
      const result = listConversationsChatPage.reduce(
        (total: any, item: any) => {
          if (item.countUnreadPage > 0) {
            return total + 1;
          }
          return total;
        },
        0
      );
      setTotalMessage(result);
    } else {
      setTotalMessage(0);
    }
  }, [JSON.stringify(listConversationsChatPage)]);

  const listMenuPages = [
    {
      id: 'page',
      title: 'Trang của bạn',
      icon: <i className="fa-light fa-flag" style={{ fontSize: 20 }}></i>,
      style: {
        height: 36,
        width: 36,
        backgroundColor: 'transparent',
        border: '1px solid #c4c4c4'
      },
      action: () => {
        history.push(PATHS.PAGE);
      },
      position: 'bottom'
    },
    {
      id: 'discover',
      title: 'Khám phá',
      icon: <i className="fa-light fa-compass" style={{ fontSize: 28 }}></i>,
      style: {
        height: 36,
        width: 36,
        backgroundColor: 'transparent',
        border: '1px solid #c4c4c4'
      },
      action: () => {
        history.push(PATHS.PAGE_DISCOVER);
      },
      position: 'bottom'
    },
    {
      id: 'likePage',
      title: 'Trang đã thích',
      icon: <i className="fa-light fa-thumbs-up" style={{ fontSize: 24 }}></i>,
      style: {
        height: 36,
        width: 36,
        backgroundColor: 'transparent'
      },
      action: () => {
        history.push(PATHS.PAGE_LIKED);
      },
      position: 'bottom'
    },
    {
      id: 'invitePage',
      title: 'Lời mời',
      icon: <i className="fa-light fa-user-plus" style={{ fontSize: 23 }}></i>,
      style: {
        height: 36,
        width: 36,
        backgroundColor: 'transparent'
      },
      action: () => {
        history.push(PATHS.PAGE_INVITES);
      },
      position: 'bottom',
      expandInfo: `${accountInvites} lượt mời mới`
    }
  ];

  const listSettingAdmin = [
    {
      id: id,
      title: 'Trang chủ',
      icon: (
        <i style={{ fontSize: 21 }} className="fa-light fa-house-chimney"></i>
      ),
      style: {
        height: 36,
        width: 36,
        backgroundColor: 'transparent'
      },
      position: 'top',
      action: () => {
        history.push(`/page/${id}`);
      },
      listChildComponent: [
        'about',
        'photos',
        'albums',
        'music',
        'video',
        'event',
        'group',
        'review'
      ] //list children in url
    },
    {
      id: 'chat',
      title: 'Hộp thư',
      numberNoti: totalMessage > 99 ? '99+' : totalMessage,
      icon: <i style={{ fontSize: 22 }} className="fa-light fa-comment"></i>,
      style: {
        height: 36,
        width: 36,
        backgroundColor: 'transparent'
      },
      notification: 0,
      position: 'top',
      action: () => {
        history.push(
          listConversationsChatPage.length > 0
            ? `/page/${id}/chat?rid=${listConversationsChatPage[0]._id}`
            : `/page/${id}/chat`
        );
      }
    },
    {
      id: 'notifications',
      title: 'Thông báo',
      notiHasArrow: totalCensorshipDiary > 0,
      icon: <i style={{ fontSize: 22 }} className="fa-light fa-bell"></i>,
      style: {
        height: 36,
        width: 36,
        backgroundColor: 'transparent'
      },
      stylePadding: {
        padding: '0px !important'
      },
      styleButton: {
        marginLeft: '0px !important'
      },
      notification: 99,
      accordion: true,
      itemChildren: [
        {
          id: 'moderation_activity_log',
          title: 'Nhật ký kiểm duyệt',
          numberNoti: totalCensorshipDiary > 0 ? totalCensorshipDiary : null,
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-flag"
            ></i>
          ),
          action: () => {
            history.push(`/page/${id}/notifications/moderation_activity_log`);
          }
        },
        {
          id: 'page_quality',
          title: 'Chất lượng trang',
          icon: <i style={{ fontSize: 22 }} className="fa-light fa-shield"></i>,
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          action: () => {
            history.push(`/page/${id}/page_quality`);
          }
        }
      ], //ist children in left bar
      position: 'top'
    },
    {
      id: 'schedule_post',
      title: 'Bài viết đã lên lịch',
      icon: (
        <i
          style={{ fontSize: 22 }}
          className="fa-light fa-calendar-lines-pen "
        ></i>
      ),
      style: {
        height: 36,
        width: 36,
        backgroundColor: 'transparent'
      },
      position: 'top',
      action: () => {
        history.push(`/page/${id}/schedule_post`);
      }
    },
    {
      id: 'edit_page_info',
      title: 'Chỉnh sửa thông tin trang',
      icon: <i style={{ fontSize: 22 }} className="fa-light fa-pen"></i>,
      style: {
        height: 36,
        width: 36,
        backgroundColor: 'transparent'
      },
      position: 'top',
      action: () => {
        history.push(`/page/${id}/edit_page_info`);
      }
    },
    {
      id: 'settings_common',
      title: 'Cài đặt',
      styleTitle: { fontWeight: '600' },
      styleButton: { margin: '0 !important' },
      icon: <i className="fa-regular fa-gear" style={{ fontSize: 22 }}></i>,
      action: () => {
        history.push(`/page/${id}/settings`);
      },
      accordion: true,
      style: { height: 36, width: 36 },
      itemChildren: [
        {
          id: 'settings',
          title: 'Chung',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: <i style={{ fontSize: 20 }} className="fa-light fa-gear"></i>,
          style: { height: 36, width: 36 },
          action: () => {
            history.push(`/page/${id}/settings`);
          },
          position: 'top'
        },
        // {
        //   id: 'notifications',
        //   title: 'Thông báo',
        //   styleTitle: { fontWeight: '400' },
        //   styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
        //   icon: (
        //     <i style={{ fontSize: 20 }} className="fa-light fa-earth-asia"></i>
        //   ),
        //   style: { height: 36, width: 36 },
        //   action: () => {
        //     history.push(`/page/${id}/settings/notifications`);
        //   },
        //   position: 'top'
        // },
        {
          id: 'admin_roles',
          title: 'Vai trò trên trang',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: <i style={{ fontSize: 20 }} className="fa-light fa-user"></i>,
          style: { height: 36, width: 36 },
          action: () => {
            history.push(`/page/${id}/settings/admin_roles`);
          },
          position: 'top'
        },
        {
          id: 'account_holder_page',
          title: 'Chủ tài khoản Trang',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{ fontSize: 20 }}
              className="fa-light fa-building-columns"
            ></i>
          ),
          style: { height: 36, width: 36 },
          action: () => {
            history.push(`/page/${id}/settings/account_holder_page`);
          },
          position: 'top'
        },
        {
          id: 'people_and_other_pages',
          title: 'Người và trang khác',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i style={{ fontSize: 20 }} className="fa-light fa-user-group"></i>
          ),
          style: { height: 36, width: 36 },
          action: () => {
            history.push(`/page/${id}/settings/people_and_other_pages`);
          },
          position: 'top'
        },
        {
          id: 'page_management_history',
          title: 'Nhật ký hoạt động',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{ fontSize: 20 }}
              className="fa-light fa-list-timeline"
            ></i>
          ),
          style: { height: 36, width: 36 },
          action: () => {
            history.push(`/page/${id}/settings/page_management_history`);
          },
          position: 'top'
        },
        {
          id: 'page_management_message',
          title: 'Cài đặt hộp thư',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '92%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{ fontSize: 20 }}
              className="fa-light fa-message-dots"
            ></i>
          ),
          style: { height: 36, width: 36 },
          action: () => {
            history.push(`/page/${id}/settings/page_management_message`);
          },
          position: 'top'
        }
      ],
      position: 'top'
    }
  ];

  const loadActivity = maxId => {
    dispatch(
      getListMyPageReq({
        max_id: maxId,
        limit: 10
      })
    );
  };

  const funcLoad = () => {
    let pageLast = [...pagesAdmin].pop();
    let maxId = pageLast?.id;
    loadActivity(maxId);
  };

  const fetchPageInvites = async setStateFunction => {
    let response: any = await getListInvitedPageApi(null);
    if (response.status === 200) {
      setStateFunction(response.data.meta.total);
    }
  };

  //check list children in url
  const checkComponentChildren = (listChildren: Array<string>, str: string) => {
    return listChildren.includes(str);
  };

  React.useEffect(() => {
    switch (match.path) {
      case PATHS.PAGE:
        setSelectedIndex('page');
        break;
      case PATHS.PAGE_DISCOVER:
        setSelectedIndex('discover');
        break;
      case PATHS.PAGE_LIKED:
        setSelectedIndex('likePage');
        break;
      case PATHS.PAGE_INVITES:
        setSelectedIndex('invitePage');
        break;
      case PATHS.PAGE_DETAIL: {
        let { id, key, tab } = match?.params;
        setKeyParams('key');
        if (id) {
          setSelectedIndex(id);
        }
        if (key) {
          setSelectedIndex(key);
        }
        if (tab) {
          setSelectedIndex(tab);
        }

        //check list children in url
        const listChildComponent = listSettingAdmin.find(
          item => item.id === id
        )?.listChildComponent;
        if (
          listChildComponent &&
          (checkComponentChildren(listChildComponent, key) ||
            checkComponentChildren(listChildComponent, tab))
        ) {
          setSelectedIndex(id);
        }
        break;
      }
    }
  }, [match.path, JSON.stringify(match.params)]);

  React.useEffect(() => {
    fetchPageInvites(setAccountInvites);
  }, []);
  const renderHeader = () => {
    switch (match.path) {
      case PATHS.PAGE_DETAIL:
        let { key } = match.params;
        if (hiddenPageLeft) {
          switch (key) {
            case 'search':
              return null;
            default:
              return (
                <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                  {key !== 'chat' && (
                    <Typography style={{ fontWeight: 'bold' }} variant="h5">
                      Quản lý Trang
                    </Typography>
                  )}
                  <div style={{ margin: '5px 10px 0px 5px' }}>
                    <IconButtonOptions
                      name={
                        key === 'chat'
                          ? isShown
                            ? pageInfo.title
                            : null
                          : pageInfo.title
                      }
                      stylePopup={{
                        marginTop: '12px',
                        width: '290px'
                      }}
                      avatar_social={
                        pageInfo.avatar_media
                          ? pageInfo.avatar_media
                          : {
                              frame: null,
                              show_url: avatar_default
                            }
                      }
                      icon={
                        isShown ? (
                          <i
                            style={{ fontSize: '16px' }}
                            className="fa-solid fa-caret-down"
                          ></i>
                        ) : null
                      }
                      openPopup={true}
                      style={key !== 'chat' ? styleLeftNoChat : styleLeftChat}
                      options={[
                        pagesAdmin?.map((el: any) => ({
                          ...el,
                          title: null,
                          label: el.title,
                          checkbox: true,
                          avatar_social: {
                            type: 'avatar',
                            avatarObj: el?.avatar_social,
                            style: {
                              width: 36,
                              height: 36,
                              margin: '0px 15px 0px 0px',
                              border: '1px solid #fff'
                            }
                          },
                          styleLabel: {
                            wordBreak: 'break-word',
                            width: '190px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                          }
                        }))
                      ]}
                      style_avatar_social={
                        key === 'chat'
                          ? {
                              width: 36,
                              height: 36,
                              marginRight: isShown ? '15px' : '0px'
                            }
                          : { width: 48, height: 48 }
                      }
                      styleListMenu={{
                        minWidth: '290px',
                        height: '250px',
                        overflowY: 'scroll'
                      }}
                      styleNameButton={{
                        wordBreak: 'break-word',
                        width: '190px',
                        color: 'text.primary'
                      }}
                      searchFeature={true}
                      infiniteScroll={true}
                      funcLoad={funcLoad}
                      hasMore={hasMore}
                    />
                  </div>
                </Grid>
              );
          }
        } else {
          return null;
        }

      case PATHS.PAGE_CREATE: {
        return (
          <Grid container>
            <Grid container item xs={12}>
              <IconButton
                sx={{
                  backgroundColor: '#999999',
                  color: '#fff',
                  height: 35,
                  width: 35
                }}
                onClick={() => history.go(-1)}
              >
                <ClearIcon />
              </IconButton>
              &nbsp;
              <Typography style={{ fontWeight: 'bold' }} variant="h5">
                Tạo Trang
              </Typography>
            </Grid>
          </Grid>
        );
      }
      default:
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={{ fontWeight: 'bold' }} variant="h5">
                Trang
              </Typography>
              <div style={{ margin: '8px 14px 8px 3px', width: '100%' }}>
                <InputSearch
                  keyword={keyword}
                  setKeyword={setKeyword}
                  label="Tìm kiếm trang page"
                  action={() => history.push(`/search/pages?q=${keyword}`)}
                />
              </div>
            </Grid>
          </Grid>
        );
    }
  };

  useEffect(() => {
    setHiddenPageLeft(rolePreviewEdit?.page_owner_id === pageInfo?.id);
  }, [rolePreviewEdit?.page_owner_id]);

  const renderBody = () => {
    switch (match.path) {
      case PATHS.PAGE_DETAIL:
        let { key } = match.params;
        if (hiddenPageLeft) {
          switch (key) {
            case 'search':
              return {
                bodyTop: null,
                bodyCenter: (
                  <div style={{ marginBottom: '200px' }}>
                    <SearchGlobalLeft
                      type="page"
                      styleGui={{
                        height: '100%',
                        width: 380,
                        backgroundColor: 'background.primary',
                        position: 'none',
                        overflowY: 'hidden'
                      }}
                    />
                    ;
                  </div>
                ),
                bodyFooter: null
              };
            case 'chat':
              const listMenuChat = listSettingAdmin.map(item => {
                return {
                  id: item.id,
                  icon: item.icon,
                  style: item.style,
                  position: item.position,
                  numberNoti: item?.numberNoti ? item.numberNoti : undefined
                };
              });
              return {
                bodyTop: null,
                bodyCenter: (
                  <div style={{ marginBottom: '200px' }}>
                    <ListButton
                      item={
                        !isShown && match.params.key === 'chat'
                          ? listMenuChat
                          : listSettingAdmin
                      }
                      selectedItem={selectedIndex}
                      width={isShown ? '290px' : '48px'}
                      styleAccordionDetail={{ padding: '8px 5px 5px' }}
                      styleBtnGrow={{ marginTop: '10px' }}
                      styleBtnMyGrow={{
                        padding: '0px !important',
                        margin: '0px'
                      }}
                      typeAction={'action'}
                    />
                  </div>
                ),
                bodyFooter: null
              };
            default:
              return {
                bodyTop: null,
                bodyCenter: (
                  <div style={{ marginBottom: '200px' }}>
                    <ListButton
                      item={listSettingAdmin}
                      selectedItem={selectedIndex}
                      width={'290px'}
                      styleAccordionDetail={{ padding: '8px 5px 5px' }}
                      styleBtnGrow={{ marginTop: '10px' }}
                      styleBtnMyGrow={{
                        padding: '0px 0px 0px 0px !important',
                        margin: '0px'
                      }}
                      typeAction={'action'}
                    />
                  </div>
                ),
                bodyFooter: null
              };
          }
        } else {
          return {
            bodyTop: null,
            bodyCenter: null,
            bodyFooter: null
          };
        }

      case PATHS.PAGE_CREATE: {
        return {
          bodyTop: null,
          bodyCenter: (
            <div style={{ marginBottom: '20px' }}>
              <PageCreateUpdate
                createPage={createPage}
                setCreatePage={setCreatePage}
                setCheckCreatePage={setCheckCreatePage}
              />
            </div>
          ),
          bodyFooter: null
        };
      }

      default:
        return {
          bodyTop: (
            <>
              <div className={classes.createPage}>
                <ButtonCustom
                  label="Tạo Trang mới"
                  solid={true}
                  action={() => {
                    history.push(PATHS.PAGE_CREATE);
                  }}
                  style={{
                    color: '#e7f3ff',
                    backgroundColor: buttonColor.backgroundColor
                  }}
                  icon={<i className="far fa-plus"></i>}
                />
              </div>
            </>
          ),
          bodyCenter: (
            <div style={{ marginBottom: '60px', borderRadius: '8px' }}>
              <ListButton
                item={listMenuPages.filter(el => el.position === 'bottom')}
                itemChildren={pagesAdmin}
                selectedItem={selectedIndex}
                width={'290px'}
                styleAccordionDetail={{ padding: '3px' }}
                styleBg={{ marginBottom: '6px' }}
                typeAction={'action'}
              />
            </div>
          ),
          bodyFooter: null
        };
    }
  };

  const renderFooter = () => {
    switch (match.path) {
      case PATHS.PAGE_DETAIL:
        if (match.params?.key === 'search' || match.params?.key === 'chat')
          return null;
        if (hiddenPageLeft) {
          return null;
        } else {
          return null;
        }

      case PATHS.PAGE_CREATE: {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 1,
              backgroundColor: 'button.primary.background',
              marginBottom: '-5px'
            }}
          >
            <Typography variant="body2">
              Bạn có thể thêm ảnh, thông tin liên hệ và các chi tiết khác sau
              khi tạo Trang.
            </Typography>
            <ButtonInherit
              loading={createPage}
              label="Tạo trang"
              color={buttonColor.backgroundColor}
              textColor="#fff"
              fullWidth={true}
              action={() => {
                setCreatePage(true);
              }}
              disabled={!checkCreatePage}
            />
          </Box>
        );
      }

      case PATHS.PAGE_LIKED: {
        return null;
      }

      case PATHS.PAGE_INVITES: {
        return null;
      }

      default:
        return null;
    }
  };

  return (
    <Box
      className={classes.root}
      onMouseEnter={() => {
        if (match.path === PATHS.PAGE_DETAIL) {
          setIsShown(true);
        }
      }}
      onMouseLeave={() => {
        if (match.path === PATHS.PAGE_DETAIL) {
          setIsShown(false);
        }
      }}
      sx={{
        position:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? 'fixed'
            : 'static',

        width:
          match.path === PATHS.PAGE_CREATE && matchCreate
            ? '100%'
            : match.path === PATHS.PAGE_CREATE
            ? 360
            : match.params?.key === 'chat' && !isShown
            ? 65
            : renderBody().bodyTop ||
              renderBody().bodyCenter ||
              renderBody().bodyFooter
            ? 310
            : 0,
        margin:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? 0
            : '5px 10px 40px -20px',
        padding:
          match.params?.key === 'search'
            ? '0px 0px 0px 0px'
            : '5px 0px 40px 6px',
        backgroundColor: 'background.primary',
        boxShadow:
          theme.palette.mode === 'dark'
            ? '1px 0px 5px 0.4px rgb(200 200 200 / 15%)'
            : '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      }}
    >
      {match.params?.key !== 'search' && (
        <div className={classes.headerPage}>{renderHeader()}</div>
      )}

      <div className={classes.rootPage} id="scrollDivPage">
        {match.path !== PATHS.PAGE_CREATE && renderBody().bodyTop && (
          <Divider />
        )}
        {renderBody().bodyTop}
        {match.path !== PATHS.PAGE_CREATE &&
          match.params?.key !== 'search' &&
          match.params?.key !== 'chat' && <div style={{ height: 10 }}></div>}
        {match.path !== PATHS.PAGE_CREATE && renderBody().bodyCenter && (
          <Divider />
        )}
        {renderBody().bodyCenter}
        {match.path !== PATHS.PAGE_CREATE &&
          match.path !== PATHS.PAGE &&
          renderBody().bodyFooter && <Divider />}
      </div>

      <div className={classes.footerPage}>
        {match.path !== PATHS.PAGE_CREATE &&
          match.path !== PATHS.PAGE &&
          renderFooter() && <Divider />}
        {renderFooter()}
      </div>

      <DialogPauseGroup
        open={openDialogPauseGroup}
        setOpen={setOpenDialogPauseGroup}
      />
    </Box>
  );
};

export default Index;
