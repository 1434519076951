import { collapseString } from 'src/common/string';
import angryUrl from 'src/assets/images/iconEmojiSocial/angry.gif';
import hahaUrl from 'src/assets/images/iconEmojiSocial/haha.gif';
import loveUrl from 'src/assets/images/iconEmojiSocial/love.gif';
import sadUrl from 'src/assets/images/iconEmojiSocial/sad.gif';
import yayUrl from 'src/assets/images/iconEmojiSocial/yay.gif';
import wowUrl from 'src/assets/images/iconEmojiSocial/wow.gif';
import likeUrl from 'src/assets/images/iconEmojiSocial/like.gif';
import angryStaticUrl from 'src/assets/images/iconEmojiStatic/angry.png';
import hahaStaticUrl from 'src/assets/images/iconEmojiStatic/haha.png';
import loveStaticUrl from 'src/assets/images/iconEmojiStatic/love.png';
import sadStaticUrl from 'src/assets/images/iconEmojiStatic/sad.png';
import yayStaticUrl from 'src/assets/images/iconEmojiStatic/yay.png';
import wowStaticUrl from 'src/assets/images/iconEmojiStatic/wow.png';
import likeStaticUrl from 'src/assets/images/iconEmojiStatic/like.png';

export const imgReaction = {
  angry: angryUrl,
  haha: hahaUrl,
  love: loveUrl,
  sad: sadUrl,
  yay: yayUrl,
  wow: wowUrl,
  like: likeUrl
};

export const staticImgReaction = {
  angry: angryStaticUrl,
  haha: hahaStaticUrl,
  love: loveStaticUrl,
  sad: sadStaticUrl,
  yay: yayStaticUrl,
  wow: wowStaticUrl,
  like: likeStaticUrl
};

export const staticUrlImgReaction = {
  angryUrl: 'src/assets/images/iconEmojiSocial/angry.gif',
  hahaUrl: 'src/assets/images/iconEmojiSocial/haha.gif',
  loveUrl: 'src/assets/images/iconEmojiSocial/love.gif',
  sadUrl: 'src/assets/images/iconEmojiSocial/sad.gif',
  yayUrl: 'src/assets/images/iconEmojiSocial/yay.gif',
  wowUrl: 'src/assets/images/iconEmojiSocial/wow.gif',
  likeUrl: 'src/assets/images/iconEmojiSocial/like.gif'
};

export function linkify(text, type: any = null) {
  var urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return (
      '<a href="' +
      url +
      '" target="_blank">' +
      collapseString(url, 80) +
      '</a>'
    );
  });
}

export const getListMention = string => {
  let regex =
    /@[0-9a-z](\.?[0-9a-zàáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ])*/g;
  return string.match(regex)?.pop().slice(1);
};

export function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  let newText = text?.replace(urlRegex, function (url) {
    return '<>' + url + '<>';
  });
  if (newText) {
    return newText.split('<>').filter(el => validURL(el));
  }
}

//Check xem mot chuoi co la link khong
// function validURL(str) {
//   var pattern = new RegExp(
//     '^(https?:\\/\\/)?' + // protocol
//       '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
//       '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
//       '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
//       '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
//       '(\\#[-a-z\\d_]*)?$',
//     'i'
//   ); // fragment locator
//   return !!pattern.test(str);
// }

const validURL = urlString => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export function hexToRgbA(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
    );
  }
  throw new Error('Bad Hex');
}

export function removeVietnameseTones(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  );
  return str.split(' ').join('');
}

function validateColorCode(colorCode) {
  const regex =
    /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$|^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$|^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d+(\.\d+)?)\)$|^hsl\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$|^hsla\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%,\s*(\d+(\.\d+)?)\)$/i;
  return regex.test(colorCode);
}

export const getColorGradient = (color, theme = undefined as any) => {
  if (!color || !validateColorCode(color)) {
    return {
      backgroundColor: '#fff'
    };
  } else {
    return {
      background:
        theme?.palette?.mode === 'dark'
          ? `linear-gradient(180deg, rgba(138,223,244,1) 0%, ${hexToRgbA(
              color
            )} 0%, rgba(36, 37, 38,1) 100%)`
          : `linear-gradient(180deg, rgba(2,0,36,1) 0%, ${hexToRgbA(
              color
            )} 0%, rgba(255,255,255,1) 50%)`
    };
  }
};

export const durationToTime = time => {
  var sec_num: any = Math.ceil(time); // don't forget the second param
  var hours: any = Math.floor(sec_num / 3600);
  var minutes: any = Math.floor((sec_num - hours * 3600) / 60);
  var seconds: any = sec_num - hours * 3600 - minutes * 60;

  if (!hours) {
    hours = '';
  }
  if (minutes < 10 && hours) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return (hours && hours + ':') + minutes + ':' + seconds;
};

//------- Xác định file ảnh hay video ----------
export function determineFileType(fileType) {
  const isImage = fileType?.startsWith('image');
  const isVideo = fileType?.startsWith('video');

  if (isImage) {
    return 'img';
  } else if (isVideo) {
    return 'video';
  } else {
    return 'div';
  }
}
// const fileType = determineFileType("video.mp4" ||"image")
// -----------------------------------------------------
export function formatDuration(value: number) {
  const hour = Math.max(Math.floor(value / 3600), 0);
  const minute = Math.max(Math.floor((value % 3600) / 60), 0);
  const second = Math.max(Math.floor(value % 60), 0);

  const formattedHour = hour > 0 ? `${hour}:` : '';
  const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
  const formattedSecond = second < 10 ? `0${second}` : `${second}`;
  return `${formattedHour}${formattedMinute}:${formattedSecond}`;
}
