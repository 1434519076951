import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  ClickAwayListener,
  List,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import AvatarSocial from '../ProfileCardImage/AvatarSocial';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    option: {
      '& li': {
        fontFamily: 'Segoe UI,Roboto !important'
      }
    },
    optionSelected: {
      backgroundColor:
        Object(theme).length > 0
          ? theme.palette.button.primary.hover
          : `${buttonColor.backgroundColor} !important`,
      color:
        Object(theme).length > 0
          ? theme.palette.text.secondary
          : '#fff !important'
    },
    cssScroll: {
      '&::-webkit-scrollbar': {
        width: '10px'
      },
      '&::-webkit-scrollbar-track': {
        background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
        borderRadius: '100px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundImage: 'linear-gradient(to bottom,#ffa400,#00aefd)'
      }
    },
    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        borderColor:
          Object(theme).length > 0
            ? `${theme.palette.myBackgroundColor?.main} !important`
            : theme.palette?.mode === 'dark'
            ? '#121212 !important'
            : '#fff !important'
      },
      '&:hover': {
        backgroundColor:
          Object(theme).length > 0
            ? theme.palette.myBackgroundColor?.main
            : theme.palette?.mode === 'dark'
            ? '#121212 !important'
            : '#fff !important'
      },
      backgroundColor:
        Object(theme).length > 0
          ? theme.palette.myBackgroundColor?.main
          : theme.palette?.mode === 'dark'
          ? '#121212 !important'
          : '#fff !important',
      borderRadius: '30px !important',
      height: 35,
      padding: '5px !important',
      margin: 'auto'
    },
    listItemText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold'
    },
    notchedOutline: {
      border: `1px solid ${
        Object(theme).length > 0
          ? theme.palette.myBackgroundColor?.main
          : theme.palette?.mode === 'dark'
          ? '#121212 !important'
          : '#fff !important'
      } !important`
    },
    multilineColor: {
      fontSize: 13
    },

    coverMembers: {
      margin: '0px 10px ',
      paddingTop: '10px'
    },
    loading: { width: '96%', margin: 'auto' },
    icon: {
      opacity: '0.5'
    },
    coverText: {
      marginTop: '20px'
    },
    textTitle: {
      fontWeight: 'bold',
      opacity: '.7'
    }
  };
});

export default function CustomizedMenus(props) {
  const classes = useStyles();
  const {
    label,
    color,
    options,
    autoChoice,
    optionSelected,
    setOptionSelected,
    accountsSearch,
    keyword,
    type,
    buttonDisplay,
    setButtonDisplay,
    group,
    style,
    styleListData,
    stylePopper,
    width,
    role,
    checkSearchSuccessfully,
    handleSearch,
    hasClosePoper = false //truyền true để click item con là tắt popper cha, mặc định là false (không tắt)
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [anchorElActivityDisplay, setAnchorElActivityDisplay] =
    React.useState<HTMLElement | null>(null);
  const [openPopperActivityDisplay, setOpenPopperActivityDisplay] =
    React.useState(false);

  const renderMember = members => {
    if (members?.length > 0) {
      return members.map((member: any, index: number) => (
        <MenuItem
          key={index}
          onClick={() => {
            setOptionSelected(member);
            setOpenPopper(false);
            setAnchorEl(null);
          }}
        >
          <ListItemAvatar>
            <AvatarSocial
              type="feed"
              avatarObj={member.avatar_media}
              style={{ width: 40, height: 40, marginRight: '15px' }}
            ></AvatarSocial>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography className={classes.listItemText}>
                {!!member?.display_name
                  ? member?.display_name
                  : member?.username}
              </Typography>
            }
          />
        </MenuItem>
      ));
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  React.useEffect(() => {
    if (autoChoice && Object.keys(options).length) {
      setOptionSelected && setOptionSelected(options[0]);
    }
  }, [autoChoice, JSON.stringify(options)]);

  const renderText = () => {
    if (type === 'search') {
      if (optionSelected) {
        return optionSelected;
      } else {
        return label;
      }
    } else if (optionSelected) {
      return buttonDisplay;
    } else {
      return label;
    }
  };

  const handleButtonDisplay = () => {
    if (type === 'date') {
      return (
        <div>
          <TextField
            sx={{
              textTransform: 'none',
              fontWeight: '600',
              fontSize: 16,
              width: '180px',
              height: '36px',
              backgroundColor: color ? color : 'background.input',
              color: !color ? 'text.primary' : 'undefined',
              marginRight: '7px'
            }}
            label="Chọn ngày"
            type="date"
            size="small"
            value={null}
            fullWidth
            onChange={item => {
              setOptionSelected(item);
              setAnchorEl(null);
              setOpenPopper(false);
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      );
    } else {
      return (
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            width,
            textTransform: 'none',
            padding: '4px 12px',
            fontWeight: '600',
            fontSize: '.875rem',
            marginRight: '7px',
            height: '36px',
            backgroundColor: color ? color : 'button.primary.background',
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'button.primary.hover'
            },
            ...style
          }}
        >
          {renderText()}
        </Button>
      );
    }
  };

  const renderPopper = (itemBody, type) => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          if (type === 'popper_activity') {
            setOpenPopperActivityDisplay(false);
          } else {
            setOpenPopper(false);
          }
        }}
      >
        <Popper
          id="simple_menu"
          anchorEl={
            type === 'popper_activity' ? anchorElActivityDisplay : anchorEl
          }
          key="popper"
          open={
            type === 'popper_activity' ? openPopperActivityDisplay : openPopper
          }
          placement="bottom-end"
          disablePortal
          style={{
            ...stylePopper,
            padding: '5px 0px',
            zIndex: 1001
          }}
        >
          <Paper key={'paper'}>{itemBody}</Paper>
        </Popper>
      </ClickAwayListener>
    );
  };

  const renderOptionItem = () => {
    return options?.options?.map((item: any) => (
      <MenuItem
        key={item.id}
        className={
          item.id === optionSelected?.id
            ? classes.optionSelected
            : classes.option
        }
        onClick={() => {
          setOptionSelected(item);
          setAnchorEl(null);
          setOpenPopperActivityDisplay(false);
          setButtonDisplay && setButtonDisplay(item?.label);
          setAnchorElActivityDisplay(null);
          hasClosePoper && setOpenPopper(false);
        }}
        sx={{
          padding: '10px',
          textTransform: 'none',
          fontWeight: '600',
          fontSize: '.875rem'
        }}
        disableRipple
      >
        {item?.icon}
        {item?.label}
      </MenuItem>
    ));
  };

  const renderMenuItem = () => {
    if (type === 'search') {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <TextField
            size="small"
            sx={{
              margin: '10px 10px 20px 10px',
              width: '93%'
            }}
            fullWidth
            variant="outlined"
            defaultValue={keyword}
            placeholder="Tìm kiếm thành viên"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              startAdornment: (
                <i
                  style={{ marginRight: 5, padding: 5 }}
                  className="fas fa-search"
                ></i>
              ),
              classes: {
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline
              },
              className: classes.multilineColor
            }}
            onChange={e => {
              handleSearch(e.target.value, role);
            }}
          />
          {accountsSearch.length ? (
            <div className={classes.coverMembers}>
              <List sx={styleListData} className={classes.cssScroll}>
                {renderMember(accountsSearch)}
              </List>
            </div>
          ) : keyword &&
            accountsSearch.length === 0 &&
            checkSearchSuccessfully ? (
            <Typography
              sx={{
                margin: '10px',
                fontSize: '1rem',
                fontWeight: 500
              }}
            >
              Không có kết quả cho “{keyword}“
            </Typography>
          ) : (
            <>
              <div className={classes.cssScroll} style={styleListData}>
                {renderMember(options)}
              </div>
              <div>
                {group?.member_count > 0 ? (
                  <>{renderMember(options?.members)}</>
                ) : (
                  <div style={{ textAlign: 'center', padding: '40px 0' }}>
                    <div className={classes.icon}>
                      <i className="fad fa-users-slash fa-8x"></i>
                    </div>
                    <div className={classes.coverText}>
                      <Typography
                        variant="h5"
                        component="h2"
                        className={classes.textTitle}
                      >
                        Không có thành viên nào
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      );
    } else if (type === 'multiOption') {
      return (
        <div
          style={{
            margin: '10px',
            width: '250px'
          }}
        >
          <MenuItem
            sx={{ padding: 0 }}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setAnchorElActivityDisplay(event.currentTarget);
              setOpenPopperActivityDisplay(true);
            }}
          >
            <Typography
              variant="body1"
              sx={{
                margin: '10px',
                textTransform: 'none',
                fontWeight: '600',
                fontSize: '.875rem',
                float: 'left',
                marginRight: '100px'
              }}
            >
              {options?.label}
            </Typography>
            <ArrowForwardIosIcon sx={{ fontSize: '15px' }} />
          </MenuItem>
          {openPopperActivityDisplay &&
            renderPopper(renderOptionItem(), 'popper_activity')}
        </div>
      );
    } else {
      return options?.map((item: any) => (
        <MenuItem
          key={item.id}
          className={
            item.id === optionSelected?.id
              ? classes.optionSelected
              : classes.option
          }
          onClick={() => {
            setOptionSelected && setOptionSelected(item);
            setAnchorEl && setAnchorEl(null);
            setOpenPopper(false);
            setButtonDisplay && setButtonDisplay(item?.label);
          }}
          disableRipple
        >
          {item?.icon}
          {item?.label}
        </MenuItem>
      ));
    }
  };

  return (
    <div>
      {handleButtonDisplay()}
      {openPopper && renderPopper(renderMenuItem(), 'popper')}
    </div>
  );
}
