import { apiDelete, apiGet, apiPatch, apiPost } from './apis';
export const getCalendarGrowApi = async (params: any) => {
  return apiGet('projects', params, null);
};
export const getHighlightedProject = async (params: any) => {
  return apiGet('top_projects', params, null);
};

export const projectCategoriesApi = async (params: any = {}) => {
  return apiGet('categories', { category_type: 'project', ...params }, null);
};

export const createUpdateGrowApi = async (data: any, type: any) => {
  var formdata = new FormData();
  formdata.append('title', data.title);
  data.project_host_added.forEach(item => {
    formdata.append('project_host_added[]', item);
  });
  data.project_host_removed.forEach(item => {
    formdata.append('project_host_removed[]', item);
  });
  if (data.banner) {
    if (data.banner.file && !data.banner.id) {
      formdata.append('banner[id]', data.banner.file);
    }
    if (data.banner.id) {
      formdata.append('banner[id]', data.banner.id);
      if (data.banner.show_url) {
        formdata.append('banner[show_url]', data.banner.show_url);
      }
    }
  }
  if (data.commitment_form) {
    if (data.commitment_form.file && data.commitment_form.id) {
      formdata.append('commitment_form[id]', data.commitment_form.id);
    }
  }
  if (data.business_license) {
    if (data.business_license.file && data.business_license.id) {
      formdata.append('business_license[id]', data.business_license.id);
    }
  }
  if (data.pitchdeck) {
    if (data.pitchdeck.file && data.pitchdeck.id) {
      formdata.append('pitchdeck[id]', data.pitchdeck.id);
    }
  }

  if (data.introduction_video) {
    if (data.introduction_video.file && data.introduction_video.id) {
      formdata.append('introduction_video[id]', data.introduction_video.id);
    }
  }
  if (data.location) {
    formdata.append('location[lat]', data.location.lat);
    formdata.append('location[lng]', data.location.lng);
  }
  if (data.phone_number) {
    formdata.append('phone_number', data.phone_number);
  }
  if (data.refund_policy) {
    formdata.append('refund_policy', data.refund_policy);
  }
  if (data.risks) {
    formdata.append('risks', data.risks);
  }
  if (data.visibility) {
    formdata.append('visibility', data.visibility);
  }
  if (data.description_project) {
    formdata.append('description_project', data.description_project);
  }
  if (data.description_company) {
    formdata.append('description_company', data.description_company);
  }
  if (data.plan) {
    formdata.append('plan', data.plan);
  }
  if (data.address) {
    formdata.append('address', data.address);
  }
  if (data.category_id) {
    formdata.append('category_id', data.category_id);
  }
  if (data.target_value) {
    formdata.append('target_value', data.target_value);
  }
  if (data.start_date) {
    formdata.append('start_date', data.start_date);
  }
  if (data.due_date) {
    formdata.append('due_date', data.due_date);
  }
  if (data.page_owner_id) {
    formdata.append('page_owner_id', data.page_owner_id);
  }
  if (data.page_id) {
    formdata.append('page_id', data.page_id);
  }

  if (type === 'create') {
    return apiPost('projects', null, formdata);
  }

  return apiPatch(`projects/${type}`, null, formdata);
};

export const getDetailGrowApi = async (idGrow: any) => {
  return apiGet(`projects/${idGrow}`, null, null);
};

export const deleteGrowApi = async (id: any) => {
  return apiDelete(`projects/${id}`, null, null);
};

export const getTimelineGrowApi = async (idGrow: any, params: any) => {
  return apiGet(`timelines/project/${idGrow}`, params, null);
};

export const createInviteGrow = async (idGrow: any, params: any) => {
  return apiPost(`projects/${idGrow}/invitations`, null, {
    target_account_ids: params
  });
};

export const interesteGrowApi = async (idGrow: any) => {
  return apiPost(
    `projects/${idGrow}/project_followers`,

    null,
    null
  );
};
export const noInteresteGrowApi = async (idGrow: any) => {
  return apiDelete(`projects/${idGrow}/project_followers`, null, null);
};

export const getListInvitation = async (params: any) => {
  return apiGet('project_invitations', params, null);
};

export const respondInvitationGrow = async (idGrow: any, params: any) => {
  return apiPost(
    `projects/${idGrow}/invitations/invitations_respond`,

    null,
    params
  );
};

export const getListHostOfGrow = async (idGrow: any, params: any) => {
  return apiGet(
    `projects/${idGrow}/invitation_hosts`,

    params,
    null
  );
};

export const respondInvitationHostGrow = async (idGrow: any, data: any) => {
  return apiPost(
    `projects/${idGrow}/invitation_hosts/invitations_respond`,

    null,
    data
  );
};
export const investmentRequest = async (idGrow: any, data: any) => {
  return apiPost(
    `projects/${idGrow}/request_invests`,

    null,
    data
  );
};

export const getListHostGrow = async (idGrow: any, params: any) => {
  return apiGet(`projects/${idGrow}/hosts`, params, null);
};

export const getListFaqs = async (idGrow: any) => {
  return apiGet(`projects/${idGrow}/faqs`, null, null);
};

export const CRUDFaqs = async (type, idGrow: any, params: any, data) => {
  if (type === 'patch') {
    return apiPatch(`projects/${idGrow}/faqs/${params}`, null, data);
  }

  if (type === 'delete') {
    return apiDelete(`projects/${idGrow}/faqs/${params}`, null, data);
  }

  return apiPost(`projects/${idGrow}/faqs`, null, data);
};

export const getListInvitationHost = async (params: any) => {
  return apiGet('project_invitation_hosts', params, null);
};

export const postRepostGrow = async (data: any) => {
  var formdata = new FormData();

  formdata.append('note', data.note);
  if (data.report_category_id) {
    formdata.append('report_category_id', data.report_category_id);
  }
  if (data.entity_id) {
    formdata.append('entity_id', data.entity_id);
  }
  if (data.entity_type) {
    formdata.append('entity_type', data.entity_type);
  }

  return apiPost(`report_violations`, null, formdata);
};
export const verificationsUser = async (data: any) => {
  var formdata = new FormData();

  if (data.idCardBack) {
    formdata.append('identity_card_back[id]', data.idCardBack);
  }
  if (data.idCardFont) {
    formdata.append('identity_card_front[id]', data.idCardFont);
  }
  if (data.idVideo) {
    formdata.append('identity_verification_video[id]', data.idVideo);
  }

  return apiPost(`identity_verifications`, null, formdata);
};

export const postPaymentGrow = async (data: any, idGrow) => {
  var formdata = new FormData();
  formdata.append('ecoin_amount', data.amount);
  formdata.append('detail_type', data.detail_type);

  return apiPost(`projects/${idGrow}/transaction`, null, formdata);
};

export const getAccountBacker = (idGrow: any) => {
  return apiGet(`projects/${idGrow}/list_backer`, null, null);
};
export const getMediaGrow = (growId: any, params: any) => {
  return apiGet(`projects/${growId}/media_attachments`, params, null);
};
