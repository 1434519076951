export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const UPDATE_PATH = 'UPDATE_PATH';
export const RESET_ACTIVITIES = 'RESET_ACTIVITIES';
export const RESET_PINNED_POST = 'RESET_PINNED_POST';
export const RESET_POST_LOAD = 'RESET_POST_LOAD';
export const RESET_FALSE_POST_LOAD = 'RESET_FALSE_POST_LOAD';

export const GET_POST_REQUEST = 'GET_POST_REQUEST';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const DELETE_OLD_POST_SUCCESS = 'DELETE_OLD_POST_SUCCESS ';

export const DELETE_POST_REQUESTED = 'DELETE_POST_REQUESTED';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';

export const UPDATE_POST_REQUESTED = 'UPDATE_POST_REQUESTED';
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS';

export const UPDATE_FOLLOW_SUCCESS = 'UPDATE_FOLLOW_SUCCESS';

export const PIN_POST_SUCCESS = 'PIN_POST_SUCCESS';
export const UNPIN_POST_SUCCESS = 'UNPIN_POST_SUCCESS';

export const GET_PIN_POST_REQ = 'GET_PIN_POST_REQ';
export const GET_PIN_POST_SUCCESS = 'GET_PIN_POST_SUCCESS';

export const SAVE_POST_SUCCESS = 'SAVE_POST_SUCCESS';
export const UNSAVE_POST_SUCCESS = 'UNSAVE_POST_SUCCESS';

export const MUTE_POST_SUCCESS = 'MUTE_POST_SUCCESS';
export const UNMUTE_POST_SUCCESS = 'UNMUTE_POST_SUCCESS';

export const ERROR_POST = 'ERROR_POST';
export const RESET_ERROR_POST = 'RESET_ERROR_POST';

export const GET_SCHEDULED_POST_REQ = 'GET_SCHEDULED_POST_REQ';
export const GET_SCHEDULED_POST_SUCCESS = 'GET_SCHEDULED_POST_SUCCESS';
export const SCHEDULED_POST_SUCCESS = 'SCHEDULED_POST_SUCCESS';
export const SCHEDULED_POST_UPDATE_SUCCESS = 'SCHEDULED_POST_UPDATE_SUCCESS';
export const LOADING_POST_SCHEDULED = 'LOADING_POST_SCHEDULED';

export const REACTIONS_POST = 'REACTIONS_POST';
export const UPDATE_REACTIONS_POST = 'UPDATE_REACTIONS_POST';
export const UPDATE_REBLOG_COUNT = 'UPDATE_REBLOG_COUNT';

export const LIST_POST_TAGS = 'LIST_POST_TAGS';
export const UPDATE_POST_TAGS = 'UPDATE_POST_TAGS';

export const GET_LIST_BOOKMARK_COLLECTION_REQ =
  'GET_LIST_BOOKMARK_COLLECTION_REQ';
export const GET_LIST_BOOKMARK_COLLECTION_SUCCESS =
  'GET_LIST_BOOKMARK_COLLECTION_SUCCESS';

export const COMMENT_TOTAL = 'COMMENT_TOTAL';

export const UPDATE_IS_LOAD = 'UPDATE_IS_LOAD';

export const NOTI_POST = 'NOTI_POST';
export const RESET_NOTI_POST = 'RESET_NOTI_POST';
export const RESET_NOTI_GROUP = 'RESET_NOTI_GROUP';
export const RESET_NOTI_SCHEDULED_POST_GROUP =
  'RESET_NOTI_SCHEDULED_POST_GROUP';
export const IS_UPDATING_POST = 'IS_UPDATING_POST';
export const NOTI_SHARE_POST = 'NOTI_SHARE_POST';
export const RESET_NOTI_SHARE_POST = 'RESET_NOTI_SHARE_POST';

export const UPDATE_ID_WATCH_HISTORY = 'UPDATE_ID_WATCH_HISTORY';

export const STATUS_PROCESSING_POST = 'STATUS_PROCESSING_POST';

export const ADD_POST_TO_VIEW = 'ADD_POST_TO_VIEW';

export const REMOVE_POST_TO_VIEW = 'REMOVE_POST_TO_VIEW';

export const UN_TAG_MENTION = 'UN_TAG_MENTION';
export const ADD_POST_FROM_DIARY = 'ADD_POST_FROM_DIARY';
export const PIN_OR_UNPIN_POST = 'PIN_OR_UNPIN_POST';
