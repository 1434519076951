import * as React from 'react';
import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  DialogTitle,
  InputBase,
  FormControl,
  DialogActions,
  Box
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';
import { saveInfoGroupChat } from 'src/apis/socialChat.apis';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor } from 'src/constants/styles';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 10,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',

      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

export default function DialogChangeNameGroupChat(props) {
  const { open, setOpen, conversation, name, setNoti, setOpenSnackbar } = props;
  const [nameChange, setNameChange] = React.useState(name);
  const [lengthText, setLengthText] = React.useState<number>(
    nameChange?.length ?? 0
  );
  const [isError, setIsError] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateNameGroupChat = async () => {
    try {
      const form = {
        roomName: nameChange
      };
      const response = await saveInfoGroupChat(conversation.rid, form);
      if (response.status === 200) {
        // dispatch(changeNamePopupChat(conversation.rid, nameChange));
        // const db: any = new Dexie(`chat_emso_db_${rocketId}`);
        // db.version(1).stores({
        //   conversations: '++rid',
        //   conversations_pinned: '++rid'
        // });
        // db.conversations.update(conversation.rid, {
        //   displayName: nameChange,
        //   fname: nameChange,
        //   '_updatedAt.$date': updatedAt
        // });

        handleClose();
      }
    } catch (error: any) {
      setOpenSnackbar && setOpenSnackbar(true);
      setNoti &&
        setNoti({
          code: 400,
          message: error.response.data.errorType
        });
      console.log('[changedNameGroupChat]', error);
    }
  };

  const handleInputChange = event => {
    const inputValue = event.target.value;

    setNameChange(inputValue);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '8px',

            width: '500px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
              Đổi tên nhóm chat
            </Typography>
          </div>
          <div
            style={{
              height: '100%',
              width: '36px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButtonOptions
              icon={
                <i
                  style={{ fontSize: 14, fontWeight: 600, color: '#505256' }}
                  className="fa-light fa-xmark-large"
                ></i>
              }
              action={() => handleClose()}
              style={{
                borderRadius: '50%',
                width: '36px',
                marginRight: '0',
                zIndex: 1,
                top: '12px',
                right: '16px',
                position: 'absolute'
              }}
            />
          </div>
        </DialogTitle>
        <Divider sx={{ marginTop: '5px' }} />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 0
          }}
        >
          <Typography
            style={{ fontSize: '14px', fontWeight: '400', marginLeft: '20px' }}
          >
            Mọi người đều biết khi tên nhóm chat thay đổi.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '5px 20px'
            }}
          >
            <FormControl
              style={{ position: 'relative', width: '100%' }}
              variant="standard"
            >
              <BootstrapInput
                fullWidth
                placeholder="Nhập tên nhóm mới..."
                name="title"
                value={nameChange}
                onChange={event => {
                  setLengthText(event.target.value.length);
                  if (event.target.value.length > 25) {
                    setIsError(true);
                  } else {
                    handleInputChange(event);
                    setIsError(false);
                  }
                }}
              />
              <Box sx={{ position: 'absolute', right: '12px', top: '10px' }}>
                {/* right, top dựa theo padding của class MuiInputBase-input*/}
                <Typography>{lengthText}/25</Typography>
              </Box>
            </FormControl>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: isError ? 'space-between' : 'start',
              paddingLeft: '20px'
            }}
          >
            {isError && (
              <FormHelperText id="component-error-text" sx={{ color: '#f00' }}>
                {isError ? 'Chỉ được phép nhập tối đa 25 ký tự' : ''}
              </FormHelperText>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            label={'Hủy'}
            style={{
              width: '40px',
              height: '30px',
              // color: buttonColor.backgroundColor,
              // backgroundColor: '#e4e6eb',
              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleClose}
          />
          <ButtonInherit
            disabled={conversation.displayName === nameChange}
            label={'Lưu'}
            style={{
              width: '40px',
              height: '30px',
              color: '#fff',
              backgroundColor:
                conversation.displayName === nameChange
                  ? '#e4e6eb'
                  : buttonColor.backgroundColor,
              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleUpdateNameGroupChat}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
