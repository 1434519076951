import * as types from 'src/constants/store/eventSocial';
const _ = require('lodash');

const initialState = {
  info: {} as any,
  rolePreviewEdit: { role: '' },
  error: false,
  tempInteract: [],
  infoPreview: null,
  isLoading: false,
  status: 'normal',
  isCheckShowEmptyLayout: false
};

export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EVENT_PREVIEW_INFO: {
      let { data } = action.payload;
      let newState = _.cloneDeep(data);
      return { ...state, infoPreview: newState };
    }

    case types.GET_EVENT_DETAIL_SUCCESS: {
      let { data } = action.payload;
      return {
        ...state,
        info: data,
        infoPreview: null
      };
    }
    case types.CHECK_UNMOUNT_DETAIL_EVENT: {
      return { ...state, error: false, infoPreview: null };
    }
    case types.RESET_EVENT: {
      return {
        info: {
          id: '',
          title: '',
          description: '',
          page_category_id: [],
          avatar: null,
          banner: null
        },
        rolePreviewEdit: { role: '' },
        infoPreview: null
      };
    }
    case types.UPDATE_STATUS_EVENT: {
      let { data } = action.payload;
      let old_status;
      let new_status;
      if (data) new_status = `users_${data}_count`;

      if (state.info.event_relationship.status)
        old_status = `users_${state.info.event_relationship.status}_count`;

      return {
        ...state,
        info: {
          ...state.info,
          event_relationship: {
            ...state.info.event_relationship,
            status: data
          },
          [old_status]: --state.info[old_status],
          [new_status]: ++state.info[new_status]
        }
      };
    }
    case types.GET_EVENT_DETAIL_ERROR: {
      return { ...state, error: true, infoPreview: null };
    }

    case types.LOADING_EVENT: {
      return { ...state, isLoading: action.payload.value };
    }

    // case types.SHARE_EVENT_SUCCESS: {
    //   return { ...state, info: action.payload.data };
    // }

    case types.STATUS_EVENT_UPDATE: {
      const status = action.payload;
      return { ...state, status: status };
    }

    case types.CHECK_EMPTY_LAYOUT_EVENT: {
      const isCheck = action.payload;
      return { ...state, isCheckShowEmptyLayout: isCheck };
    }
    default:
      return state;
  }
};
