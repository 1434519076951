import { all, call, delay, put, takeEvery } from 'redux-saga/effects';
import * as types from 'src/constants/store/socialChat';
import Dexie, { Table } from 'dexie';
import {
  getListConversationSuccess,
  eventReceiveSubscriptionRoom,
  getListConversationsChatPageSuccess
} from '../action/socialChatAction';
import {
  getSubscriptions,
  getListMessage,
  getListConversationsChatPage,
  getSubscriptionsPinned,
  getSubscriptionsAll,
  sendMessageApi
} from 'src/apis/socialChat.apis';
import { urlRocketChat } from 'src/util/config';
import store from 'src/store/store';

const typesChat: any = types;

let hasCalledSubscriptionsPinned = false;
export const getListConversationSaga = function* ({ payload }) {
  const meInfo = store.getState().meReducer ?? '';
  const rocketId = meInfo?.info.id;

  let listRoomsPinned = [];

  try {
    const { limit, lastUpdated } = payload;
    const _response = yield call(getSubscriptions, null, limit, lastUpdated);

    if (_response.status === 200) {
      const listSubscription = JSON.parse(_response.data.message)?.result
        ?.update;

      if (listSubscription && listSubscription.length) {
        const listRooms = listSubscription.map(el => {
          if (el.t === 'cp' && el.RoomsList) {
            el.RoomsList[0].label = [];
            el.RoomsList[0].notes = [];
            el.RoomsList[0].is_user_chat = null;
          }

          el.type =
            el?.t === 'd' ? 'user' : el?.t === 'cp' ? 'chat_page' : 'group';
          el.avatar_media =
            el?.t === 'd'
              ? {
                  show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                    el.RoomsList[0]?.uids.length > 1
                      ? el.RoomsList[0]?.uids?.filter(el => el !== rocketId)[0]
                      : el.RoomsList[0]?.uids[0]
                  }`
                }
              : el?.t === 'p' || el?.t === 'c'
              ? {
                  avatar_top: {
                    show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.RoomsList[0].uids[0]}`
                  },
                  avatar_bottom: {
                    show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.RoomsList[0].uids[1]}`
                  }
                }
              : el?.t === 'cp'
              ? {
                  show_url: `${urlRocketChat}/api/v1/teams.getAvatar?teamId=${el.prid}`
                }
              : null;
          el.lastMessage = el.RoomsList?.[0]?.lastMessage;
          el.usersCount = el.RoomsList[0]?.usersCount;
          el.topic = el.topic || el?.RoomsList?.[0]?.topic || '';
          el.ro = el?.RoomsList[0]?.ro;

          el.userId =
            el?.t === 'd'
              ? el.RoomsList[0]?.uids?.filter(el => el !== rocketId)[0]
              : null;
          el.fname =
            el?.t === 'cp'
              ? el?.RoomsList[0]?.real_name ??
                el?.RoomsList[0]?.display_name ??
                el?.name
              : el.fname;

          el.username = el?.name;

          return el;
        });

        if (!hasCalledSubscriptionsPinned) {
          const _responsePinned = yield call(
            getSubscriptionsPinned,
            lastUpdated
          );
          if (_responsePinned.status === 200) {
            hasCalledSubscriptionsPinned = true;
            const listSubscriptionPinned = JSON.parse(
              _responsePinned.data.message
            )?.result?.update;
            if (listSubscriptionPinned) {
              listRoomsPinned = listSubscriptionPinned.map(el => {
                if (el.t === 'cp' && el.RoomsList) {
                  el.RoomsList[0].label = [];
                  el.RoomsList[0].notes = [];
                  el.RoomsList[0].is_user_chat = null;
                }

                el.type =
                  el?.t === 'd'
                    ? 'user'
                    : el?.t === 'cp'
                    ? 'chat_page'
                    : 'group';

                el.avatar_media =
                  el?.t === 'd'
                    ? {
                        show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                          el.RoomsList[0]?.uids?.filter(
                            el => el !== rocketId
                          )[0]
                        }`
                      }
                    : el?.t === 'p' || el?.t === 'c'
                    ? {
                        avatar_top: {
                          show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.RoomsList[0].uids[0]}`
                        },
                        avatar_bottom: {
                          show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.RoomsList[0].uids[1]}`
                        }
                      }
                    : el?.t === 'cp'
                    ? {
                        show_url: `${urlRocketChat}/api/v1/teams.getAvatar?teamId=${el.prid}`
                      }
                    : null;
                el.fname = el?.fname ?? el.name;
                el.ro = el?.RoomsList[0]?.ro;

                if (el.type === 'chat_page' || el.t === 'cp') {
                  el.avatar_media = {
                    show_url: `${urlRocketChat}/api/v1/teams.getAvatar?teamId=${el.prid}`
                  };
                }
                el.topic = el.topic || el?.RoomsList?.[0]?.topic || '';
                el.lastMessage = el?.RoomsList[0]?.lastMessage;

                el.usersCount = el.RoomsList[0]?.usersCount;
                el.userId =
                  el?.t === 'd'
                    ? el.RoomsList[0]?.uids?.filter(el => el !== rocketId)[0]
                    : null;

                el.username = el?.name;
                // el.RoomsList[0] = null;

                return el;
              });
            }
          }
        }
        yield put(
          getListConversationSuccess([...listRoomsPinned, ...listRooms])
        );
      }
    }
    // const isDBLocalExists = yield Dexie.exists(`chat_emso_db_${rocketId}`);
    // if (isDBLocalExists) {
    // console.log('exists, updated db');
    // const db: any = yield new Dexie(`chat_emso_db_${rocketId}`);
    // db.version(1).stores({
    //   conversations: 'rid',
    //   conversations_pinned: 'rid'
    // });
    // const conversations = yield db.conversations.toArray();
    // const listRooms = conversations.sort(
    //   (a, b) => b?._updatedAt?.$date - a?._updatedAt?.$date
    // );
    // const updatedAt = listRooms[0]?._updatedAt?.$date;
    // const response = yield call(getSubscriptionsAll, updatedAt, lastUpdated);
    // const listSubscription = JSON.parse(response.data.message)?.result
    //   ?.update;
    // if (listSubscription && listSubscription.length > 0) {
    //   const listRooms = listSubscription.map(el => {
    //     if (el.t === 'cp' && el.RoomsList) {
    //       el.RoomsList[0].label = [];
    //       el.RoomsList[0].notes = [];
    //       el.RoomsList[0].is_user_chat = null;
    //     }
    //     if (!el.disableNotifications) {
    //       el.disableNotifications = false;
    //     }
    //     if (!el.blocker) {
    //       el.blocker = false;
    //     }
    //     if (!el.blocked) {
    //       el.blocked = false;
    //     }
    //     el.type =
    //       el?.t === 'd' ? 'user' : el?.t === 'cp' ? 'chat_page' : 'group';
    //     el.avatar_media =
    //       el?.t === 'd'
    //         ? {
    //             show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
    //               el.RoomsList[0]?.uids?.filter(el => el !== rocketId)[0]
    //             }`
    //           }
    //         : el?.t === 'p' || el?.t === 'c'
    //         ? {
    //             avatar_top: {
    //               show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.RoomsList[0].uids[0]}`
    //             },
    //             avatar_bottom: {
    //               show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.RoomsList[0].uids[1]}`
    //             }
    //           }
    //         : el?.t === 'cp'
    //         ? {
    //             show_url: `${urlRocketChat}/api/v1/teams.getAvatar?teamId=${el.prid}`
    //           }
    //         : null;
    //     el.lastMessage = el.RoomsList[0]?.lastMessage;
    //     el.usersCount = el.RoomsList[0]?.usersCount;
    //     el.userId =
    //       el?.t === 'd'
    //         ? el.RoomsList[0]?.uids?.filter(el => el !== rocketId)?.[0]
    //         : null;
    //     el.fname =
    //       el?.t === 'cp'
    //         ? el?.real_name ??
    //           el?.RoomsList?.[0]?.real_name ??
    //           el?.RoomsList?.[0]?.display_name ??
    //           el?.name
    //         : el.fname;
    //     el.username = el?.name;
    //     if (!el.topic) {
    //       el.topic = el.RoomsList[0].topic;
    //     }
    //     return el;
    //   });
    //   db.conversations.bulkPut(listRooms);
    // }
    // } else {
    // const db = yield new Dexie(`chat_emso_db_${rocketId}`);
    // db.version(1).stores({
    //   conversations: '++rid',
    //   conversations_pinned: '++rid'
    // });
    // const response = yield call(getSubscriptions, null, null, lastUpdated);
    // if (response.status === 200) {
    //   const listSubscription = JSON.parse(response.data.message)?.result
    //     ?.update;
    //   if (listSubscription && listSubscription.length > 0) {
    //     const listRooms = listSubscription.map(el => {
    //       if (el.t === 'cp' && el.RoomsList) {
    //         el.RoomsList[0].label = [];
    //         el.RoomsList[0].notes = [];
    //         el.RoomsList[0].is_user_chat = null;
    //       }

    //       el.type =
    //         el?.t === 'd' ? 'user' : el?.t === 'cp' ? 'chat_page' : 'group';
    //       el.avatar_media =
    //         el?.t === 'd'
    //           ? {
    //               show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
    //                 el.RoomsList[0]?.uids?.filter(el => el !== rocketId)[0]
    //               }`
    //             }
    //           : el?.t === 'p' || el?.t === 'c'
    //           ? {
    //               avatar_top: {
    //                 show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.RoomsList[0].uids[0]}`
    //               },
    //               avatar_bottom: {
    //                 show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.RoomsList[0].uids[1]}`
    //               }
    //             }
    //           : el?.t === 'cp'
    //           ? {
    //               show_url: `${urlRocketChat}/api/v1/teams.getAvatar?teamId=${el.prid}`
    //             }
    //           : null;
    //       el.lastMessage = el.RoomsList?.[0]?.lastMessage;
    //       el.usersCount = el.RoomsList[0]?.usersCount;
    //       if (!el.disableNotifications) {
    //         el.disableNotifications = false;
    //       }
    //       if (!el.blocker) {
    //         el.blocker = false;
    //       }
    //       if (!el.blocked) {
    //         el.blocked = false;
    //       }
    //       el.ro = el?.RoomsList[0]?.ro;
    //       el.userId =
    //         el?.t === 'd'
    //           ? el.RoomsList[0]?.uids?.filter(el => el !== rocketId)?.[0]
    //           : null;
    //       el.fname =
    //         el?.t === 'cp'
    //           ? el?.RoomsList?.[0]?.real_name ??
    //             el?.RoomsList?.[0]?.display_name ??
    //             el?.name
    //           : el.fname;
    //       el.username = el?.name;
    //       // el.RoomsList[0] = null;
    //       if (!el.topic) {
    //         el.topic = el.RoomsList[0].topic;
    //       }
    //       return el;
    //     });
    //     db.conversations
    //       .bulkAdd(listRooms)
    //       .then(() => {
    //         console.log('Thêm một lượng lớn thành công!');
    //       })
    //       .catch(error => {
    //         console.error('Thêm một lượng lớn thất bại:', error);
    //       });
    //   }
    // }
    // }
  } catch (err: any) {
    console.log(err);
  }
};
let lastTimeAction = null;
export const getMessageSaga = function* ({ payload }) {
  try {
    const { conversation, lastTime } = payload;
    lastTimeAction = lastTime;
    const userId = localStorage.getItem('userId');
    const db: any = yield new Dexie(`chat_emso_db_${userId}`);
    db.version(1).stores({
      conversations: 'rid',
      conversations_pinned: 'rid'
    });
    const updatedAt = Date.now();
    const _response = yield call(getListMessage, conversation.rid, 1, lastTime);
    if (_response.status === 200) {
      const data = JSON.parse(_response.data.message);
      if (data?.result?.messages[0] && !!data?.result?.messages[0]) {
        const roomId = conversation.rid || conversation._id;
        const conversationDBLocal = yield db.conversations.get(roomId);
        if (conversation?.blocked !== conversationDBLocal?.blocked) {
          db.conversations.update(roomId, {
            blocked: conversation.blocked,
            '_updatedAt.$date': updatedAt
          });
        }
        if (conversation?.blocker !== conversationDBLocal?.blocker) {
          db.conversations.update(roomId, {
            blocker: conversation.blocker,
            '_updatedAt.$date': updatedAt
          });
        }
        if (conversation?.f !== conversationDBLocal?.f) {
          db.conversations.update(roomId, {
            f: conversation.f,
            '_updatedAt.$date': updatedAt
          });
        }
        const message = data.result.messages[0];
        yield delay(1000);
        if (lastTimeAction === lastTime) {
          yield put(eventReceiveSubscriptionRoom(conversation, message)); // trách dispath quá nhiều action khi spam tin nhắn
        }
      }
    }
  } catch (err: any) {
    console.log('tin nhắn lỗi không thể hiển thị', err);
  }
};

export const getConversationChatPage = function* ({ payload }) {
  try {
    const { idPage, count, offset, label }: any = payload;

    const _response = yield call(getListConversationsChatPage, {
      roomId: idPage,
      count,
      offset,
      label: JSON.stringify(label)
    });
    if (_response.status === 200) {
      const listRoomsChatPage = _response.data.discussions
        .filter(el => el !== null)
        .map(el => {
          el.type = 'chat_page';
          el.avatar_media = {
            show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el?.u?._id}`
          };
          // https://dev-chat.emso.vn/api/v1/teams.getAvatar?teamId=108277159419223627

          // el.lastMessage = el.lastMessage;
          // el.usersCount = el.RoomsList[0]?.usersCount;
          el.userId = null;
          el.real_name = el?.u?.name;
          // el.username = el?.name;
          el.user = el.u;
          el.room = null;
          el.rid = el?._id;
          el.is_user_chat = true;
          if (el.lastMessage && el.lastMessage.unread) {
            el.unread =
              el.lastMessage.unread && el.lastMessage.u?._id !== el.u?._id
                ? 0
                : el.lastMessage?.type !== 'automatic_reply'
                ? 1
                : 0;
          }
          return el;
        });
      yield put(
        getListConversationsChatPageSuccess(listRoomsChatPage, idPage, label)
      );
      // const data = JSON.parse(_response.data.message);
      // if (data.result.messages[0] && !!data.result.messages[0]) {
      //   yield put(eventReceiveSubscriptionRoom(conversation));
      // }
    }
  } catch (err: any) {
    console.log('🚀 ~ err:', err);
  }
};
export function* sendMessageNetWordRestored({ payload }) {
  const { message } = payload;
  try {
    for (let i = 0; i < message.length; i++) {
      const el = message[i];
      yield call(sendMessageApi, el.msg, el.rid, undefined, undefined, el._id);
    }
  } catch (error) {
    console.log('🚀 ~ error:', error);
  }
}

export function* watchSocialChatAsync() {
  yield takeEvery(typesChat.GET_LIST_CONVERSATION_REQ, getListConversationSaga);
  yield takeEvery(typesChat.GET_MESSAGE_REQ, getMessageSaga);
  yield takeEvery(typesChat.NETWORK_RESTORED, sendMessageNetWordRestored);
  yield takeEvery(
    typesChat.GET_LIST_CONVERSATION_CHAT_PAGE_REQ,
    getConversationChatPage
  );
}
