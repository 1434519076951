import { CircularProgress, Divider, Tooltip, Typography } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { getReactionPeopleApi } from 'src/apis/socialPost.api';
import { followUser } from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import CarouselV2 from 'src/components/Carousel/CarouselV2';
import DialogPostShare from 'src/components/Dialog/DialogPostShare';
import DialogViewReaction from 'src/components/Dialog/DialogViewReation';
import RenderReactionImg from 'src/components/ReactEmoji/RenderReactionImg';
import { listReactCount } from 'src/constants/common';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
        alignItems: 'center',
        height: 45,
        paddingLeft: '10px'
      },
      react: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer'
        }
      },
      reactCount: {
        margin: '0 0 0 5px',
        // fontWeight: 400,
        color: '#65676b',
        fontSize: 15,
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
          textDecorationColor: '#65676b'
        }
      },
      inline: {
        display: 'inline',
        '&:hover': {
          textDecoration: 'underline'
        },
        cursor: 'pointer',
        fontSize: '15px !important'
      },
      boxUser: {
        width: '150px',
        maxHeight: '250px',
        overflow: 'hidden auto',
        opacity: '0.8',
        backgroundColor:
          theme.palette.mode === 'dark' ? '#ccc !important' : '#333 !important',
        color:
          theme.palette.mode === 'dark' ? '#333 !important' : '#fff !important'
      },
      lineClamp: {
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        overflowWrap: 'break-word'
      }
    }),
  { index: 1 }
);

interface Props {
  post?: any;
  type?: String;
  reactions?: any;
  commentTotal?: any;
  shareCount?: any;
  listComment?: any;
  handleGetComment?: any;
  setShowComment?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setListComment?: React.Dispatch<React.SetStateAction<any>>;
  reactionCount?: any;
  setReactionCount?: React.Dispatch<React.SetStateAction<number>> | any;
  meReaction?: any;
  listReaction?: any;
  buttonSelected?: any;
  setButtonSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  typeLive?: String;
  setHasMore?: React.Dispatch<React.SetStateAction<boolean>> | any;
  typePost?: String | any;
}

const PostActionInformation = React.memo((props: Props) => {
  const classes = useStyles();
  let {
    post,
    type,
    reactions,
    commentTotal,
    shareCount,
    listComment,
    handleGetComment,
    setShowComment,
    setListComment,
    reactionCount,
    setReactionCount,
    meReaction,
    listReaction,
    buttonSelected,
    setButtonSelected,
    typeLive,
    typePost,
    setHasMore
  } = props;
  const theme: any = useTheme();
  const match = useRouteMatch();
  const [openViewReaction, setOpenViewReaction] = React.useState(false);
  const [openPostShare, setOpenPostShare] = React.useState(false);
  const [listUserComment, setListUserComment] = React.useState<any>([]);

  const postWatchSelected = useSelector(
    (state: any) => state.watchReducer.postSelected
  );
  const rolePage: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer?.rolePreviewEdit
  );
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const [listPeople, setListPeople] = React.useState<any>([]);
  const history = useHistory();

  const getPeopleReaction = async () => {
    let res = await getReactionPeopleApi(post.id, null);
    if (res.status === 200) {
      const data: any = res?.data;
      if (data?.length) {
        setListPeople(data);
      }
    }
  };

  const handleMouseEnter = () => {
    getPeopleReaction();
  };

  const renderTitle: any = () => {
    const reactedViewerCountLimit: number = 20;

    return (
      <>
        {listPeople?.length && listPeople?.length <= reactedViewerCountLimit ? (
          listPeople?.map((el: any, index) => (
            <span key={index} style={{ display: 'block', fontSize: 12 }}>
              {el?.page ? el?.page?.title : el.account.display_name}
            </span>
          ))
        ) : listPeople?.length &&
          listPeople?.length > reactedViewerCountLimit ? (
          <span>
            {listPeople
              ?.slice(0, reactedViewerCountLimit)
              ?.map((el: any, index) => (
                <span key={index} style={{ display: 'block', fontSize: 12 }}>
                  {el?.page ? el?.page?.title : el.account.display_name}
                </span>
              ))}{' '}
            và {listPeople?.length - reactedViewerCountLimit} người khác
          </span>
        ) : (
          <CircularProgress style={{ height: 20, width: 20 }} color="inherit" />
        )}
      </>
    );
  };

  const listButton = [
    {
      id: 'overview',
      label: 'Tổng quan'
    },
    {
      id: 'chat',
      label: 'Chat trực tiếp'
    },
    {
      id: 'totle_comment',
      label: 'Câu trả lời'
    }
    // {
    //   id: 'video',
    //   label: 'Video tương tự'
    // }
  ];

  // const getUserInfoCalled = React.useRef(false);

  // const getUserInfo = async id => {
  //   try {
  //     console.log('44444');

  //     const res = await getInforUser(id, null);
  //     if (res.status === 200) {
  //       setUserInfo(res.data);
  //     }
  //   } catch (error) {}
  // };

  // React.useEffect(() => {
  //   if (post?.account?.id && !getUserInfoCalled.current) {
  //     console.log('checkloggg', userInfo);

  //     getUserInfoCalled.current = true;
  //     getUserInfo(post.account.id);
  //   }
  // }, [post?.account?.id]);

  const handleFollow = async id => {
    try {
      if (id) {
        const res = await followUser(id);
        if (res.status === 200) {
          // setUserInfo(prev => ({
          //   ...prev,
          //   relationships: { ...prev.relationships, following: true }
          // }));
        }
      }
    } catch (error) {}
  };

  const getReactCount = key => {
    return reactions?.find(el => el[key] || el[key] === 0)
      ? reactions?.find(el => el[key] || el[key] === 0)[key]
      : 0 ?? 0;
  };

  const handleAction = () => {
    if (typePost === 'pinned') {
      history.push(
        `/${post?.account?.username ?? post?.account?.id}/posts/${post.id}`
      );
    } else {
      if (
        match.path.includes('watch') &&
        type !== 'watch-selected' &&
        postWatchSelected.id === post.id
      ) {
        return;
      }
      if (!listComment?.length) {
        handleGetComment(
          post.id,
          {
            sort_by: 'newest'
          },
          setListComment
        );
      }
    }

    setShowComment(prev => !prev);
  };

  const handleClickReact = () => {
    // setOpenViewReaction(true);
    if (post?.post_type !== 'moment') {
      setOpenViewReaction(true);
    }
  };

  const handleSharePost = () => {
    setOpenPostShare(true);
  };
  React.useEffect(() => {
    if (post?.post_type !== 'moment') {
      let count = listReactCount?.reduce(
        (total, el) => total + getReactCount(el),
        0
      );
      setReactionCount(count);
    }
  }, [JSON.stringify(reactions)]);

  const renderActionsButton = item => {
    return (
      <ButtonInherit
        label={item.label}
        style={{
          width: '100%',
          margin: '0px',
          color:
            buttonSelected === item.id
              ? '#fff'
              : theme.palette.mode === 'dark'
              ? '#e4e6eb'
              : '#000',
          backgroundColor:
            buttonSelected === item.id
              ? buttonColor.backgroundColor
              : theme.palette.mode === 'dark'
              ? '#3a3b3c'
              : '#E4E6EB',
          borderRadius: '10px !important'
        }}
        action={() => setButtonSelected(item.id)}
      />
    );
  };

  const renderButtonOption = () => {
    return (
      <>
        {
          <ButtonInherit
            color={theme.palette.mode === 'dark' ? '#3a3b3c' : '#E4E6EB'}
            action={() => {
              handleFollow(post?.account?.id);
            }}
            label={
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                }}
                fontWeight={500}
              >
                <i
                  className="fa-solid fa-folder-medical"
                  style={{ marginRight: '10px' }}
                ></i>
                Theo dõi
              </Typography>
            }
            style={{
              width: '100%'
            }}
          />
        }

        <CarouselV2
          component={renderActionsButton}
          data={listButton}
          elOfPage={2}
          styleRoot={{
            minHeight: '60px',
            margin: '0px'
          }}
          styleButtonLeft={{
            width: '45px !important',
            height: '45px !important',
            left: '-4px !important',
            top: '12% !important'
          }}
          styleButtonRight={{
            width: '45px !important',
            height: '45px !important',
            right: '-5px !important',
            top: '12% !important'
          }}
          styleIcon={{
            fontSize: '16px'
          }}
          styleWrapTitle={{
            padding: '0px'
          }}
          styleTitle={{
            fontSize: '20px'
          }}
          itemComponentStyle={{
            maxHeight: '60px',
            minHeight: '30px',
            width: '163px'
          }}
          typeCarousel="carousel_button"
          typeButton="out_box"
        />
      </>
    );
  };
  const renderTextInfo = (meReaction: any) => {
    const pageMeReaction = meReaction.find(
      item => item?.page?.id === rolePage?.page_owner_id && rolePage?.role
    );
    const accountMeReaction = meReaction.find(
      item => item?.account.id === meInfo?.id && item?.page == null
    );
    const pageReaction = meReaction.find(
      item => item?.page?.id && !rolePage?.role
    );
    const accountReaction = meReaction.find(
      item => item?.account.id !== meInfo?.id && item?.page == null
    );
    if (
      (accountMeReaction && !pageMeReaction) ||
      (pageMeReaction && !accountMeReaction)
    ) {
      return pageReaction
        ? 'Bạn và ' + pageReaction.page.title
        : accountReaction
        ? 'Bạn và ' + accountReaction.account.display_name
        : 'Bạn';
    } else if (accountMeReaction && pageMeReaction) {
      return 'Bạn và ' + pageMeReaction.page.title;
    } else {
      if (meReaction?.length === 1) {
        return meReaction.map((item, index) => {
          return (
            <span key={index}>
              Bạn và{' '}
              {item?.page ? item?.page.title : item?.account.display_name}
            </span>
          );
        });
      } else {
        return meReaction.map((item, index) => {
          return (
            <span key={index}>
              {item?.page ? item?.page.title : item?.account.display_name}
              {index === 0 ? ' và ' : null}
            </span>
          );
        });
      }
    }
  };

  const renderReactionInfo = () => {
    if (post.post_type === 'moment') return post?.favourites_count;
    if (type === 'previewMedia') return reactionCount;

    const viewerReacted = post?.viewer_reaction;
    const myReactions = meReaction?.filter(
      item => item?.account?.id !== meInfo?.id || item?.page //trường hợp post chỉ có 1 account thả react bằng nhiều page
    );

    const firstOtherDisplayName = myReactions?.[0]?.account?.display_name;

    if (meReaction?.length && !meReaction[0].page) {
      if (viewerReacted) {
        if (reactionCount === 1)
          return (
            <div>
              {meReaction[0]?.page
                ? meReaction[0]?.page?.title
                : meInfo?.display_name}
            </div>
          );
        if (reactionCount === 2) return <div>{renderTextInfo(meReaction)}</div>;
        return (
          <div>{`Bạn, ${firstOtherDisplayName} và ${
            reactionCount - 2
          } người khác`}</div>
        );
      } else {
        if (reactionCount === 1)
          return <div>{myReactions?.map(el => el?.account?.display_name)}</div>;
        if (reactionCount === 2) return <div>{renderTextInfo(meReaction)}</div>;
        return (
          <div>{`${firstOtherDisplayName} và ${
            reactionCount - 1
          } người khác`}</div>
        );
      }
    } else if (viewerReacted) {
      return reactionCount === 1 ? (
        <div>
          {post?.favourites?.length > 0 && post?.favourites[0]?.page
            ? post?.favourites[0]?.page?.title
            : meInfo?.display_name}
        </div>
      ) : (
        <div>{`Bạn và ${reactionCount - 1} người khác`}</div>
      );
    } else {
      return reactionCount;
    }
  };

  const popupUsers = () => {
    const uniqueItems = listUserComment.filter((item, index, self) => {
      const checkItem = item?.page_owner ? 'page_owner' : 'account';
      return (
        self.findIndex(value =>
          checkItem === 'account'
            ? value[checkItem]?.id === item[checkItem]?.id && !value.page_owner
            : value[checkItem]?.id === item[checkItem]?.id
        ) === index
      );
    });

    return (
      <>
        {listUserComment?.length ? (
          uniqueItems.map((item, index) => (
            <Typography
              className={classes.lineClamp}
              sx={{ fontSize: '14px' }}
              key={index}
            >
              {item?.page_owner?.title ?? item?.account?.display_name}
            </Typography>
          ))
        ) : (
          <CircularProgress style={{ height: 20, width: 20 }} color="inherit" />
        )}
      </>
    );
  };

  return commentTotal ||
    post?.view_3s > 0 ||
    shareCount ||
    (reactionCount > 0 && post.post_type !== 'moment') ||
    (post.post_type === 'moment' && post?.reactions[3].loves_count > 0) ? (
    <>
      <div
        className={classes.root}
        style={
          {
            // zoom: match.path.includes('watch') ? `85%` : '100%'   để zoom thì tooltip sẽ hiển thị sai vị trí
            // minWidth: match.path.includes('watch') ? `460px` : undefined
          }
        }
      >
        <div
          onClick={handleClickReact}
          className={classes.react}
          style={{ maxWidth: '250px' }}
        >
          {((reactionCount > 0 && post.post_type !== 'moment') ||
            (post.post_type === 'moment' &&
              post?.reactions[3].loves_count > 0)) && (
            <>
              {post.post_type === 'moment' ? (
                <i
                  style={{ color: 'rgb(254, 44, 85)' }}
                  className="fa-solid fa-heart fa-beat fa-lg"
                ></i>
              ) : (
                <span>
                  <RenderReactionImg post={post} listReaction={listReaction} />
                </span>
              )}
              <Tooltip title={renderTitle()} disableInteractive>
                <div
                  className={classes.reactCount}
                  onMouseEnter={() => handleMouseEnter()}
                  onMouseLeave={() => setListPeople([])}
                >
                  {renderReactionInfo()}
                  {/* {post.post_type === 'moment' ? (
                    post?.reactions[3].loves_count
                  ) : type === 'previewMedia' ? (
                    reactionCount
                  ) : meReaction?.length ? (
                    post?.viewer_reaction ? (
                      reactionCount === 1 ? (
                        <div>
                          {meReaction[0]?.page
                            ? meReaction[0]?.page?.title
                            : meInfo?.display_name}
                        </div>
                      ) : reactionCount === 2 ? (
                        <div>{renderTextInfo(meReaction)}</div>
                      ) : reactionCount === 3 ? (
                        <div>
                          {'Bạn, ' +
                            meReaction
                              ?.filter(item => item?.account?.id !== meInfo?.id)
                              .slice(0, 1)
                              .map(el => el?.account?.display_name) +
                            ' và 1 người khác'}
                        </div>
                      ) : (
                        <div>
                          {'Bạn, ' +
                            meReaction
                              ?.filter(item => item?.account?.id !== meInfo?.id)
                              .slice(0, 1)
                              .map(el => el?.account?.display_name) +
                            ' và ' +
                            (reactionCount - 2) +
                            ' người khác'}
                        </div>
                      )
                    ) : reactionCount === 1 ? (
                      <div>
                        {meReaction
                          ?.filter(item => item?.account?.id !== meInfo?.id)
                          .map(el => el?.account?.display_name)}
                      </div>
                    ) : reactionCount === 2 ? (
                      <div>{renderTextInfo(meReaction)}</div>
                    ) : reactionCount === 3 ? (
                      <div>
                        {meReaction
                          .filter(item => item?.account?.id !== meInfo?.id)
                          .slice(0, 1)
                          .map(el => el?.account?.display_name)}{' '}
                        và 2 người khác
                      </div>
                    ) : (
                      <div>
                        {meReaction
                          .filter(item => item?.account?.id !== meInfo?.id)
                          .slice(0, 1)
                          .map(el => el?.account?.display_name)
                          .join(', ')}{' '}
                        và {reactionCount - 1} người khác
                      </div>
                    )
                  ) : post?.viewer_reaction ? (
                    reactionCount === 1 ? (
                      <div>
                        {rolePage?.role === 'admin'
                          ? rolePage?.title
                          : meInfo?.display_name}
                      </div>
                    ) : (
                      <div>
                        {'Bạn và ' + (reactionCount - 1) + ' người khác'}
                      </div>
                    )
                  ) : (
                    reactionCount
                  )} */}
                </div>
              </Tooltip>
            </>
          )}
        </div>
        &nbsp; &nbsp;
        <div
          style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}
        >
          {commentTotal ? (
            <Tooltip title={popupUsers()} disableInteractive>
              <Typography
                noWrap
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
                onClick={e => {
                  handleAction();
                }}
                onMouseEnter={() => {
                  if (listComment?.length) {
                    setListUserComment(listComment);
                  } else {
                    handleGetComment(
                      post.id,
                      {
                        sort_by: 'newest'
                      },
                      setListUserComment,
                      setHasMore
                    );
                  }
                }}
                onMouseLeave={() => setListUserComment([])}
              >
                {commentTotal} bình luận
              </Typography>
            </Tooltip>
          ) : null}
          {match.path === PATHS.WATCH ? (
            <>
              {shareCount ? (
                <>
                  &nbsp; &nbsp;
                  <Typography
                    noWrap
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                    onClick={handleSharePost}
                  >
                    {shareCount} lượt chia sẻ
                  </Typography>
                </>
              ) : null}
              {post.view_3s > 0 ? (
                <>
                  &nbsp; &nbsp;
                  <Typography
                    noWrap
                    component="span"
                    variant="body2"
                    fontSize={'15px !important'}
                    color="textPrimary"
                  >
                    {post.view_3s > 1000
                      ? (post.view_3s / 1000).toFixed(1)
                      : post.view_3s}{' '}
                    lượt xem
                  </Typography>
                </>
              ) : null}
            </>
          ) : shareCount ? (
            <>
              &nbsp; &nbsp;
              <Typography
                noWrap
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
                onClick={handleSharePost}
              >
                {shareCount} lượt chia sẻ
              </Typography>
            </>
          ) : null}
        </div>
      </div>
      {openViewReaction ? (
        <DialogViewReaction
          post={post}
          open={openViewReaction}
          setOpen={setOpenViewReaction}
        />
      ) : null}

      {openPostShare ? (
        <DialogPostShare
          post={post}
          open={openPostShare}
          setOpen={setOpenPostShare}
        />
      ) : null}

      {typeLive ? renderButtonOption() : null}
      <Divider />
    </>
  ) : (
    <div>{typeLive ? renderButtonOption() : null}</div>
  );
});

export default PostActionInformation;
