/* eslint-disable react-hooks/exhaustive-deps */ import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { HighlightOff } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ResponseApi } from 'src/@types/api';
import { getCourseCategories } from 'src/apis/socialCourse.api';
import { searchFilterPostInGroup } from 'src/apis/socialGroup';
import { getRecruitCategories } from 'src/apis/socialRecruit.api';
import { searchPostUser } from 'src/apis/socialUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import ButtonOptions from 'src/components/Button/ButtonOptions';
import ListButton from 'src/components/List/ListButton';
import { productCategories } from 'src/constants/common';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import {
  searchFilterPostGroup,
  searchFilterPostPage,
  searchFilterPostUserByCreateAt
} from 'src/store/action/socialSearchGlobalAction';
import { searchPageApi } from 'src/apis/socialPages.api';
import { getListProvincesApi } from 'src/apis/socialLocation';
import InputSearch from '../../components/Input/SocialInput/InputSearch';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        height: '100%',
        width: 310,
        position: 'fixed',
        padding: '5px 0px 40px 8px',
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)',
        backgroundColor: theme.palette.background.primary
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 10px',
        backgroundColor: theme.palette.background.primary
      },
      footerPage: {
        position: 'absolute',
        bottom: '55px',
        left: 0,
        width: '100%',
        zIndex: 1200
      },
      rootPage: {
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        backgroundColor: theme.palette.background.primary,
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          // background: '#f1f1f1',
          backgroundColor: theme.palette.background.primary
        },
        '&::-webkit-scrollbar-thumb': {
          // background: '#bdbdbd',
          borderRadius: 10,
          backgroundColor: theme.palette.background.primary
        }
      },
      inputSearch: {
        width: '100%'
        // marginTop: '10px !important'
      },
      cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
          borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        '& fieldset': {
          display: 'none'
        },
        '&:hover': {
          backgroundColor:
            theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
        },
        backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
        borderRadius: '30px !important',
        height: 35,
        padding: '7px !important',
        margin: 'auto'
      },
      notchedOutline: {
        border: `1px solid ${theme.palette.myBackgroundColor?.main} !important`
      },
      multilineColor: {
        fontSize: 13
      },
      avatar: {
        width: '56px !important',
        height: '56px !important'
      },
      list: {
        width: '100%'
      },
      selected: {
        backgroundColor: '#ECF3FF !important'
      },
      wrapBody: {
        marginBottom: '200px',
        marginTop: '8px'
      },
      divFlexJustify: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '36px'
      },
      divFlexEnd: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'center'
      }
    }),
  { index: 1 }
);
interface paramSearch {
  q: any;
  created_year: number;
}
interface paramSearchPostGroup extends paramSearch {
  created_by: string;
}
const SearchGlobalLeft = props => {
  const { type, styleGui } = props;
  const theme: any = useTheme();

  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const [selectedIndex, setSelectedIndex] = React.useState<any>('all');
  const [filterSearchGroupCreatedBy, setFilterSearchGroupCreatedBy] =
    React.useState<any>({ label: 'Người đăng', id: null });
  const [filterSearchGroupCreatedAt, setFilterSearchGroupCreatedAt] =
    React.useState<any>({ label: 'Ngày đăng', id: null });
  const [listRecruitCategory, setListRecruitCategory] = React.useState<any>([]);
  const [listCourseCategory, setListCourseCategory] = React.useState<any>([]);
  const [listProductCategory, setListProductCategory] = React.useState<any>(
    productCategories.map((el: any, index) => ({
      id: index,
      label: el?.text,
      idParent: 'productCategories'
    }))
  );
  const [searchRecruitByExp, setSearchRecruitByExp] = React.useState<any>({
    label: 'Kinh nghiệm làm việc',
    id: null
  });
  const [searchRecruitByWorkingForm, setSearchRecruitByWorkingForm] =
    React.useState<any>({
      label: 'Hình thức làm việc',
      id: null
    });
  const [searchRecruitByJobs, setSearchRecruitByJobs] = React.useState<any>({
    label: 'Ngành nghề',
    id: null
  });
  const [searchRecruitBySalary, setSearchRecruitBySalary] = React.useState<any>(
    {
      label: 'Mức lương',
      id: null,
      salary_min: null,
      salary_max: null
    }
  );
  const [searchRecruitByProvince, setSearchRecruitByProvince] =
    React.useState<any>({
      province_name: 'Địa điểm',
      id: null,
      center_point: {},
      province_id: null
    });
  const [searchCourseBySubjects, setSearchCourseBySubjects] =
    React.useState<any>({
      label: 'Danh mục',
      id: null
    });
  const [searchProduct, setSearchProduct] = React.useState<any>({
    label: 'Danh mục',
    id: null
  });
  const dispatch = useDispatch();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const userSelected = useSelector((state: any) => state?.userReducer?.infor);

  const groupSelected =
    useSelector((state: any) => state.groupReducer.groupSelected) || {};
  const pageSelected =
    useSelector((state: any) => state.pageReducer.info) || {};
  let query = useQuery();
  let keyword: any = query.get('q');
  let mediaId = query.get('mediaId');
  let { userId }: any = match.params;
  const [keyWordSearch, setKeywordSearch] = React.useState<string>(keyword);
  const [provinceOptions, setProvinceOptions] = React.useState<any>([]);
  const yearCurrent = new Date().getFullYear();

  const getProvinces = async () => {
    let response = await getListProvincesApi();
    if (response.status === 200) {
      setProvinceOptions(response.data);
    }
  };
  React.useEffect(() => {
    getProvinces();
  }, []);

  const listFilterSearchGlobal = [
    {
      id: 'all',
      title: 'Tất cả',
      icon: <i style={{ fontSize: 18 }} className="fa-solid fa-id-card"></i>,
      action: () => {
        history.push(`/search/all?q=${keyword ? keyword : ''}`);
      }
    },
    {
      id: 'accounts',
      title: 'Mọi người',
      icon: <i style={{ fontSize: 18 }} className="fa-solid fa-user-group"></i>,
      action: () => {
        history.push(`/search/accounts?q=${keyword}`);
      }
    },
    {
      id: 'statuses',
      title: 'Bài viết',
      icon: (
        <i
          style={{ fontSize: 18 }}
          className="fa-solid fa-comment-captions"
        ></i>
      ),
      action: () => {
        history.push(`/search/statuses?q=${keyword}`);
      }
    },
    {
      id: 'groups',
      title: 'Nhóm',
      icon: <i style={{ fontSize: 18 }} className="fa-solid fa-users"></i>,
      action: () => {
        history.push(`/search/groups?q=${keyword}`);
      }
    },
    {
      id: 'pages',
      title: 'Trang',
      icon: (
        <i
          style={{ fontSize: 18 }}
          className="fa-solid fa-flag-swallowtail"
        ></i>
      ),
      action: () => {
        history.push(`/search/pages?q=${keyword}`);
      }
    },
    {
      id: 'events',
      title: 'Sự kiện',
      icon: (
        <i style={{ fontSize: 18 }} className="fa-regular fa-calendar-star"></i>
      ),
      action: () => {
        history.push(`/search/events?q=${keyword}`);
      }
    },
    {
      id: 'grows',
      title: 'Gọi vốn',
      icon: (
        <i
          style={{ fontSize: 18 }}
          className="fa-sharp fa-solid fa-arrow-up-right-dots"
        ></i>
      ),
      action: () => {
        history.push(`/search/grows?q=${keyword}`);
      }
    },
    {
      id: 'recruits',
      title: 'Tuyển dụng',
      icon: (
        <i
          style={{ fontSize: 18 }}
          className="fa-solid fa-users-viewfinder"
        ></i>
      ),
      action: () => {
        history.push(`/search/recruits?q=${keyword}`);
      }
    },
    {
      id: 'courses',
      title: 'Khóa học',
      icon: (
        <i style={{ fontSize: 18 }} className="fa-solid fa-graduation-cap"></i>
      ),
      action: () => {
        history.push(`/search/courses?q=${keyword}`);
      }
    },
    {
      id: 'products',
      title: 'Sản phẩm',
      icon: <i style={{ fontSize: 18 }} className="fa-solid fa-box-open"></i>,
      action: () => {
        history.push(`/search/products?q=${keyword}`);
      }
    }
  ];
  const handleResetFilterSearchRecruit = () => {
    setSearchRecruitByJobs({ label: 'Ngành nghề', id: null });
    setSearchRecruitByWorkingForm({ label: 'Hình thức làm việc', id: null });
    setSearchRecruitByExp({ label: 'Kinh nghiệm làm việc', id: null });
    setSearchRecruitBySalary({
      label: 'Mức lương',
      id: null,
      salary_min: null,
      salary_max: null
    });
    setSearchRecruitByProvince({
      province_name: 'Địa điểm',
      id: null,
      center_point: {},
      province_id: null
    });

    history.push({
      pathname: '',
      search: `?q=${keyword}`
    });
  };
  const fetchDataRecruitCategory = async () => {
    let res: ResponseApi = await getRecruitCategories();
    if (res.status === 200) {
      let tempData: any = res.data.map((el: any, index) => ({
        id: el?.id,
        label: el?.text,
        idParent: 'c'
      }));
      setListRecruitCategory(tempData);
    }
  };

  const handleResetFilterSearchCourse = () => {
    setSearchCourseBySubjects({ label: 'Danh mục', id: null });
    history.push({
      pathname: '',
      search: `?q=${keyword}`
    });
  };

  const fetchDataCourseCategory = async () => {
    let res: ResponseApi = await getCourseCategories();
    if (res.status === 200) {
      let tempData: any = res.data.map((el: any, index) => ({
        id: el?.id,
        label: el?.text,
        idParent: 'courseCategories'
      }));
      setListCourseCategory(tempData);
    }
  };
  const listFilterSearchRecruit = [
    {
      id: 'a',
      label: searchRecruitByWorkingForm.label,
      type: 'select',
      options: [
        {
          id: 'fulltime',
          label: 'Toàn thời gian',
          idParent: 'a'
        },
        {
          id: 'parttime',
          label: 'Bán thời gian',
          idParent: 'a'
        },
        {
          id: 'internship',
          label: 'Thực tập',
          idParent: 'a'
        },
        {
          id: 'remote',
          label: 'Làm việc từ xa',
          idParent: 'a'
        }
      ]
    },
    {
      id: 'b',
      label: searchRecruitByExp.label,
      type: 'select',
      options: [
        {
          id: 'less_than_one',
          label: 'Dưới 1 năm kinh nghiệm',
          idParent: 'b'
        },
        {
          id: 'one',
          label: '1 năm kinh nghiệm',
          idParent: 'b'
        },
        {
          id: 'two',
          label: '2 năm kinh nghiệm',
          idParent: 'b'
        },
        {
          id: 'three',
          label: '3 năm kinh nghiệm',
          idParent: 'b'
        },
        {
          id: 'four',
          label: '4 năm kinh nghiệm',
          idParent: 'b'
        },
        {
          id: 'five',
          label: '5 năm kinh nghiệm',
          idParent: 'b'
        },
        {
          id: 'more_than_five',
          label: 'Trên 5 năm kinh nghiệm',
          idParent: 'b'
        }
      ]
    },
    {
      id: 'c',
      label: searchRecruitByJobs.label,
      type: 'select',
      options: listRecruitCategory
    },
    {
      id: 'd',
      label: searchRecruitBySalary.label,
      type: 'select',
      options: [
        {
          id: 'all',
          label: 'Tất cả',
          idParent: 'd'
        },
        {
          id: '1',
          label: 'Dưới 10 triệu',
          salary_min: 0,
          salary_max: 10000000,
          idParent: 'd'
        },
        {
          id: '2',
          label: 'Từ 10 - 15 triệu',
          salary_min: 10000000,
          salary_max: 15000000,
          idParent: 'd'
        },
        {
          id: '3',
          label: 'Từ 15 - 20 triệu',
          salary_min: 15000000,
          salary_max: 20000000,
          idParent: 'd'
        },
        {
          id: '4',
          label: 'Từ 20 - 25 triệu',
          salary_min: 20000000,
          salary_max: 25000000,
          idParent: 'd'
        },
        {
          id: '5',
          label: 'Từ 25 - 30 triệu',
          salary_min: 25000000,
          salary_max: 30000000,
          idParent: 'd'
        },
        {
          id: '6',
          label: 'Trên 30 triệu',
          salary_min: 30000000,
          idParent: 'd'
        }
      ]
    },
    {
      id: 'e',
      label: searchRecruitByProvince.province_name,
      type: 'select',
      options: provinceOptions
    }
  ];

  const listFilterSearchCourse = [
    {
      id: 'courseCategories',
      label: searchCourseBySubjects.label,
      type: 'select',
      options: listCourseCategory
    }
  ];

  const listFilterSearchProduct = [
    {
      id: 'productCategories',
      label: searchProduct.label,
      type: 'select',
      options: listProductCategory
    }
  ];
  React.useEffect(() => {
    if (keyword) {
      setFilterSearchGroupCreatedAt({ label: 'Ngày đăng', id: null });
    }
  }, [keyword]);
  const handleSearchPostUser = async (createYear: any) => {
    let params: paramSearch = {
      created_year: createYear,
      q: keyword
    };
    let res: ResponseApi = await searchPostUser(userId, params);
    if (res.status === 200) {
      dispatch(searchFilterPostUserByCreateAt(res.data.statuses));
      setFilterSearchGroupCreatedAt({
        label: createYear.toString(),
        id: createYear.toString()
      });
    }
  };
  const handleSearchPostGroup = async (createYear: any, createdBy: any) => {
    let params: paramSearchPostGroup = {
      created_year: createYear,
      q: keyword,
      created_by: createdBy
    };
    let res = await searchFilterPostInGroup(groupSelected?.id, params);
    if (res.status === 200) {
      dispatch(searchFilterPostGroup(res.data));
      setFilterSearchGroupCreatedAt({
        label: createYear.toString(),
        id: createYear
      });
    }
  };
  const handleSearchPostPage = async (createYear: any, createdBy: any) => {
    let params: paramSearchPostGroup = {
      created_year: createYear,
      q: keyword,
      created_by: createdBy
    };
    let res = await searchPageApi(pageSelected?.id, params);
    if (res.status === 200) {
      dispatch(searchFilterPostPage(res.data));
      setFilterSearchGroupCreatedAt({
        label: createYear.toString(),
        id: createYear
      });
    }
  };
  const handleSearchFilterCreatedBy = async (
    createYear: any,
    createdBy: any
  ) => {
    let params: paramSearchPostGroup = {
      created_year: createYear,
      q: keyword,
      created_by: createdBy
    };
    let res = await searchFilterPostInGroup(groupSelected?.id, params);
    if (res.status === 200) {
      dispatch(searchFilterPostGroup(res.data));
      setFilterSearchGroupCreatedBy({
        label: createdBy === 'friends' ? 'Bạn bè của bạn' : 'Bạn',
        id: createdBy
      });
    }
  };
  const listFilterSearchGroup = [
    {
      id: 'created_by',
      label: filterSearchGroupCreatedBy.label,
      type: 'select',
      options: [
        {
          id: 'me',
          label: 'Bạn',
          action: () => {
            handleSearchFilterCreatedBy(filterSearchGroupCreatedAt.id, 'me');
          }
        },
        {
          id: 'friends',
          label: 'Bạn bè của bạn',
          action: () => {
            handleSearchFilterCreatedBy(
              filterSearchGroupCreatedAt.id,
              'friends'
            );
          }
        }
      ]
    },
    {
      id: 'created_at',
      label: filterSearchGroupCreatedAt.label,
      type: 'select',
      sub_type: 'time',
      options: [
        {
          id: yearCurrent.toString(),
          label: yearCurrent.toString(),
          action: () => {
            match.path === PATHS.USER && handleSearchPostUser(yearCurrent);
            match.path === PATHS.GROUP_DETAIL &&
              handleSearchPostGroup(yearCurrent, filterSearchGroupCreatedBy.id);
            match.path === PATHS.PAGE_DETAIL &&
              handleSearchPostPage(yearCurrent, filterSearchGroupCreatedBy.id);
          }
        },
        {
          id: (yearCurrent - 1).toString(),
          label: (yearCurrent - 1).toString(),
          action: () => {
            match.path === PATHS.USER && handleSearchPostUser(yearCurrent - 1);
            match.path === PATHS.GROUP_DETAIL &&
              handleSearchPostGroup(
                yearCurrent - 1,
                filterSearchGroupCreatedBy.id
              );
            match.path === PATHS.PAGE_DETAIL &&
              handleSearchPostPage(
                yearCurrent - 1,
                filterSearchGroupCreatedBy.id
              );
          }
        },
        {
          id: (yearCurrent - 2).toString(),
          label: (yearCurrent - 2).toString(),
          action: () => {
            match.path === PATHS.USER && handleSearchPostUser(yearCurrent - 2);
            match.path === PATHS.GROUP_DETAIL &&
              handleSearchPostGroup(
                yearCurrent - 2,
                filterSearchGroupCreatedBy.id
              );
            match.path === PATHS.PAGE_DETAIL &&
              handleSearchPostPage(
                yearCurrent - 2,
                filterSearchGroupCreatedBy.id
              );
          }
        }
      ]
    }
  ];

  React.useEffect(() => {
    let { key } = match?.params;
    setSelectedIndex(key);
  }, [match.path, JSON.stringify(match.params)]);
  React.useEffect(() => {
    switch (type) {
      case 'recruit':
        fetchDataRecruitCategory();
        break;
      case 'course':
        fetchDataCourseCategory();
        break;
    }
  }, [type]);

  React.useEffect(() => {
    history.push(
      `?q=${keyword ? keyword : mediaId ?? ''}${
        searchRecruitByExp?.id
          ? `&work_experience=${searchRecruitByExp?.id}`
          : ''
      }${
        searchRecruitByWorkingForm?.id
          ? `&working_form=${searchRecruitByWorkingForm?.id}`
          : ''
      }${
        searchRecruitByJobs?.id
          ? `&recruit_category_id=${searchRecruitByJobs?.id}`
          : ''
      }${
        searchCourseBySubjects?.id
          ? `&course_category_id=${searchCourseBySubjects?.id}`
          : ''
      }${searchProduct?.id ? `&category=${searchProduct?.id}` : ''}${
        searchRecruitBySalary?.id
          ? `&salary_min=${searchRecruitBySalary.salary_min}&salary_max=${searchRecruitBySalary.salary_max}`
          : ''
      }${
        searchRecruitByProvince?.id
          ? `&province_id=${searchRecruitByProvince?.id}`
          : ''
      }
      `
    );
  }, [
    JSON.stringify(searchRecruitByWorkingForm),
    JSON.stringify(searchRecruitByExp),
    JSON.stringify(searchRecruitByJobs),
    JSON.stringify(searchCourseBySubjects),
    JSON.stringify(searchProduct),
    JSON.stringify(searchRecruitBySalary),
    JSON.stringify(searchRecruitByProvince)
  ]);
  React.useEffect(() => {
    if (filterSearchGroupCreatedBy.id && filterSearchGroupCreatedAt.id) {
      history.push({
        pathname: '',
        search: `?q=${keyword}&createdBy=${filterSearchGroupCreatedBy.id}&createdAt=${filterSearchGroupCreatedAt.id}`
      });
    } else if (
      filterSearchGroupCreatedBy.id &&
      !filterSearchGroupCreatedAt.id
    ) {
      history.push({
        pathname: '',
        search: `?q=${keyword}&createdBy=${filterSearchGroupCreatedBy.id}`
      });
    } else if (
      !filterSearchGroupCreatedBy.id &&
      filterSearchGroupCreatedAt.id
    ) {
      history.push({
        pathname: '',
        search: `?q=${keyword}&createdAt=${filterSearchGroupCreatedAt.id}`
      });
    }
  }, [
    JSON.stringify(filterSearchGroupCreatedBy),
    JSON.stringify(filterSearchGroupCreatedAt)
  ]);

  const renderHeader = () => {
    return (
      <Grid container spacing={2}>
        <Grid container item justifyContent="space-between" alignItems="center">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '96%',
              backgroundColor: 'background.primary'
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: 24 }}>
              Kết quả tìm kiếm
            </Typography>
            {(type === 'group' ||
              type === 'page' ||
              type === 'user' ||
              type === 'recruit' ||
              type === 'course' ||
              type === 'event' ||
              type === 'grow' ||
              type === 'product') && (
              <>
                <Typography sx={{ fontWeight: 300, fontSize: 15 }}>
                  trong{' '}
                  {type === 'group'
                    ? 'nhóm'
                    : type === 'page'
                    ? 'trang'
                    : type === 'recruit'
                    ? 'tuyển dụng'
                    : type === 'course'
                    ? 'khóa học'
                    : type === 'event'
                    ? 'sự kiện'
                    : type === 'grow'
                    ? 'dự án'
                    : type === 'product'
                    ? 'Marketplace'
                    : ''}{' '}
                  <b>
                    {type === 'group'
                      ? groupSelected.title
                      : type === 'page'
                      ? pageSelected.title
                      : type === 'recruit' ||
                        type === 'course' ||
                        type === 'event' ||
                        type === 'grow' ||
                        type === 'product'
                      ? ''
                      : userSelected.display_name}
                  </b>
                </Typography>
                <Box sx={{ margin: '8px 14px 8px 3px', width: '100%' }}>
                  <InputSearch
                    keyword={keyWordSearch}
                    setKeyword={setKeywordSearch}
                    label={
                      type === 'group'
                        ? 'Tìm kiếm trong nhóm này'
                        : type === 'page'
                        ? 'Tìm kiếm trong trang này'
                        : type === 'recruit'
                        ? 'Tìm kiếm tuyển dụng'
                        : type === 'course'
                        ? 'Tìm kiếm khóa học'
                        : type === 'event'
                        ? 'Tìm kiếm sự kiện'
                        : type === 'grow'
                        ? 'Tìm kiếm dự án'
                        : type === 'product'
                        ? 'Tìm kiếm sản phẩm'
                        : 'Tìm kiếm trong trang cá nhân'
                    }
                    action={() => {
                      if (type === 'group') {
                        history.push(
                          `/group/${groupSelected.id}/search?q=${keyWordSearch}`
                        );
                      } else if (type === 'page') {
                        history.push(
                          `/page/${pageSelected.id}/search?q=${keyWordSearch}`
                        );
                      } else if (type === 'recruit') {
                        history.push(`/recruit/search?q=${keyWordSearch}`);
                      } else if (type === 'course') {
                        history.push(`/course/search?q=${keyWordSearch}`);
                      } else if (type === 'event') {
                        history.push(`/event/search?q=${keyWordSearch}`);
                      } else if (type === 'grow') {
                        history.push(`/grow/search?q=${keyWordSearch}`);
                      } else if (type === 'product') {
                        history.push(`/product/search?q=${keyWordSearch}`);
                      } else {
                        history.push(
                          `/user/${userSelected?.id}/search?q=${keyWordSearch}`
                        );
                      }
                    }}
                  />
                </Box>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    );
  };

  const handleResetOption = (id: any) => {
    if (id === 'a') {
      setSearchRecruitByWorkingForm({ label: 'Hình thức làm việc', id: null });
    } else if (id === 'b') {
      setSearchRecruitByExp({ label: 'Kinh nghiệm làm việc', id: null });
    } else if (id === 'c') {
      setSearchRecruitByJobs({ label: 'Ngành nghề', id: null });
    } else if (id === 'd') {
      setSearchRecruitBySalary({
        label: 'Mức lương',
        id: null,
        salary_min: null,
        salary_max: null
      });
    } else if (id === 'e') {
      setSearchRecruitByProvince({
        province_name: 'Địa điểm',
        id: null,
        center_point: {},
        province_id: null
      });
    }
  };

  const renderIcon = (id: any) => {
    const mapOption = {
      a: searchRecruitByWorkingForm.id,
      b: searchRecruitByExp.id,
      c: searchRecruitByJobs.id,
      d: searchRecruitBySalary.id,
      e: searchRecruitByProvince.id
    };

    if (mapOption[id]) {
      return (
        <HighlightOff
          sx={{ marginRight: '4px', fontSize: '20px' }}
          onClick={e => {
            e.stopPropagation();
            handleResetOption(id);
          }}
        />
      );
    }

    return <ArrowDropDownIcon style={{ fontSize: '25px' }} />;
  };
  const renderBody = () => {
    switch (match.path) {
      case PATHS.SEARCH_GLOBAL:
        return {
          bodyTop: null,
          bodyCenter: (
            <div style={{ marginBottom: '200px', marginTop: '8px' }}>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: '#252525',
                  margin: '0px 5px'
                }}
              >
                Bộ lọc
              </Typography>
              <ListButton
                item={listFilterSearchGlobal}
                selectedItem={selectedIndex}
                width={'290px'}
                colorIcon={true}
                typeAction={'action'}
              />
            </div>
          ),
          bodyFooter: <div style={{ marginBottom: '200px' }}></div>
        };
      case PATHS.USER:
      case PATHS.PAGE_DETAIL: {
        return {
          bodyTop: null,
          bodyCenter: (
            <div
              style={{
                marginBottom: '200px',
                marginTop: '8px'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: 'text.primary',
                    margin: '0px'
                  }}
                >
                  Bộ lọc
                </Typography>
                {filterSearchGroupCreatedAt.id && (
                  <ButtonInherit
                    action={handleResetFilterSearchGroup}
                    label={
                      <Typography
                        fontSize="13px"
                        fontWeight="400"
                        color={buttonColor.backgroundColor}
                      >
                        Đặt lại
                      </Typography>
                    }
                    style={{
                      width: '30px',
                      height: '25px',
                      backgroundColor: 'background.primary',
                      '&:hover': {
                        backgroundColor: 'button.primary.background'
                      }
                    }}
                  />
                )}
              </div>
              {listFilterSearchGroup.map(
                el =>
                  el?.sub_type === 'time' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <ButtonOptions
                        options={el.options}
                        endIcon={
                          <ArrowDropDownIcon style={{ fontSize: '25px' }} />
                        }
                        width="295px"
                        color="white"
                        label={el.label}
                        style={{
                          backgroundColor: 'button.primary.background',
                          '&:hover': {
                            backgroundColor:
                              theme.palette.mode === 'dark' ? '#3a3b3c' : '#fff'
                          },
                          height: '35px',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      />
                    </div>
                  )
              )}
            </div>
          )
        };
      }
      case PATHS.GROUP_DETAIL: {
        return {
          bodyTop: null,
          bodyCenter: (
            <div
              style={{
                marginBottom: '200px',
                marginTop: '8px'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: '#252525',
                    margin: '0px 5px'
                  }}
                >
                  Bộ lọc
                </Typography>
                {(filterSearchGroupCreatedBy.id ||
                  filterSearchGroupCreatedAt.id) && (
                  <ButtonInherit
                    action={handleResetFilterSearchGroup}
                    label={
                      <Typography
                        fontSize="13px"
                        fontWeight="400"
                        color={buttonColor.backgroundColor}
                      >
                        Đặt lại
                      </Typography>
                    }
                    style={{
                      width: '30px',
                      height: '25px',
                      backgroundColor: 'white !important',
                      '&:hover': {
                        backgroundColor: '#f1f2f5 !important'
                      }
                    }}
                  />
                )}
              </div>
              {listFilterSearchGroup.map(el => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <ButtonOptions
                    options={el.options}
                    endIcon={<ArrowDropDownIcon style={{ fontSize: '25px' }} />}
                    width="290px"
                    color="white"
                    label={el.label}
                    style={{
                      '&:hover': {
                        backgroundColor: 'button.secondary.background'
                      },
                      height: '35px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '8px',
                      backgroundColor: 'button.primary.background',
                      marginTop: '10px'
                    }}
                  />
                </div>
              ))}
            </div>
          )
        };
      }
      case PATHS.RECRUIT_DETAIL: {
        return {
          bodyTop: null,
          bodyCenter: (
            <div
              style={{
                marginBottom: '200px',
                marginTop: '8px'
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,

                    margin: '0px 5px'
                  }}
                >
                  Bộ lọc
                </Typography>
                {(searchRecruitByJobs.id ||
                  searchRecruitByWorkingForm.id ||
                  searchRecruitByExp.id ||
                  searchRecruitByProvince.id ||
                  searchRecruitBySalary.id) && (
                  <ButtonInherit
                    action={handleResetFilterSearchRecruit}
                    style={{
                      marginRight: '9px'
                    }}
                    label={
                      <Typography fontSize="13px" fontWeight="400">
                        Đặt lại
                      </Typography>
                    }
                  />
                )}
              </div>
              {listFilterSearchRecruit.map(el => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <ButtonOptions
                    type="recruit"
                    options={el.options}
                    handleAction={item => {
                      if (item?.idParent === 'a') {
                        setSearchRecruitByWorkingForm(item);
                      } else if (item?.idParent === 'b') {
                        setSearchRecruitByExp(item);
                      } else if (item?.idParent === 'c') {
                        setSearchRecruitByJobs(item);
                      } else if (item?.idParent === 'd') {
                        setSearchRecruitBySalary(item);
                      } else {
                        setSearchRecruitByProvince(item);
                      }
                    }}
                    endIcon={renderIcon(el.id)}
                    width="290px"
                    label={el.label}
                    style={{
                      '&:hover': {
                        backgroundColor: buttonColor.backgroundColor,
                        color: 'white'
                      },
                      backgroundColor:
                        theme.palette.mode === 'dark' ? '#3a3b3c' : '#fff',
                      height: '35px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '8px',
                      marginTop: '10px'
                    }}
                  />
                </div>
              ))}
            </div>
          )
        };
      }
      case PATHS.COURSE_DETAIL: {
        return {
          bodyTop: null,
          bodyCenter: (
            <div className={classes.wrapBody}>
              <div className={classes.divFlexJustify}>
                <Typography
                  fontWeight="600"
                  fontSize="16px"
                  align="center"
                  sx={{
                    margin: '0px 5px'
                  }}
                >
                  Bộ lọc
                </Typography>
                {searchCourseBySubjects.id && (
                  <ButtonInherit
                    action={handleResetFilterSearchCourse}
                    style={{
                      marginRight: '14px'
                    }}
                    label={
                      <Typography fontSize="13px" fontWeight="400">
                        Đặt lại
                      </Typography>
                    }
                  />
                )}
              </div>
              {listFilterSearchCourse.map(el => (
                <div className={classes.divFlexEnd}>
                  <ButtonOptions
                    options={el.options}
                    handleAction={item => {
                      if (item?.idParent === 'courseCategories') {
                        setSearchCourseBySubjects(item);
                      }
                    }}
                    endIcon={<ArrowDropDownIcon style={{ fontSize: '25px' }} />}
                    width="290px"
                    color="white"
                    label={el?.label}
                    style={{
                      marginLeft: '0',
                      marginRight: '7px',
                      padding: '6px 16px',
                      '&:hover': {
                        backgroundColor: buttonColor.backgroundColor,
                        color: 'white'
                      },
                      backgroundColor:
                        theme.palette.mode === 'dark' ? '#3a3b3c' : '#fff',

                      height: '35px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '8px'
                    }}
                  />
                </div>
              ))}
            </div>
          )
        };
      }
      case PATHS.PRODUCT_DETAIL: {
        return {
          bodyTop: null,
          bodyCenter: (
            <div className={classes.wrapBody}>
              <div className={classes.divFlexJustify}>
                <Typography
                  fontWeight="600"
                  fontSize="16px"
                  sx={{
                    margin: '0px 5px'
                  }}
                >
                  Bộ lọc
                </Typography>
                {searchProduct.id && (
                  <ButtonInherit
                    action={handleResetFilterSearchCourse}
                    label={
                      <Typography fontSize="13px" fontWeight="400">
                        Đặt lại
                      </Typography>
                    }
                  />
                )}
              </div>
              {listFilterSearchProduct.map(el => (
                <div className={classes.divFlexEnd}>
                  <ButtonOptions
                    options={el.options}
                    handleAction={item => {
                      if (item?.idParent === 'productCategories') {
                        setSearchProduct(item);
                      }
                    }}
                    endIcon={<ArrowDropDownIcon style={{ fontSize: '25px' }} />}
                    width="290px"
                    color="white"
                    label={el.label}
                    style={{
                      '&:hover': {
                        backgroundColor: 'button.default.hover  !important'
                      },
                      height: '35px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '8px',
                      backgroundColor: theme.palette.background.secondary
                    }}
                  />
                </div>
              ))}
            </div>
          )
        };
      }
      default:
        return {
          bodyTop: null,
          bodyCenter: null,
          bodyFooter: null
        };
    }
  };

  const renderFooter = () => {
    return null;
  };

  const handleResetFilterSearchGroup = async () => {
    setFilterSearchGroupCreatedBy({ label: 'Người đăng', id: null });
    setFilterSearchGroupCreatedAt({ label: 'Ngày đăng', id: null });
    if (match.path === PATHS.USER) {
      let res: ResponseApi = await searchPostUser(userId, { q: keyword });
      if (res.status === 200) {
        dispatch(searchFilterPostUserByCreateAt(res.data.statuses));
      }
    } else if (match.path === PATHS.GROUP_DETAIL) {
      let res = await searchFilterPostInGroup(groupSelected?.id, {
        q: keyword
      });
      if (res.status === 200) {
        dispatch(searchFilterPostGroup(res.data));
      }
    }
    history.push({
      pathname: '',
      search: `?q=${keyWordSearch}`
    });
  };

  return (
    <Box
      className={classes.root}
      style={styleGui}
      sx={{ backgroundColor: 'background.primary' }}
    >
      <div className={classes.headerPage}>{renderHeader()}</div>

      <div className={classes.rootPage}>
        {renderBody().bodyTop}
        {renderBody().bodyTop && <Divider />}
        {<Divider sx={{ marginRight: '11px' }} />}
        {renderBody().bodyCenter}
        {renderBody().bodyFooter}
      </div>

      <div className={classes.footerPage}>{renderFooter()}</div>
    </Box>
  );
};

export default SearchGlobalLeft;
