import { Box, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { listMenuPost } from 'src/constants/common';

const useStyles = makeStyles((theme: any) => ({
  wrapPostBottom: {
    border: '1px solid rgba(0,0,0,0.2) ',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px 23px 0px 16px',
    padding: 8,
    height: 58
  },
  postBottomTitle: {
    '&>p': {
      height: '100%',
      fontSize: '15px !important',
      fontWeight: '500 !important'
    }
  },
  icon: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 2px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.primary.hover
    }
  },
  iconSelected: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 2px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  iconDisabled: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 2px',
    opacity: '0.5',
    pointerEvents: 'none',
    '& i': {
      color: 'rgba(0,0,0,0.7)'
    }
  }
}));

interface CUPostBottom {
  setOpenMenuPost?: React.Dispatch<React.SetStateAction<boolean>> | any;
  handleActionKey?: any;
  menuSelected?: any;
  listKeyDisabled?: any;
  sharePostInNewFeed?: React.Dispatch<React.SetStateAction<any>> | any;
  setListKeyDisabled?: React.Dispatch<React.SetStateAction<any>> | any;
  newLifeEvent?: any;
  type?: String;
  idRecruit?: String | any;
  idProject?: String | any;
  idCourse?: String | any;
  event?: any;
  share?: any;
  post?: any;
}

const CUPostBottom: React.FC<CUPostBottom> = React.memo(props => {
  const classes = useStyles();

  const {
    setOpenMenuPost,
    handleActionKey,
    menuSelected,
    listKeyDisabled,
    sharePostInNewFeed,
    setListKeyDisabled,
    newLifeEvent,
    type,
    idRecruit,
    idProject,
    idCourse,
    event,
    share,
    post
  } = props;

  React.useEffect(() => {
    if (
      sharePostInNewFeed ||
      newLifeEvent?.place_id ||
      idRecruit ||
      idProject ||
      idCourse ||
      share ||
      event ||
      post?.reblog ||
      type === 'music' ||
      post?.post_type === 'moment'
    ) {
      setListKeyDisabled([
        'media',
        'live',
        'moment',
        'gif',
        'target',
        'background',
        'poll',
        'answer',
        'life-event'
      ]);
    }
  }, []);

  return (
    <div className={classes.wrapPostBottom}>
      <div className={classes.postBottomTitle}>
        <Typography>Thêm vào bài viết</Typography>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {listMenuPost
          .slice(0, 5)
          ?.filter((el: any) => el.key !== 'moment' && el.key !== 'live')
          ?.map((el: any) => (
            <Box
              key={el.key}
              style={{
                color: el.color,
                backgroundColor: menuSelected?.includes(el.key)
                  ? `${el.color}30`
                  : 'none',
                cursor:
                  (type === 'product' || type === 'product_update') &&
                  el.key === 'media'
                    ? 'inherit'
                    : 'pointer'
              }}
              className={
                menuSelected?.includes(el.key)
                  ? classes.iconSelected
                  : listKeyDisabled.includes(el.key)
                  ? classes.iconDisabled
                  : classes.icon
              }
              onClick={() => handleActionKey(el.key)}
            >
              <Tooltip title={el.label}>
                {el.icon ? (
                  <i className={`${el.icon} fa-lg`}></i>
                ) : el.image ? (
                  <img
                    src={el.image}
                    alt="hình ảnh"
                    style={{
                      height: 23,
                      width: 23,
                      marginRight: '3px',
                      opacity:
                        (type === 'product' || type === 'update_product') &&
                        el.key === 'media'
                          ? '0.4'
                          : '1',
                      ...el.styleImage
                    }}
                  ></img>
                ) : (
                  <></>
                )}
              </Tooltip>
            </Box>
          ))}
        <div
          key="more-icon"
          className={classes.icon}
          onClick={() => {
            setOpenMenuPost(true);
          }}
        >
          <Tooltip title="Xem thêm">
            <i className="far fa-ellipsis-h"></i>
          </Tooltip>
        </div>
      </div>
    </div>
  );
});

export default CUPostBottom;
