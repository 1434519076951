import * as types from 'src/constants/store/socialChat';
export const selectedUserBoxChat = (conversation: Object, type?: string) => ({
  type: types.SELECT_USER_CHAT,
  payload: {
    conversation,
    type
  }
});

// close box chat
export const removeUserBoxChat = (conversation: Object) => ({
  type: types.REMOVE_USER_CHAT,
  payload: {
    conversation
  }
});

// hidden box chat
export const hiddenUserBoxChat = (conversation: Object) => ({
  type: types.HIDDEN_USER_CHAT,
  payload: {
    conversation
  }
});

export const sendMessage = (message: Object) => ({
  type: types.SEND_MESSAGE_SUCCESS,
  payload: {
    message
  }
});

// action box chat - rocket
export const receiveMessage = (message: Object) => ({
  type: types.RECEIVE_MESSAGE_SUCCESS,
  payload: {
    message
  }
});

export const receiveActionBoxChat = (activity: Object) => {
  return {
    type: types.RECEIVE_ACTION_BOX_SUCCESS,
    payload: {
      activity
    }
  };
};

export const subRoomSuccess = (roomId: string, subId: string) => ({
  type: types.SUB_ROOM_SUCCESS,
  payload: {
    roomId,
    subId
  }
});

export const eventMessageFromChatPage = (conversation: Object) => {
  return {
    type: types.EVENT_MESSAGE_FROM_CHAT_PAGE,
    payload: {
      conversation
    }
  };
};

export const eventDeleteMessage = (roomId: string, messageId: string) => ({
  type: types.EVENT_DELETE_MESSAGE,
  payload: {
    roomId,
    messageId
  }
});

export const getInfoMeChatSuccess = (me: object) => ({
  type: types.GET_INFO_ME_CHAT_SUCCESS,
  payload: {
    me
  }
});
export const focusConversation = (id: any) => ({
  type: types.FOCUS_CONVERSATION,
  payload: {
    id
  }
});

// get vote form
export const getCreateVoteForm = (voteForm: any) => {
  return {
    type: types.GET_CREATE_VOTE_MESSAGE,
    payload: {
      voteForm
    }
  };
};

// ----------------------------------------------------------

// load more message for box chat
export const loadMoreMessageSuccess = (messages: Object[], roomId: string) => ({
  type: types.LOAD_MESSAGE_SUCCESS,
  payload: {
    messages,
    roomId
  }
});

export const changedRolesAddMemberGroup = (
  roomId: string,
  roomType: string
) => ({
  type: types.CHANGE_ROLES_ADD_MEMBER_GROUP,
  payload: {
    roomId,
    roomType
  }
});

export const isSoundNotification = (isPlay: boolean) => ({
  type: types.SOUND_NOTIFICATION,
  payload: {
    isPlay
  }
});

// get list conversation for global popup chat
export const getListConversationSuccess = (conversations: Object[]) => ({
  type: types.GET_LIST_CONVERSATION_SUCCESS,
  payload: {
    conversations
  }
});

// action box create
export const openBoxCreate = () => ({
  type: types.OPEN_BOX_CREATE
});

export const closeBoxCreate = () => ({
  type: types.CLOSE_BOX_CREATE
});

export const createGroupChatWith = (conversation: Object) => ({
  type: types.CREATE_GROUP_CHAT_WITH,
  payload: {
    conversation
  }
});
// ----------------------------------------------------------

// action global popup chat - rocket
export const eventReceiveNewMessage = (conversation: Object) => {
  return {
    type: types.EVENT_NEW_MESSAGE,
    payload: {
      conversation
    }
  };
};
// action add data fname from subscriptions-changed to room-changed
export const addFname = (conversation: Object) => {
  return {
    type: types.ADD_FNAME_FOR_ROOM_CHANGE,
    payload: {
      conversation
    }
  };
};
// changed notification
export const handleDisabledNotification = (
  roomId: string,
  disableNotifications: boolean
) => {
  return {
    type: types.DISABLE_NOTIFICATION,
    payload: { roomId, disableNotifications }
  };
};

// changed emoticon

export const changedEmoticon = (roomId: string, emoticon: string) => {
  return {
    type: types.CHANGE_EMOTICON,
    payload: {
      roomId,
      emoticon
    }
  };
};

export const changedTopic = (rid: string, topic: string) => {
  return {
    type: types.CHANGE_TOPIC,
    payload: {
      rid,
      topic
    }
  };
};

export const changedRoleGroupChat = (roomId: string, roleChat: boolean) => {
  return {
    type: types.CHANGE_ROLES_GROUP_CHAT,
    payload: {
      roomId,
      roleChat
    }
  };
};

export const changedPinnedConversation = (
  roomId: string,
  isPinned: boolean
) => {
  return {
    type: types.PINNED_CONVERSATION,
    payload: {
      roomId,
      isPinned
    }
  };
};

export const eventReceiveSubscriptionRoom = (
  conversation: Object,
  message: Object
) => {
  return {
    type: types.EVENT_NEW_SUBSCRIPTION,
    payload: {
      conversation,
      message
    }
  };
};
// action handle block
export const handleBlockerOtherUser = (roomId: string, isBlocker: boolean) => {
  return {
    type: types.IS_BLOCKER,
    payload: {
      roomId,
      isBlocker
    }
  };
};
//  is blocked
export const isBlockedByOtherUser = (roomId: string, isBlocked: boolean) => {
  return {
    type: types.IS_BLOCKED,
    payload: {
      roomId,
      isBlocked
    }
  };
};
// ----------------------------------------------------------

// get list conversation / global popup chat
export const getListConversationReq = (limit: number, lastUpdated: number) => ({
  type: types.GET_LIST_CONVERSATION_REQ,
  payload: {
    limit,
    lastUpdated
  }
});

// setting chat
export const settingChat = (settings: object) => ({
  type: types.SETTING_CHAT,
  payload: {
    settings
  }
});
//delete Conversation
export const deleteConversation = (id: any, type: any) => ({
  type: types.DELETE_CONVERSATION,
  payload: {
    id,
    type
  }
});

export const leaveConversation = (rid: any) => ({
  type: types.LEAVE_CONVERSATION,
  payload: {
    rid
  }
});

// get messeage:
export const getMesseageReq = (conversation: any, lastTime: any) => ({
  type: types.GET_MESSAGE_REQ,
  payload: {
    conversation,
    lastTime
  }
});
export const networkRestoreSuccess = (message: any) => ({
  type: types.NETWORK_RESTORED,
  payload: {
    message
  }
});
export const getMesseageSuccess = (id: any) => ({
  type: types.GET_MESSAGE_SUCCESS,
  payload: {
    id
  }
});

export const resetListPopupChat = () => ({
  type: types.RESET_LIST_POPUP_CHAT
});

export const handleReadUnreadMessageSuccess = (idRoom: any, type: any) => {
  return {
    type: types.READ_UNREAD_MESSAGE,
    payload: {
      idRoom,
      type
    }
  };
};
export const handleReadUnreadPageMessageSuccess = (
  idRoom: any,
  type: any,
  isChatPage: any
) => {
  return {
    type: types.READ_UNREAD_MESSAGE_PAGE,
    payload: {
      idRoom,
      type,
      isChatPage
    }
  };
};

// update members in group chat

export const updateMembersGroupChat = (members: Array<any>, roomId: string) => {
  return {
    type: types.UPDATE_ADD_MEMBERS_GROUP,
    payload: {
      members,
      roomId
    }
  };
};

export const deletePopupChat = (idPopupChat: any) => {
  return {
    type: types.DELETE_QUEUE_POPUP_CHAT,
    payload: {
      idPopupChat
    }
  };
};

export const changeNamePopupChat = (roomId: any, roomName: any) => {
  return {
    type: types.CHANGE_NAME_POPUP_CHAT,
    payload: {
      roomId,
      roomName
    }
  };
};

export const changeAvatarPopupChat = (idPopupChat: any, newAvatar: any) => {
  return {
    type: types.CHANGE_AVATAR_POPUP_CHAT,
    payload: {
      idPopupChat,
      newAvatar
    }
  };
};

export const getListConversationsChatPageReq = (
  idPage: any,
  count?: number,
  offset?: number,
  label?: any
) => {
  return {
    type: types.GET_LIST_CONVERSATION_CHAT_PAGE_REQ,
    payload: {
      idPage,
      count,
      offset,
      label
    }
  };
};

export const getStatusUserRealTime = (username: string, status: string) => {
  return {
    type: types.USER_STATUS_REAL_TIME,
    payload: {
      username,
      status
    }
  };
};

export const getListConversationsChatPageSuccess = (
  listConversations: any,
  idPage: any,
  label?: any
) => {
  return {
    type: types.GET_LIST_CONVERSATION_CHAT_PAGE_SUCCESS,
    payload: {
      listConversations,
      idPage,
      label
    }
  };
};

export const addLabelConversationChatPage = (roomId: string, label: Object) => {
  return {
    type: types.ADD_LABEL_CHAT_PAGE,
    payload: {
      roomId,
      label
    }
  };
};

export const removeLabelConversationChatPage = (
  roomId: string,
  label: Object
) => {
  return {
    type: types.REMOVE_LABEL_CHAT_PAGE,
    payload: {
      roomId,
      label
    }
  };
};

export const removeLabelTotalPage = (label: Object) => {
  return {
    type: types.REMOVE_LABEL_TOTAL_PAGE,
    payload: {
      label
    }
  };
};

export const blockerUser = (id: String) => {
  return {
    type: types.BLOCKER_USER,
    payload: {
      id
    }
  };
};

export const blockedUser = (id: String) => {
  return {
    type: types.BLOCKED_USER,
    payload: {
      id
    }
  };
};

export const unBlockerUser = (id: String) => {
  return {
    type: types.UN_BLOCKER_USER,
    payload: {
      id
    }
  };
};
export const unBlockerPage = (id: String) => {
  return {
    type: types.UN_BLOCKER_PAGE,
    payload: {
      id
    }
  };
};
export const getMessageAutomatic = (message: String) => {
  return {
    type: types.GET_MESSAGE_AUTOMATIC,
    payload: {
      message
    }
  };
};
