import axios, { AxiosRequestConfig } from 'axios';
import { BASE_ROOT, USER_TOKEN } from 'src/util/config';
import { apiDelete, apiGet, apiPatch, apiPost } from './apis';

export const getDataCertificationApi = async () => {
  return apiGet('account_request_certifications', null, null);
};

export const requestFriendApi = async (userId: string) => {
  return apiPost(`accounts/${userId}/friendship_requests`, null, null);
};

export const removeFriendshipSuggestions = async (userId: string) => {
  return apiPost(`remove_friendship_suggestions`, null, { account_id: userId });
};

export const listRequestFriendApi = async params => {
  return apiGet('friendship_requests', params, null);
};

export const listRequestToFriendApi = async params => {
  return apiGet('requested_friendships', params, null);
};

export const approveRequestFriendApi = async (userId: string) => {
  return apiPost(`accounts/${userId}/approve_friendship`, null, null);
};

export const deleteRequestFriendApi = async (userId: string) => {
  return apiPost(`accounts/${userId}/reject_friendship`, null, null);
};

export const cancelRequestFriendApi = async (userId: string) => {
  return apiPost(`accounts/${userId}/cancel_friendship_requests`, null, null);
};

export const getInforUser = async (userId: string, source) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : ''
  };
  let response = await axios(configs);
  return response;
};

export const getMediaUser = async (
  userId: string,
  params: any,
  source: any
) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/media_attachments`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : '',
    params
  };
  let response = await axios(configs);
  return response;
};

export const getFriendUser = async (
  userId: string,
  params: any = null,
  source: any
) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/friendships`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : '',
    params
  };
  let response = await axios(configs);
  return response;
};

export const searchFriendUser = async (userId: string, params: any) => {
  return apiGet('friendships', params, null);
};

export const unFriendUser = async (userId: string) => {
  return apiPost(`accounts/${userId}/unfriend`, null, null);
};

export const followUser = async (userId: string) => {
  return apiPost(`accounts/${userId}/follow`, null, null);
};

export const unFollowUser = async (userId: string) => {
  return apiPost(`accounts/${userId}/unfollow`, null, null);
};

export const updateCredentialsUser = async data => {
  var formdata = new FormData();
  if (data.avatar) {
    if (data.avatar.file) {
      formdata.append('avatar[file]', data.avatar.file);
    }
    if (data.avatar.id) {
      formdata.append('avatar[id]', data.avatar.id);
    }
    if (data.avatar.show_url) {
      formdata.append('avatar[show_url]', data.avatar.show_url);
    }
    formdata.append('avatar[status]', data.avatar.status ?? '');
    if (data.avatar.frame_id) {
      formdata.append('avatar[frame_id]', data.avatar.frame_id);
    }
  }
  if (data.header) {
    if (data.header.file) {
      formdata.append('header[file]', data.header.file);
    }
    if (data.header.id) {
      formdata.append('header[id]', data.header.id);
    }
    if (data.header.show_url) {
      formdata.append('header[show_url]', data.header.show_url);
    }
    formdata.append('header[status]', data.header.status ?? '');
  }

  if (data.hobby_ids) {
    formdata.append('hobby_ids', data.hobby_ids);
  }
  if (data.display_name) {
    formdata.append('display_name', data.display_name);
  }
  if (data.username) {
    formdata.append('username', data.username);
  }

  return apiPatch('accounts/update_credentials', null, formdata);
};

export const followingUser = async (userId: string, params) => {
  return apiGet(`accounts/${userId}/followings`, params, null);
};

export const followerUser = async (userId: string, params) => {
  return apiGet(`accounts/${userId}/followers`, params, null);
};

export const getAboutUser = async (userId: string, source: any) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/accounts/${userId}/abouts`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : ''
  };
  let response = await axios(configs);
  return response;
};

export const getRelationshipCategories = async () => {
  return apiGet('relationship_categories', null, null);
};

export const getRelationshipFamily = async () => {
  return apiGet('family_relationship_categories', null, null);
};

export const CRUDRelationshipFamily = async () => {
  return apiGet('family_relationships', null, null);
};

export const getListInvitedAdminPage = async () => {
  return apiGet(`page_invitations`, null, null);
};

export const getStatusUser = async () => {
  return apiGet(`account_settings`, null, null);
};

export const getFriendDiscover = async params => {
  return apiGet(`friend_suggestions`, params, null);
};

export const postStatusUser = async data => {
  return apiPost('account_settings', null, data);
};

export const getListObjectBlock = async (object: any, params: any) => {
  return apiGet(`${object}`, null, params);
};
export const addObejctBlock = async (object: any, id: any) => {
  return apiPost(`${object}`, null, id);
};
export const removeObjectBlock = async (object: any, id: any) => {
  return apiDelete(`${object}/${id}`, null, null);
};
export const postUpdateInfoUser = async (data: any) => {
  return apiPatch(`account_general_infomation`, null, data);
};

export const getAccountGeneralInformation = async () => {
  return apiGet(`account_general_infomation`, null, null);
};

export const postUpdateRelationshipUser = async (data: any, type, about) => {
  if (type === 'create') {
    return apiPost('account_relationships', null, data);
  }

  return apiPatch(`account_relationships/${about.id}`, null, data);
};
export const deleteFamilyRelationship = async (id: any) => {
  return apiDelete(`family_relationships/${id}`, null, null);
};
export const addFamilyRelationship = async (data: any) => {
  return apiPost(`family_relationships`, null, data);
};
export const updateFamilyUser = async (data: any, id: any) => {
  return apiPatch(`family_relationships/${id}`, null, data);
};
export const updateHobbyUser = async (data: any) => {
  return apiPatch(`accounts/update_credentials`, null, data);
};
export const getHobbyUser = async () => {
  return apiGet(`accounts/update_credentials`, null, null);
};
export const changePassword = async (data: any) => {
  return apiPost(`change_password`, null, data);
};
export const checkPasswordUser = async (data: any) => {
  return apiPost(`valid_password`, null, data);
};
export const getLifeEventUser = async (userId: any) => {
  return apiGet(`accounts/${userId}/life_events`, null, null);
};
export const updateLifeEventAccount = async (userId, idLifeEvent, data) => {
  return apiPatch(`accounts/${userId}/life_events/${idLifeEvent}`, null, data);
};
export const deleteLifeEventAccount = async (userId: any, idLifeEvent) => {
  return apiDelete(`accounts/${userId}/life_events/${idLifeEvent}`, null, null);
};

export const deleteRelationship = async (relationshipId: any) => {
  return apiDelete(`account_relationships/${relationshipId}`, null, null);
};

export const blockPages = async (data: any) => {
  return apiPost(`block_pages`, null, data);
};
export const getListBlockPages = async (params: any) => {
  return apiGet('block_pages', null, params);
};
export const getListCategoriesReport = async () => {
  return apiGet('report_categories', null, null);
};
export const sendReport = async (data: any) => {
  return apiPost('report_violations', null, data);
};
export const reportPost = async (params: any) => {
  return apiPost('reports', null, params);
};

export const getListNoticeableCollection = async (userId: any) => {
  return apiGet(`accounts/${userId}/featured_contents`, null, null);
};

export const getListMediaCollection = async (
  userId: any,
  collectionId: any,
  params: any
) => {
  return apiGet(
    `accounts/${userId}/featured_contents/${collectionId}/media_attachments`,
    params,
    null
  );
};

export const CUNoticeableCollection = async (data, id) => {
  return !id
    ? apiPost('account_featured_contents', null, data)
    : apiPatch(`account_featured_contents/${id}`, null, data);
};

export const deleteNoticeableCollection = async (id: any) => {
  return apiDelete(`account_featured_contents/${id}`, null, null);
};

export const searchPostUser = async (userId: any, params: any) => {
  return apiGet(`accounts/${userId}/statuses/search`, params, null);
};
export const getHistorysearch = async (params: any) => {
  return apiGet(`search_histories`, params, null);
};
export const deleteHistorySearch = async (id: any) => {
  return apiDelete(`search_histories/${id}`, null, null);
};
export const deleteAllHistorySearch = async () => {
  return apiDelete(`all_search_histories`, null, null);
};
export const getHistoryPosts = async (params: any) => {
  return apiGet(`watch_histories`, params, null);
};
export const deleteHistoryPost = async (id: any) => {
  return apiDelete(`watch_histories/${id}`, null, null);
};

export const completeTargetUser = async (id: any) => {
  return apiPost(`statuses/${id}/complete_target`, null, null);
};

export const turnOnNotification = async (data: any) => {
  return apiPost(`allow_notification_posts`, null, data);
};
export const socialCategories = async source => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v1/social_categories`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    [`${source ? 'cancelToken' : ''}`]: source ? source.token : ''
  };
  let response = await axios(configs);
  return response;
};

export const getPinnedPostUser = async (idUser: any, params: any) => {
  return apiGet(`accounts/${idUser}/pin`, params, null);
};

export const getListAccountsSuggest = async (idUser: any, params: any) => {
  return apiGet(
    `accounts/${idUser}/follow_moments/suggest_moments`,
    params,
    null
  );
};

export const getListAccountsFollow = async (idUser: any, params: any) => {
  return apiGet(`accounts/${idUser}/follow_moments`, params, null);
};
export const unBlockPages = async (idPage: any) => {
  return apiDelete(`block_pages/${idPage}`, null, null);
};

export const getListPageLikes = async (idUser: any, params: any) => {
  return apiGet(
    `accounts/${idUser}/page_likes`,
    { ...params, sort_direction: 'desc' },
    null
  );
};

export const getListHashTagMoment = async (params: any) => {
  return apiGet(`trends/tags`, params, null);
};

export const requestBadgeApi = async (data: any) => {
  return apiPost(`account_request_certifications`, null, data);
};

export const fetchFamilyRelationship = async (id: any, data: any) => {
  return apiPatch(`response_family_relationship/${id}`, null, data);
};

export const fetchAccountRelationship = async (id: any, data: any) => {
  return apiPatch(`response_account_relationship/${id}`, null, data);
};

export const verificationsUser = async (data: any, type: any) => {
  const formData: any = new FormData();

  formData.append('name', data.name);
  formData.append('phone_number', data.phone_number);

  if (data.identity_card_back) {
    formData.append('identity_card_back[id]', data.identity_card_back.id);
  }
  if (data.identity_card_front) {
    formData.append('identity_card_front[id]', data.identity_card_front.id);
  }
  if (data.identity_verification_video) {
    formData.append(
      'identity_verification_video[id]',
      data.identity_verification_video.id
    );
  }

  if (type === 'update') {
    return apiPatch(`identity_verifications/1`, null, formData);
  }

  return apiPost('identity_verifications', null, formData);
};

export const getListVerificationUser = () => {
  return apiGet(`identity_verifications`, null, null);
};
export const getVerificationUser = (id: any) => {
  return apiGet(`identity_verifications/${id}`, null, null);
};
export const getVerificationPage = (id: any) => {
  return apiGet(`page_identity_verifications/${id}`, null, null);
};
export const openCriteriaMoney = data => {
  return apiPost(`earn_moneys`, null, data);
};
export const updateCriteriaMoney = (id: any) => {
  return apiPatch(`earn_moneys/${id}`, null, null);
};

export const disabledUser = () => {
  return apiPost(`suspend`, null, null);
};

export const deactivateUser = data => {
  return apiPost(`deactivation`, null, data);
};
export const getCategoriesFeedback = () => {
  return apiGet('feedback_categories', null, null);
};
export const sendFeedback = data => {
  return apiPost('feedbacks', null, data);
};
