import axios, { AxiosRequestConfig } from 'axios';
import { errorManyReq } from 'src/store/action/socialUserAction';
import store from 'src/store/store';
import { BASE_ROOT, USER_TOKEN } from 'src/util/config';

export interface Response<T> {
  data: T | null | undefined;
  status: number;
  isError: boolean;
  errorMessage: string;
}

export async function apiGetV2<R>(
  endPoint: string,
  params: any,
  data: any
): Promise<Response<R>> {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v2/${endPoint}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };
  try {
    let response = await axios(configs);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
        isError: false,
        errorMessage: ''
      };
    }

    return {
      data: null,
      status: response.status,
      isError: true,
      errorMessage: 'Lỗi khi thực hiện yêu cầu.'
    };
  } catch (error) {
    throw error;
  }
}

export async function apiPostV2<R>(
  endPoint: string,
  params: any,
  data: any
): Promise<Response<R>> {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v2/${endPoint}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };
  try {
    let response = await axios(configs);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
        isError: false,
        errorMessage: ''
      };
    }

    return {
      data: null,
      status: response.status,
      isError: true,
      errorMessage: 'Lỗi khi thực hiện yêu cầu.'
    };
  } catch (error: any) {
    if (error.response.status === 429) {
      store.dispatch(errorManyReq(true));
    } else {
      store.dispatch(errorManyReq(false));
    }
    throw error;
  }
}

export async function apiPatchV2<R>(
  endPoint: string,
  params: any,
  data: any
): Promise<Response<R>> {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v2/${endPoint}`,
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };
  try {
    let response = await axios(configs);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
        isError: false,
        errorMessage: ''
      };
    }

    return {
      data: null,
      status: response.status,
      isError: true,
      errorMessage: 'Lỗi khi thực hiện yêu cầu.'
    };
  } catch (error: any) {
    if (error.response.status === 429) {
      store.dispatch(errorManyReq(true));
    } else {
      store.dispatch(errorManyReq(false));
    }
    throw error;
  }
}

export async function apiDeleteV2<R>(
  endPoint: string,
  params: any,
  data: any
): Promise<Response<R>> {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/v2/${endPoint}`,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data
  };
  try {
    let response = await axios(configs);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
        isError: false,
        errorMessage: ''
      };
    }

    return {
      data: null,
      status: response.status,
      isError: true,
      errorMessage: 'Lỗi khi thực hiện yêu cầu.'
    };
  } catch (error) {
    throw error;
  }
}
