import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Divider, Typography, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import MediaPreview from 'src/components/ImageGrid/components/MediaPreview';
import SocialPostAction from 'src/components/SocialPost/SocialPostAction';
import SocialPostInformation from 'src/components/SocialPost/SocialPostInformartion';
import { postSelectedAction } from 'src/store/action/socialWatchAction';
import reactStringReplace from 'react-string-replace';
import {
  getPostMediaApi,
  postWatchHistoriesApi
} from 'src/apis/socialPost.api';
import { buttonColor } from 'src/constants/styles';
import _ from 'lodash';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // [theme.breakpoints.down('xl')]: {
    //   height: '80vh'
    // },
    height: '100%',
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center'
  },
  wrapVideo: {
    maxHeight: '100%',
    minWidth: '900px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
    // [theme.breakpoints.down('xl')]: {
    //   width: 800
    // }
  },
  wrapInformation: {
    width: 490,
    maxHeight: '100%',
    padding: '0px 16px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  wrapContent: {
    fontSize: '22px !important',
    fontWeight: '600 !important'
  },
  chip: {
    margin: '0px 5px !important'
  },
  textChip: {
    fontSize: '15px !important',
    fontWeight: '500 !important'
  },
  textChipSelected: {
    fontSize: '15px !important',
    fontWeight: '500 !important',
    color: buttonColor.backgroundColor
  },
  chipSelected: {
    margin: '0px 5px !important',
    backgroundColor: '#e7f3ff !important'
  },
  textLink: {
    color: buttonColor.backgroundColor,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline'
    },
    '&:focus': {
      color: '#6f2da8'
    },
    cursor: 'pointer'
  }
}));

interface Props {
  setWidthLeft?: any;
  open?: boolean;
  type?: string | undefined;
}

const PostWatch = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setWidthLeft, open, type } = props;
  let query = useQuery();
  const [keyOption, setKeyOption] = React.useState<any>('overview');
  const [height, setHeight] = React.useState<any>(0);
  const [chooseFocus, setChooseFocus] = React.useState<any>(false);
  const postWatchSelected = useSelector(
    (state: any) => state.watchReducer.postSelected
  );
  const contentRef: any = React.useRef(null);
  const leftRef: any = React.useRef(null);

  const [postMedia, setPostMedia] = React.useState<any>(null);

  const formatHashtang = str => {
    return reactStringReplace(
      str,
      /(#[^\s#~`!@$%^&*()=+-><.,?/"':;|{}]+)/gi,
      (match, i) => {
        return (
          <a
            key={match + i}
            href={`/hashtag/${match.slice(1)}`}
            className={classes.textLink}
          >
            {match}
          </a>
        );
      }
    );
  };
  const listOption = [
    { id: 'overview', label: 'Tổng quan' },
    { id: 'comment', label: 'Bình luận' }
  ];

  const handleClickChip = key => {
    setKeyOption(key);
  };
  const getPostMediaDetail = async () => {
    let res = await getPostMediaApi(query.get('v'));

    if (res.status === 200) {
      setPostMedia(res.data);
      dispatch(postSelectedAction(res.data));
    }
  };
  const updateVideoHistories = React.useCallback(
    _.debounce(async data => {
      await postWatchHistoriesApi(data);
    }, 800),
    []
  );
  React.useEffect(() => {
    if (contentRef?.current?.clientHeight && keyOption === 'overview') {
      setHeight(contentRef.current.clientHeight + 10);
    } else {
      setHeight(0);
    }
  }, []);

  React.useEffect(() => {
    if (postWatchSelected && Object.keys(postWatchSelected).length) {
      setPostMedia(postWatchSelected);
    } else if (query.get('v')) {
      getPostMediaDetail();
    }
  }, [JSON.stringify(postWatchSelected), query.get('v')]);

  React.useEffect(() => {
    if (postMedia) {
      updateVideoHistories({
        action_type: 'impressions',
        status_ids: [postMedia.id]
      });
      updateVideoHistories({
        action_type: 'view_3s',
        status_ids: [postMedia.id]
      });
    }
  }, [postMedia]);

  React.useEffect(() => {
    return () => {
      dispatch(postSelectedAction({}));
    };
  }, []);

  return (
    postMedia && (
      <div className={classes.root}>
        <div ref={leftRef} className={classes.wrapVideo}>
          <MediaPreview
            setWidthLeft={setWidthLeft}
            postMedia={postMedia}
            medias={postMedia.media_attachments}
            typeWatch="watch-selected"
            type={type}
            open={open}
          />
          <div style={{ width: '100%' }}>
            <Divider />
            <SocialPostAction
              key={`${postMedia.id}-socialAction`}
              post={postMedia}
              setPostData={setPostMedia}
              setChooseFocus={setChooseFocus}
            />
          </div>
        </div>
        <div className={classes.wrapInformation}>
          <div>
            <SocialPostInformation
              key={`${postMedia.id}-postInformation`}
              type="watch-selected"
              post={postMedia}
              setPostData={setPostMedia}
              roleGroupMember={postMedia?.group_account_relationship} //check quyền quản trị viên
            />
            <Divider sx={{ margin: '10px 0px' }} />
            {listOption?.map((el: any) => (
              <Chip
                className={
                  keyOption === el.id ? classes.chipSelected : classes.chip
                }
                key={el.id}
                label={
                  <Typography
                    className={
                      keyOption === el.id
                        ? classes.textChipSelected
                        : classes.textChip
                    }
                  >
                    {el.label}
                  </Typography>
                }
                onClick={() => handleClickChip(el.id)}
              />
            ))}
            {keyOption === 'overview' && (
              <div ref={contentRef}>
                <Divider sx={{ margin: '10px 0px' }} />
                <Typography>{formatHashtang(postMedia.content)}</Typography>
              </div>
            )}
          </div>
          <SocialPostAction
            type="watch-selected"
            key={`${postMedia.id}-socialAction`}
            post={postMedia}
            setPostData={setPostMedia}
            styleWatch={{
              height:
                keyOption === 'comment'
                  ? `calc(100% - ${212 + height}px)`
                  : `calc(100% - ${305 + height}px)`,
              styleComment: {
                overflow: 'auto !important',
                height: 'calc(100% - 60px)'
              }
            }}
            chooseFocus={chooseFocus}
            setChooseFocus={setChooseFocus}
          />
        </div>
      </div>
    )
  );
};

export default PostWatch;
