import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React from 'react';
import BaseLink from 'src/components/Link/BaseLink';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  followUser,
  getFriendDiscover,
  getFriendUser,
  getInforUser,
  requestFriendApi,
  unFollowUser,
  unFriendUser,
  removeFriendshipSuggestions
} from 'src/apis/socialUser';
import DialogConfirm from 'src/components/Dialog/DialogConfirmDelete';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import MenuAction from 'src/components/Menu';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { SkeletonFriendItem } from 'src/components/Skeleton/skeleton';
import { buttonColor } from 'src/constants/styles';
import FriendRequest from './FriendRequest';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import axios from 'axios';
import { getInforlUserReq } from 'src/store/action/socialUserAction';
import { saveUserInfo } from 'src/store/action/saveUser';
import ButtonInherit from 'src/components/Button/ButtonInherit';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100% - 50px)',
    width: 310,
    position: 'fixed',
    top: 50,

    marginBottom: 20,
    boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
  },
  rootTitle: {
    fontSize: '22px !important',
    fontWeight: '700 !important'
  },
  wrapHeader: {
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  list: {
    width: '290px'
  },
  selected: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? '#47494c !important'
        : '#ECF3FF !important'
  },
  textPrimary: {
    fontSize: '17px !important',
    fontWeight: '500 !important'
  },
  wrapContent: {
    width: '100%',
    height: 'calc(100vh - 110px)',
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto'
    },
    '&:hover&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundImage: 'linear-gradient(to bottom,#ffa400,#00aefd)'
    }
  },
  wrapMenu: {
    padding: '0px 8px'
  },
  iconExit: {
    backgroundColor: theme.palette.mode === 'dark' ? '#5c5d60' : '#E4E6EB',
    width: 36,
    height: 36,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.2)'
    }
  },
  textSecondary: {
    fontSize: '13px !important',
    color: '#65676b'
  }
}));

interface ItemListData {
  item: any;
  index: number;
  selectedIndex: string;
  handleAction: any;
  setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}

const ItemListData = (props: ItemListData) => {
  const classes = useStyles();

  const history = useHistory();
  const match: any = useRouteMatch();

  const { item, index, selectedIndex, handleAction, setOpenConfirm } = props;

  const [isFollow, setIsFollow] = React.useState<boolean>(
    item.relationships.following
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });

  let listMenu = [
    {
      key: 'unFollow',
      icon: 'fa-light fa-square-xmark',
      label: isFollow ? 'Bỏ theo dõi' : 'Theo dõi',
      action: () => {
        handleAction(isFollow ? 'unFollow' : 'follow', setIsFollow);
        handleCloseMenu();
      }
    },
    {
      key: 'addFriend',
      icon: 'fa-solid fa-user-plus',
      label: 'Thêm bạn bè',
      action: () => {
        handleAction('addFriend');
      }
    }
  ];

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <BaseLink
        key={index}
        url={item?.url ? item?.url : null}
        component={
          <ListItem
            style={{
              borderRadius: 10,
              maxHeight: item.icon ? 58 : 92,
              marginBottom: '6px',
              padding: 8
            }}
            button
            onClick={() => {
              history.push(`/friends/${selectedIndex}/${item.id}`);
            }}
            key={item?.id}
            className={
              match?.params?.userId === item?.id
                ? classes.selected
                : 'undefined'
            }
            secondaryAction={
              <i onClick={handleClick} className="fa-solid fa-ellipsis"></i>
            }
          >
            <>
              {!item.icon && (
                <AvatarSocial
                  type="feed"
                  style={{ width: 50, height: 50 }}
                  avatarObj={item.avatar_media}
                />
              )}

              <ListItemText
                style={{ marginLeft: 10 }}
                primary={
                  <Typography
                    className={classes.textPrimary}
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      width: '175px'
                    }}
                  >
                    {item?.display_name ?? item?.username}
                    {item?.certified && (
                      <Tooltip
                        placement="top-start"
                        title="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                        arrow
                        sx={{
                          '& .MuiTooltip-tooltip': {
                            fontSize: '14px',
                            padding: '10px'
                          }
                        }}
                      >
                        <VerifiedIcon
                          // titleAccess="Tài khoản đã xác minh"
                          fontSize="small"
                          viewBox="0 -2 24 24"
                          sx={{
                            color: buttonColor.backgroundColor,
                            fontSize: '14px',
                            marginLeft: '8px',
                            '&:hover': {
                              color: buttonColor.backgroundColor
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                    {!item.certified && item.identity_verification && (
                      <Tooltip
                        placement="top-start"
                        title="Emso đã xác nhận tài khoản này đã được xác minh danh tính"
                        arrow
                      >
                        <VerifiedIcon
                          // titleAccess="Tài khoản đã xác minh"
                          fontSize="small"
                          viewBox="0 -2 24 24"
                          sx={{
                            color: 'hsla(0, 0%, 50%)',
                            fontSize: '14px',
                            '&:hover': {
                              color: 'hsla(0, 0%, 60%)'
                            }
                          }}
                        />
                      </Tooltip>
                    )}
                  </Typography>
                }
                secondary={
                  !item.icon ? (
                    <Typography className={classes.textSecondary}>
                      {item?.relationships?.mutual_friend_count ?? 0} bạn chung
                    </Typography>
                  ) : null
                }
              />
            </>
          </ListItem>
        }
      />
      {anchorEl && (
        <MenuAction
          anchorEl={anchorEl}
          openPopper={openPopper}
          setOpenPopper={setOpenPopper}
          handleCloseMenu={handleCloseMenu}
          listMenu={listMenu}
        />
      )}
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </>
  );
};

const FriendBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const theme: any = useTheme();
  const [selectedIndex, setSelectedIndex] = React.useState('friends');
  const [listFriend, setListFriend] = React.useState<any>([]);
  const meInfo = useSelector((state: any) => state?.meReducer.info);
  const [hasMore, setHasMore] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [loadingConfirm, setLoadingConfirm] = React.useState(false);
  const [isFollow, setIsFollow] = React.useState(true);
  const [keyword, setKeyword] = React.useState<any>('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });

  const getSearchFriendData = React.useCallback(
    _.debounce(async params => {
      let response = await getFriendUser(meInfo?.id, params, null);
      if (response.status === 200) {
        setListFriend(response.data);
        setHasMore(false);
      }
    }, 500),
    []
  );

  async function fetchFriendUser(maxId = null, type = null as any) {
    let response;
    if (type === 'list') {
      response = await getFriendUser(
        meInfo.id,
        {
          limit: 25,
          max_id: maxId
        } as any,
        null
      );
    } else if (type === 'suggestions') {
      response = await getFriendDiscover({
        limit: 25,
        offset: listFriend.length
      } as any);
    }
    if (response?.status === 200) {
      if (response.data.length) {
        if (response.data.length < 25) {
          setHasMore(false);
        }
        setListFriend(prev => [...prev, ...response.data]);
      } else {
        setHasMore(false);
      }
    }
  }

  const funcLoad = () => {
    let mediaLast = [...listFriend].pop();
    let maxId = mediaLast?.id;

    if (listFriend.length) {
      fetchFriendUser(maxId, selectedIndex);
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  let listMenu = [
    {
      key: 'unFollow',
      icon: 'fa-light fa-square-xmark',
      label: isFollow ? 'Bỏ theo dõi' : 'Theo dõi',
      action: () => {
        handleAction(isFollow ? 'unFollow' : 'follow');
        handleCloseMenu();
      }
    },
    {
      key: 'unFriend',
      icon: 'fa-light fa-user-xmark',
      label: 'Hủy kết bạn',
      action: () => {
        setOpenConfirm(true);
        handleCloseMenu();
      }
    }
  ];

  const dispatch = useDispatch();
  const handleInforUser = async (userId, source: any) => {
    let res = await getInforUser(userId, source);
    if (res.status === 200) {
      dispatch(getInforlUserReq(res.data));
      dispatch(saveUserInfo(res.data));
    }
  };

  const handleActionFriendSuggestion = async (type: string, idUser: string) => {
    if (type === 'addFriend') {
      const response = await requestFriendApi(idUser);
      if (response.status === 200) {
        let index = listFriend.findIndex((el: any) => el.id === idUser);
        setListFriend(prev => [
          ...prev.slice(0, index),
          ...prev.slice(index + 1)
        ]);
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        handleInforUser(idUser, source);
      }
    }
    if (type === 'removeFriend') {
      const response = await removeFriendshipSuggestions(idUser);
      if (response.status === 200) {
        let index = listFriend.findIndex((el: any) => el.id === idUser);
        listFriend.splice(index, 1);
        setListFriend([...listFriend]);
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        handleInforUser(idUser, source);
      }
    }
  };
  const renderTextAlert = type => {
    console.log('type', type);
    if (type === 'delete') {
      return 'Đã từ chối lời mời kết bạn.';
    } else if (type === 'removeRequest') {
      return 'Đã hủy yêu cầu kết bạn.';
    } else if (type === 'approve') {
      return 'Đã chấp nhận lời mời kết bạn';
    } else if (type === 'addFriend') {
      return 'Đã gửi lời mời kết bạn.';
    } else if (type === 'unFollow') {
      return 'Đã hủy theo dõi.';
    } else if (type === 'unFriend') {
      return 'Đã hủy kết bạn.';
    } else if (type === 'follow') {
      return 'Đã follow.';
    }
  };
  const handleAction = async (
    type,
    setIsFollowElement: undefined | any = undefined
  ) => {
    let response;
    try {
      if (type === 'unFollow') {
        response = await unFollowUser(match.params.userId);
      } else if (type === 'addFriend') {
        response = await requestFriendApi(match.params.userId);
      } else if (type === 'follow') {
        response = await followUser(match.params.userId);
      }
      if (response?.status === 200) {
        if (type === 'addFriend') {
          let index = listFriend.findIndex(
            (el: any) => el.id === match.params.userId
          );
          setListFriend(prev => [
            ...prev.slice(0, index),
            ...prev.slice(index + 1)
          ]);
          const CancelToken = axios.CancelToken;
          const source = CancelToken.source();
          handleInforUser(match.params.userId, source);
        }
        if (setIsFollowElement) setIsFollowElement(prev => !prev);
        else setIsFollow(prev => !prev);
        setLoadingConfirm(false);
        setOpenConfirm(false);
      }
      setOpenSnackbar(true);
      setNoti({
        code: 200,
        message: renderTextAlert(type) ?? 'Đã được gửi.'
      });
    } catch (error: any) {
      setOpenSnackbar(true);
      setNoti({
        code: error.response.status,
        message:
          'Đã có lỗi xảy ra. Vui lòng thử lại sau!' ||
          error?.response?.data?.error
      });
    }
  };

  const listMenuBar = [
    {
      id: 'friends',
      label: 'Trang chủ',
      icon: 'fa-solid fa-user-group'
    },
    {
      id: 'requests',
      label: 'Lời mời kết bạn',
      isMore: true,
      icon: 'fa-solid fa-user-check'
    },
    {
      id: 'suggestions',
      label: 'Gợi ý',
      isMore: true,
      icon: 'fa-solid fa-user-plus'
    },
    {
      id: 'list',
      label: 'Tất cả bạn bè',
      isMore: true,
      icon: 'fa-solid fa-bars'
    }
  ];

  const renderPathSuggestions = item => {
    return (
      <>
        {!item.icon && (
          <AvatarSocial
            type="feed"
            style={{ width: 60, height: 60 }}
            avatarObj={item.avatar_media}
          />
        )}
        <Grid>
          <ListItemText
            style={{ marginLeft: 12 }}
            primary={
              <Typography
                className={classes.textPrimary}
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  width: '175px',
                  fontSize: '14px !important'
                }}
              >
                {item?.label
                  ? item?.label
                  : item?.display_name
                  ? item?.display_name
                  : item?.username}
                {item?.certified ? (
                  <Tooltip
                    placement="top-start"
                    title="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                    arrow
                    sx={{
                      '& .MuiTooltip-tooltip': {
                        fontSize: '14px',
                        padding: '10px'
                      }
                    }}
                  >
                    <VerifiedIcon
                      // titleAccess="Tài khoản đã xác minh"
                      fontSize="small"
                      viewBox="0 -2'32 24"
                      sx={{
                        color: buttonColor.backgroundColor,
                        fontSize: '14px',
                        marginLeft: '8px',
                        '&:hover': {
                          color: buttonColor.backgroundColor
                        }
                      }}
                    />
                  </Tooltip>
                ) : null}
                {!item.certified && item.identity_verification ? (
                  <Tooltip
                    placement="top-start"
                    title="Emso đã xác nhận tài khoản này đã được xác minh danh tính"
                    arrow
                  >
                    <VerifiedIcon
                      // titleAccess="Tài khoản đã xác minh"
                      fontSize="small"
                      viewBox="0 -2 24 24"
                      sx={{
                        color: 'hsla(0, 0%, 50%)',
                        fontSize: '14px',
                        '&:hover': {
                          color: 'hsla(0, 0%, 60%)'
                        }
                      }}
                    />
                  </Tooltip>
                ) : null}
              </Typography>
            }
            secondary={
              !item.icon ? (
                <Typography
                  className={classes.textSecondary}
                  sx={{ paddingBottom: '2px', fontSize: '14px !important' }}
                >
                  {item?.relationships?.mutual_friend_count ?? 0} bạn chung
                </Typography>
              ) : null
            }
          />
          <Grid display={'flex'} marginLeft={'12px'}>
            <ButtonInherit
              label="Thêm bạn bè"
              style={{
                height: '30px',
                paddingLeft: '18px',
                paddingRight: '18px',
                '&:hover': {
                  backgroundColor: '#7165e0',
                  color: 'rgb(247, 247, 247)'
                }
              }}
              styleText={{
                fontSize: 14,
                fontWeight: '500 !important'
              }}
              action={() => handleActionFriendSuggestion('addFriend', item?.id)}
              // style={{ backgroundColor: '#e4e6eb' }}
            />
            <ButtonInherit
              label="Gỡ"
              style={{
                height: '30px',
                '&:hover': {
                  backgroundColor: '#7165e0',
                  color: 'rgb(247, 247, 247)'
                }
              }}
              styleText={{
                fontSize: 14,
                fontWeight: '500 !important'
              }}
              action={e => {
                e.preventDefault();
                e.stopPropagation();
                handleActionFriendSuggestion('removeFriend', item?.id);
              }}
              // style={{ backgroundColor: '#e4e6eb' }}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderList = (list, type = null) => {
    return (
      <List className={classes.list} component="nav" aria-label="sidebar">
        {list?.map((item: any, index) => (
          <BaseLink
            key={index}
            url={item?.url ? item?.url : null}
            component={
              <ListItem
                style={{
                  borderRadius: 10,
                  maxHeight: item.icon ? 58 : 92,
                  marginBottom: '6px',
                  padding: 8
                }}
                button
                onClick={() => {
                  item.display_name
                    ? history.push(`/friends/${selectedIndex}/${item.id}`)
                    : history.push(
                        item?.id === 'friends'
                          ? '/friends'
                          : `/friends/${item?.id}`
                      );
                  item?.action && item?.action();
                }}
                key={item?.id}
                className={
                  selectedIndex === item?.id ||
                  match?.params?.userId === item?.id
                    ? classes.selected
                    : 'undefined'
                }
                secondaryAction={
                  item.isMore ? (
                    <i className="fa-solid fa-angle-right"></i>
                  ) : (item.display_name ? item.display_name : item.username) &&
                    selectedIndex !== 'suggestions' ? (
                    <i
                      onClick={handleClick}
                      className="fa-solid fa-ellipsis"
                    ></i>
                  ) : null
                }
              >
                {item?.icon && (
                  <div
                    style={{
                      width: 36,
                      height: 36,
                      backgroundColor:
                        selectedIndex === item?.id
                          ? buttonColor.backgroundColor
                          : 'rgba(0,0,0,0.1)',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <i
                      style={{
                        color:
                          theme.palette.mode === 'dark' ||
                          selectedIndex === item?.id
                            ? '#fff'
                            : '#050505',
                        fontSize: 15
                      }}
                      className={item.icon}
                    ></i>
                  </div>
                )}

                {match.params.keyFriend === 'suggestions' ? (
                  renderPathSuggestions(item)
                ) : (
                  <>
                    {!item.icon && (
                      <AvatarSocial
                        type="feed"
                        style={{ width: 50, height: 50 }}
                        avatarObj={item.avatar_media}
                      />
                    )}

                    <ListItemText
                      style={{ marginLeft: 10 }}
                      primary={
                        <Typography
                          className={classes.textPrimary}
                          style={{
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            width: '175px'
                          }}
                        >
                          {item?.label
                            ? item?.label
                            : item?.display_name
                            ? item?.display_name
                            : item?.username}
                          {item?.certified ? (
                            <Tooltip
                              placement="top-start"
                              title="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                              arrow
                              sx={{
                                '& .MuiTooltip-tooltip': {
                                  fontSize: '14px',
                                  padding: '10px'
                                }
                              }}
                            >
                              <VerifiedIcon
                                // titleAccess="Tài khoản đã xác minh"
                                fontSize="small"
                                viewBox="0 -2 24 24"
                                sx={{
                                  color: buttonColor.backgroundColor,
                                  fontSize: '14px',
                                  marginLeft: '8px',
                                  '&:hover': {
                                    color: buttonColor.backgroundColor
                                  }
                                }}
                              />
                            </Tooltip>
                          ) : null}
                          {!item.certified && item.identity_verification ? (
                            <Tooltip
                              placement="top-start"
                              title="Emso đã xác nhận tài khoản này đã được xác minh danh tính"
                              arrow
                            >
                              <VerifiedIcon
                                // titleAccess="Tài khoản đã xác minh"
                                fontSize="small"
                                viewBox="0 -2 24 24"
                                sx={{
                                  color: 'hsla(0, 0%, 50%)',
                                  fontSize: '14px',
                                  '&:hover': {
                                    color: 'hsla(0, 0%, 60%)'
                                  }
                                }}
                              />
                            </Tooltip>
                          ) : null}
                        </Typography>
                      }
                      secondary={
                        !item.icon ? (
                          <Typography className={classes.textSecondary}>
                            {item?.relationships?.mutual_friend_count ?? 0} bạn
                            chung
                          </Typography>
                        ) : null
                      }
                    />
                  </>
                )}
              </ListItem>
            }
          />
        ))}
      </List>
    );
  };

  const renderListFriend = list => {
    return (
      <List className={classes.list} component="nav" aria-label="sidebar">
        {list?.map((item: any, index) => (
          <ItemListData
            index={index}
            item={item}
            selectedIndex={selectedIndex}
            handleAction={handleAction}
            setOpenConfirm={setOpenConfirm}
          />
        ))}
      </List>
    );
  };

  React.useEffect(() => {
    if (match.params?.keyFriend) {
      setSelectedIndex(match.params?.keyFriend);
    } else {
      setSelectedIndex('friends');
    }
  }, [match.params?.keyFriend]);

  React.useEffect(() => {
    setListFriend([]);
    setHasMore(true);
    if (meInfo && Object.keys(meInfo).length) {
      if (selectedIndex === 'list') {
        fetchFriendUser(null, 'list');
      } else if (selectedIndex === 'suggestions') {
        fetchFriendUser(null, 'suggestions');
      }
    }
  }, [selectedIndex, JSON.stringify(meInfo)]);

  React.useEffect(() => {
    if (selectedIndex === 'list') {
      if (keyword) {
        setHasMore(true);
        setListFriend([]);
        getSearchFriendData({
          keyword: keyword
        });
      } else {
        setHasMore(true);
        getSearchFriendData({
          keyword: null
        });
      }
    }
  }, [keyword]);

  const renderFriendBar = () => {
    if (selectedIndex === 'friends') {
      return (
        <>
          <div className={classes.wrapHeader}>
            <Typography className={classes.rootTitle}>Bạn bè</Typography>
          </div>
          <div className={classes.wrapContent}>
            <div className={classes.wrapMenu}>{renderList(listMenuBar)}</div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{ justifyContent: 'flex-start' }}
            className={classes.wrapHeader}
          >
            <div
              onClick={() => history.push('/friends')}
              className={classes.iconExit}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </div>{' '}
            &nbsp;
            <Typography className={classes.rootTitle}>
              {listMenuBar?.find((el: any) => el.id === selectedIndex)?.label}
            </Typography>
          </div>
          {selectedIndex === 'suggestions' && (
            <>
              <Typography
                style={{ margin: '12px 10px', fontSize: 17, fontWeight: 500 }}
              >
                Những người mà bạn có thể biết
              </Typography>
              <Typography
                style={{
                  margin: '10px',
                  fontSize: 15,
                  fontWeight: 500,
                  color: '#65676b'
                }}
              >
                Hãy chọn bạn bè bạn quan tâm để xem trang cá nhân bạn nhé
              </Typography>
            </>
          )}
          {selectedIndex === 'list' ? (
            <>
              <div style={{ margin: '10px 12px' }}>
                <InputSearch
                  keyword={keyword}
                  setKeyword={setKeyword}
                  label="Tìm kiếm bạn bè"
                />
              </div>
              <div style={{ height: 10 }}></div>
              <Divider style={{ margin: '0 12px' }} />
              <div style={{ margin: '10px 12px 0px 12px' }}>
                <Typography style={{ fontSize: 20, fontWeight: 600 }}>
                  {selectedIndex === 'list'
                    ? meInfo?.friends_count
                    : listFriend.length}{' '}
                  bạn bè
                </Typography>
              </div>
            </>
          ) : null}

          {selectedIndex === 'requests' ? (
            <div className={classes.wrapContent}>
              <FriendRequest
                setOpenSnackbar={setOpenSnackbar}
                setNoti={setNoti}
              />
            </div>
          ) : (
            <div
              style={{ height: 'calc(100vh - 205px)' }}
              className={classes.wrapContent}
              id="scrollableFriend"
            >
              <InfiniteScroll
                dataLength={listFriend.length}
                next={funcLoad}
                hasMore={hasMore}
                loader={<SkeletonFriendItem />}
                style={{ overflow: 'hidden', marginRight: 10, width: '100%' }}
                scrollableTarget="scrollableFriend"
                scrollThreshold={0.7}
              >
                <div style={{ margin: '5px 0' }} className={classes.wrapMenu}>
                  {listFriend.length ? (
                    renderListFriend(listFriend)
                  ) : !hasMore && !listFriend.length ? (
                    <Typography sx={{ paddingLeft: '6px', fontWeight: '500' }}>
                      {keyword
                        ? 'Không tìm thấy bạn bè của bạn.'
                        : 'Không có bạn bè nào của bạn.'}
                    </Typography>
                  ) : null}
                </div>
              </InfiniteScroll>
            </div>
          )}
        </>
      );
    }
  };
  return (
    <Box
      className={classes.root}
      sx={{ backgroundColor: 'background.primary' }}
    >
      {renderFriendBar()}

      {anchorEl && (
        <MenuAction
          anchorEl={anchorEl}
          openPopper={openPopper}
          setOpenPopper={setOpenPopper}
          handleCloseMenu={handleCloseMenu}
          listMenu={listMenu}
        />
      )}

      {openConfirm && (
        <DialogConfirm
          open={openConfirm}
          handleClose={() => setOpenConfirm(false)}
          title={`Hủy kết bạn với ${
            listFriend.find((el: any) => el.id === match.params.userId)
              ?.display_name
          }`}
          text={`Bạn có chắc chắn muốn xóa ${
            listFriend.find((el: any) => el.id === match.params.userId)
              ?.display_name
          } khỏi danh sách bạn bè không?`}
          labelButton="Xác nhận"
          action={() => {
            handleAction('unFriend');
          }}
          loading={loadingConfirm}
          setLoading={setLoadingConfirm}
        />
      )}
      {openSnackbar && (
        <SnackbarNotification
          open={openSnackbar}
          setOpen={setOpenSnackbar}
          code={noti?.code}
          message={noti?.message}
        />
      )}
    </Box>
  );
};

export default FriendBar;
