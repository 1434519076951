import moment from 'moment';

// params for function diff of moment
type TimeUnit =
  | 'years'
  | 'months'
  | 'weeks'
  | 'days'
  | 'hours'
  | 'minutes'
  | 'seconds';

let dayOfWeekArray: any = [
  { eng: 'Mon', vi: 'Thứ hai' },
  { eng: 'Tue', vi: 'Thứ ba' },
  { eng: 'Wed', vi: 'Thứ tư' },
  { eng: 'Thu', vi: 'Thứ năm' },
  { eng: 'Fri', vi: 'Thứ sáu' },
  { eng: 'Sat', vi: 'Thứ bảy' },
  { eng: 'Sun', vi: 'Chủ nhật' }
];
let monOfYear: any = [
  { eng: 'Jan', num: 1 },
  { eng: 'Feb', num: 2 },
  { eng: 'Mar', num: 3 },
  { eng: 'Apr', num: 4 },
  { eng: 'May', num: 5 },
  { eng: 'Jun', num: 6 },
  { eng: 'Jul', num: 7 },
  { eng: 'Aug', num: 8 },
  { eng: 'Sep', num: 9 },
  { eng: 'Oct', num: 10 },
  { eng: 'Nov', num: 11 },
  { eng: 'Dec', num: 12 }
];
const days = [
  'CHỦ NHẬT',
  'THỨ HAI',
  'THỨ BA',
  'THỨ TƯ',
  'THỨ NĂM',
  'THỨ SÁU',
  'THỨ BẢY'
];
const months = [
  'THÁNG 1',
  'THÁNG 2',
  'THÁNG 3',
  'THÁNG 4',
  'THÁNG 5',
  'THÁNG 6',
  'THÁNG 7',
  'THÁNG 8',
  'THÁNG 9',
  'THÁNG 10',
  'THÁNG 11',
  'THÁNG 12'
];

export const RegText = /^[^!@#$%^&*(),.?":{}|<>\/'\\;[\]=+_\-]*$/;

export const upCaseFirstString = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatTimeToUTC = (value: string) => {
  try {
    let time = moment(value).toISOString();
    let strTime = time.slice(0, -5);
    let timeFormat = strTime + 'Z';
    return timeFormat;
  } catch (error) {
    return '';
  }
};
export const formatNumberVND = (price: number): any => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND'
  }).format(price);
};
export const collapseString = (text: string, value: number) => {
  let strCollapse = text;
  if (text?.length > value) {
    strCollapse = text.slice(0, value) + '...';
  }
  return strCollapse;
};

export const formatDateTimeToDatePicker = (values: any) => {
  var d = new Date(values),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
export const formatDateTimeToDate = (values: any) => {
  var d = new Date(values),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${day} Tháng ${month}, ${year}`;
};
export const formatDateTimeToStringDateTime = (values: any, addTime: any) => {
  if (values) {
    var d = new Date(values),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    var time: any = values.toString().slice(11, 16);

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    // Lấy ngày hiện tại
    var today = new Date();
    var todayDay = '' + today.getDate(),
      todayMonth = '' + (today.getMonth() + 1),
      todayYear = today.getFullYear();

    if (todayMonth.length < 2) todayMonth = '0' + todayMonth;
    if (todayDay.length < 2) todayDay = '0' + todayDay;

    // Kiểm tra xem ngày chọn có phải là hôm nay không
    if (day === todayDay && month === todayMonth && year === todayYear) {
      return 'Hôm nay';
    }

    return `${
      addTime === true ? time + ', ' : ''
    }${day} tháng ${month}, ${year}`;
  }
};

export const formatDateTimeToStringDateTimeYear = (values: any) => {
  var d = new Date(values),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return `${day} tháng ${month}`;
};

// 2024-07-22T14:22:04.150+07:00 => 22-07-2024
export function convertISO8086toLDate(dateISO8086) {
  return dateISO8086 ? moment(dateISO8086).format('DD-MM-YYYY') : '';
}

// 2024-07-22T14:22:04.150+07:00 => 14:22
export function convertISO8086toLTime(dateISO8086) {
  return moment(dateISO8086).format('HH:mm');
}

// 2024-07-22T14:22:04.150+07:00 => 14:22 22-07-2024
export function formTimeDate(dateISO8086) {
  return `${convertISO8086toLTime(dateISO8086)} ${convertISO8086toLDate(
    dateISO8086
  )}`;
}

export function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

export function convertISO8601toStringDay(ISOTime: any) {
  let dayWeek: any = dayOfWeekArray.find(
    (el: any) => el.eng === ISOTime?.toString()?.slice(0, 3)
  )?.vi;

  var d = new Date(ISOTime),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (dayWeek) {
    return `${dayWeek}, ${day} tháng ${month}, ${year}`;
  } else {
    return ` ${day} tháng ${month}, ${year}`;
  }
}
export function convertISO8601toStringOnlyDay(ISOTime: any) {
  var d = new Date(ISOTime),
    day = '' + d.getDate();

  return `${day} `;
}
//Fri Sep 30 2022 06:30:00 GMT+0700 (Giờ Đông Dương) to Thứ 5, 14 tháng 5, 2022
export function convertISO8601RegionToStringDay(date: any) {
  let dayWeek: any = dayOfWeekArray.find(
    (el: any) => el.eng === date.slice(0, 3)
  )?.vi;
  let day: any = date.slice(8, 10);
  let month: any = monOfYear.find(
    (el: any) => el.eng === date.slice(4, 7)
  )?.num;
  let year: any = date.slice(11, 15);
  return `${dayWeek}, ${day}/${month}/${year}`;
}
//2022-09-30T06:30:00.000+07:00 to 30/09/2022
export function convertISO8601StandardToStringDay(date: any) {
  let dayWeek: any = dayOfWeekArray.find(
    (el: any) => el.eng === date.slice(0, 3)
  )?.vi;
  let day: any = date.slice(8, 10);
  let month: any = monOfYear.find(
    (el: any) => el.eng === date.slice(4, 7)
  )?.num;
  let year: any = date.slice(11, 15);
  return `${dayWeek}, ${day}/${month}/${year}`;
}

export function convertISO8601StringDayToDate(date: any) {
  let hours: any = Number(date.slice(11, 13)) + 7;
  let minutes: string = date.slice(14, 16);
  let day: string = date.slice(8, 10);
  let month: string = date.slice(5, 7);
  let year: string = date.slice(0, 4);
  const dateObject: any = moment(date);
  if (dateObject.isSame(moment(), 'day')) {
    return `${hours > 12 ? hours - 12 : hours} : ${minutes} ${
      hours > 12 ? 'PM' : 'AM'
    } `;
  }
  if (dateObject.isSame(moment(), 'year')) {
    return `${day} Tháng ${month}`;
  }
  return `${day} Tháng ${month}, ${year}`;
}

export function convertISOToFormattedDate(isoString) {
  var date = new Date(isoString);

  var hours = date.getHours();
  var minutes = date.getMinutes().toString().padStart(2, '0');
  var day = date.getDate().toString().padStart(2, '0');
  var month = (date.getMonth() + 1).toString().padStart(2, '0');
  var year = date.getFullYear();

  var formattedDate =
    hours + ':' + minutes + ' ' + day + '/' + month + '/' + year;

  return formattedDate;
}

// 2023-10-17T12:00:00.000+07:00 => "THỨ BA, 17 THÁNG 10, 2023 VÀO 12:00"
export function formatDateTimeDetailCampaign(inputDateTime) {
  const date = new Date(inputDateTime);
  const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${dayOfWeek}, ${dayOfMonth} ${month}, ${year} VÀO ${hours
    .toString()
    .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

// futureTime: "2023-10-19T20:57:00.000+07:00" | "2023-10-19 20:57"
// Nhận vào thời gian futureTime => số ngày và giờ từ hiện tại đến futureTime
export function formatTimeDifference(futureTime) {
  if (!moment(futureTime, moment.ISO_8601, true).isValid()) {
    return 'Thời gian không hợp lệ';
  }

  const currentTime = moment();
  const future = moment(futureTime);
  const duration = moment.duration(future.diff(currentTime));

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();

  return `${days} ngày ${hours} giờ`;
}

export const handleRenderTime = (
  dataObject,
  type,
  timeCompare: TimeUnit,
  responseTimeLimit: number = 2 // Số ngày Shop phải xử lý yêu cầu trả hàng(tham số code cũ)
) => {
  const currentDate = moment();

  // Ngày tạo yêu cầu
  const requestCreationDate = moment(dataObject?.created_at);

  // Ngày các yêu cầu được thay đổi
  const changeRequestDate = moment(dataObject?.updated_at);

  // Cộng thêm thời gian là thời gian shop phải xử lý yêu cầu đầu tiên
  const storeMustHandleFirstDate = requestCreationDate
    .clone()
    .add(responseTimeLimit, timeCompare);

  const formattedFirstDate = storeMustHandleFirstDate.format('DD-MM-YYYY');

  // Cộng thêm thời gian cho các trạng thái tiếp theo sau khi shop đã xử lý
  const nextStatesDate = changeRequestDate
    .clone()
    .add(responseTimeLimit, timeCompare);

  const formattedNextStatesDate = nextStatesDate.format('DD-MM-YYYY');

  // Tính số thời gian giữa 2 mốc thời gian này. Với đơn hàng mới được tạo( bước đầu tiên, trạng thái pending), dự liệu
  // có thể thay đổi khiến trường updated_at luôn thay đổi không phù hợp với yêu câu. Sau khi đơn hàng được chấp nhận thì
  // updated_at chỉ thay đổi khi status thay đổi. Trường hợp dataObj là dữ liệu của refund_request thì chỉ thay
  // đổi status nên có thể dùng updated_at từ bước đầu tiên
  const timesDifference = currentDate.diff(
    dataObject?.status === 'pending' ? requestCreationDate : changeRequestDate,
    timeCompare
  );

  const responseTimeRemaining =
    timesDifference <= responseTimeLimit
      ? responseTimeLimit - timesDifference
      : '-1';

  if (type === 'reply') {
    let text = '';
    switch (dataObject?.status) {
      case 'shop_waiting':
        text = `Phản hồi trước yêu cầu trước ${formattedFirstDate}`;
        break;
      case 'buyer_cancel':
        text = `Người mua đã huỷ yêu cầu`;
        break;
      case 'shop_approval':
        text = `Trả hàng trước trước ${formattedNextStatesDate}`;
        break;
      case 'shop_rejected':
        text = `Người mua phản hồi trước ${formattedNextStatesDate}`;
        break;
      case 'received_product_refund':
        text = `Xem xét và hoàn tiền trước ${formattedNextStatesDate}`;
        break;
      case 'buyer_rejected':
        text = `EMSO đang xử lý yêu cầu`;
        break;
      case 'second_shop_approval':
        text = `Tiền đã được hoàn cho người mua`;
        break;
      case 'second_shop_rejected':
        text = `EMSO đang xử lý yêu cầu`;
        break;
      case 'emso_rejected':
        text =
          'Khiếu nại người mua thất bại. Tiền không được hoàn lại cho người mua';
        break;
      case 'emso_approved':
        text =
          'Khiếu nại người mua thành công. Tiền được hoàn lại cho người mua';
        break;

      default:
        text = '';
    }

    return text;
  } else if (type === 'remaining') {
    return responseTimeRemaining;
  } else {
    return '';
  }
};

export const handleReturnStatusSeller = refund => {
  const refundStatus = refund?.status;

  switch (refundStatus) {
    case 'shop_waiting':
      return 'Xác nhận yêu cầu';
    case 'buyer_cancel':
      return 'Người mua huỷ hoàn hàng';
    case 'shop_approval':
      return 'Chờ người mua trả hàng';
    case 'shop_rejected':
      return 'Bạn đã từ chối yêu cầu';
    case 'received_product_refund':
      return 'Hàng đã được hoàn trả';
    case 'buyer_approval':
      return 'Người mua đồng ý với quyết định của bạn';
    case 'buyer_rejected':
      return 'Người mua không chấp nhận với quyết định của bạn';
    case 'second_shop_approval':
      return 'Hoàn tiền thành công';
    case 'second_shop_rejected':
      return 'Đã nhận hàng và từ chối yêu cầu';
    case 'emso_rejected':
      return 'Từ chối hoàn tiền thành công';
    case 'emso_approved':
      return 'Từ chối hoàn tiền thất bại';
    default:
      return 'Thiếu trạng thái (cần bổ sung)';
  }
};

export const renderStatusOrder = (
  detailOrder,
  typeUser: 'buyer' | 'seller'
) => {
  // const isBuyer = typeUser === 'buyer';

  const status = detailOrder?.status;
  const refund = detailOrder?.refund_request;
  const paymentStatus = detailOrder?.payment_status;
  const refundStatus = refund?.status;

  const textRefundType =
    refund?.refund_type === 'refund' ? 'hoàn tiền' : 'trả hàng và hoàn tiền';

  switch (status) {
    case 'pending':
      if (paymentStatus === 'unpaid') {
        return 'Chờ thanh toán';
      } else {
        return 'Chờ xác nhận';
      }
    case 'order_confirmed':
      return 'Đã xác nhận';
    case 'transport':
      return 'Vận chuyển';
    case 'wait_shipping_units':
      return 'Chờ lấy hàng';
    case 'shipping_units_picked_up':
      return 'Đã lấy hàng';
    case 'shipping':
      return 'Đang giao';
    case 'delivered':
      return 'Đã giao hàng thành công';
    case 'finish':
      return 'Hoàn thành';
    case 'cancelled':
      return 'Đã hủy';
    case 'return':
      switch (refundStatus) {
        case 'shop_waiting':
          return `Shop đang xem xét yêu cầu ${textRefundType}`;
        case 'buyer_cancel':
          return `Bạn đã huỷ yêu cầu ${textRefundType}`;
        case 'shop_approval':
          return `Chờ người mua trả hàng`;
        case 'shop_rejected':
          return `Shop không đồng ý yêu cầu ${textRefundType}`;
        case 'second_shop_approval':
          return `Shop đã nhận hàng và đồng ý yêu cầu ${textRefundType}`;
        case 'second_shop_rejected':
          return `Shop đã nhận hàng và từ chối yêu cầu ${textRefundType}`;
        case 'buyer_approval':
          return `Bạn đã chấp nhận việc Shop từ chối yêu cầu ${textRefundType}`;
        case 'buyer_rejected':
          return 'Bạn đã từ chối khiếu nại của Shop. Đơn hàng đang được EMSO xử lý';
        case 'emso_rejected':
          return `EMSO đã từ chối yêu cầu ${textRefundType} của bạn`;
        case 'emso_approved':
          return `EMSO đã đồng ý yêu cầu ${textRefundType} của bạn`;

        default:
          return 'Erorr Refund';
      }

    case 'delivery_failed':
      return 'Giao hàng không thành công';

    default:
      return 'Erorr';
  }
};
