import React from 'react';
import _ from 'lodash';
import {
  Popper,
  Theme,
  Typography,
  IconButton,
  TextField,
  Divider,
  Tooltip,
  List,
  Box,
  useMediaQuery,
  Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ClickAwayListener } from '@mui/base';
import PopupState from 'material-ui-popup-state';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { urlRocketChat } from 'src/util/config';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import {
  searchConversation,
  searchConversationGroup,
  getInfoMeChat,
  searchConversationChatPage,
  getListNewPendingMessages
} from 'src/apis/socialChat.apis';
import { postStatusUser } from 'src/apis/socialUser';

import {
  getListConversationReq,
  getInfoMeChatSuccess,
  settingChat,
  getListConversationsChatPageReq

  // getListConversationsChatPageSuccess
} from 'src/store/action/socialChatAction';
import { changeStatusActive } from 'src/socket/RocketChatSubscriptions';
import SuspenseConversation from 'src/pages/Chat/GlobalPopupChat/SuspenseConversation';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import Conversation from 'src/pages/Chat/GlobalPopupChat/Conversation';

import CustomizedMenus from 'src/components/Label';
import { DeleteOutlined } from '@mui/icons-material';
import { buttonColor } from 'src/constants/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxHeight: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    border:
      theme.palette.mode === 'light'
        ? '0.5px solid #bdbdbd'
        : '0.5px solid #242526',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.16)'
  },
  header: {
    width: '100%',
    height: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 16px 4px 16px',
    alignItems: 'center'
  },
  searchBox: {
    width: '100%',
    height: '52px !important',
    margin: '8px 0px',
    padding: '0px 16px',
    display: 'flex',
    alignItems: 'center'
  },
  optionChat: {},
  body: {
    width: '100%',
    height: '100%',
    padding: '5px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    }
  },
  footer: {
    width: '100%',
    height: '42px',
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 0px'
  },
  cssInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    '& fieldset': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C'
    },
    backgroundColor: theme.palette.mode === 'light' ? '#F0F2F5' : '#3A3B3C',
    borderRadius: '50px !important',
    height: 35,
    padding: '7px !important',
    margin: 'auto'
  },
  styleScrollBar: {
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    }
  }
}));

let popupId = JSON.stringify(Math.random());
const SearchBox = props => {
  const {
    classes,
    keyword,
    setKeyword,
    isShowMatches,
    searchInput,
    type,
    pageId,
    setFilterConversationChatPageLabel,
    filterConversationChatPageLabel,
    handleDelete,
    setChecked,
    checked
  } = props;
  return (
    <div className={classes.searchBox} style={{ padding: '0px 12px' }}>
      {keyword.length > 0 ? (
        <IconButton
          onClick={() => setKeyword('')}
          sx={{ width: '36px', height: '36px', marginRight: '5px' }}
        >
          <i
            style={{ fontSize: '18px' }}
            className="fa-solid fa-arrow-left"
          ></i>
        </IconButton>
      ) : null}
      <div
        style={{
          margin: '6px 0px',
          width: '100%',
          display: isShowMatches,

          gap: 2
        }}
      >
        <TextField
          inputRef={searchInput}
          value={keyword}
          // style={{ border: 'none' }}
          size="small"
          fullWidth
          variant="outlined"
          placeholder="Tìm kiếm trên EmsoChat"
          InputLabelProps={{ shrink: false }}
          InputProps={{
            startAdornment: (
              <i
                style={{
                  marginRight: 5,
                  padding: 5,
                  fontWeight: 500,
                  color: '#7b7b7b'
                }}
                className="fa-light fa-magnifying-glass"
              ></i>
            ),
            classes: {
              root: classes.cssInput
            }
          }}
          onChange={e => {
            setKeyword(e.target.value);
          }}
          autoFocus={
            type === 'chatPage' || type === 'fullScreen' ? false : true
          }
          autoCorrect="off"
          autoComplete="off"
        />
        {type === 'chatPage' ? (
          <CustomizedMenus
            teamId={pageId}
            label={filterConversationChatPageLabel}
            setLabel={setFilterConversationChatPageLabel}
            handleDeleteFilter={handleDelete}
            checked={checked}
            setChecked={setChecked}
          />
        ) : null}
      </div>
    </div>
  );
};
function GlobalPopupChat(props) {
  const {
    anchorEl,
    setAnchorEl,
    styleRoot,
    styleBox,
    type,
    handleClosePreview,
    styleBody,
    pageId
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const listRef: any = React.useRef();
  const theme = useTheme();
  const history = useHistory();
  const [keyword, setKeyword] = React.useState<string>('');
  const [listResultSearch, setListResultSearch] = React.useState<any>([]);
  const [listNewMessagePending, setListNewMessagePending] = React.useState<any>(
    []
  );
  const [checked, setChecked] = React.useState<any>([]);

  const [hasMoreMessagePending, setHasMoreMessagePending] =
    React.useState<boolean>(true);
  const [isLoadingSearchConversation, setIsLoadingSearchConversation] =
    React.useState<boolean>(true);
  const [numberCurrentPage, setNumberCurrentPage] = React.useState<any>(1);
  const [openAwaitMessage, setOpenAwaitMessage] =
    React.useState<boolean>(false);
  const [filterConversationChatPageLabel, setFilterConversationChatPageLabel] =
    React.useState<any>([]);
  const listConversations = useSelector(
    (state: any) => state.socialChatReducer.listConversations
  );

  const listConversationsChatPage = useSelector(
    (state: any) => state.socialChatReducer.listConversationsChatPage
  );

  const hasMore = useSelector((state: any) => state.socialChatReducer.hasMore);
  const lastUpdated = useSelector(
    (state: any) => state.socialChatReducer.lastUpdatedConversation
  );
  const active =
    useSelector((state: any) => state.socialChatReducer.me)?.status ?? null;

  const settingsChat =
    useSelector((state: any) => state.socialChatReducer.settingChat) ?? null;
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const matches = useMediaQuery('(min-width:924px)');

  React.useEffect(() => {
    if (!active) {
      getInfoChat();
    }
  }, [active]);

  const searchInput: any = React.useRef(null);

  React.useEffect(() => {
    if (keyword) {
      handleSearch();
    } else {
      setIsLoadingSearchConversation(true);
      setListResultSearch([]);
    }
  }, [keyword]);

  const Header = () => {
    const actionHeaders = [
      {
        title: 'Tùy chọn',
        icon: 'fa-solid fa-ellipsis',
        options: [
          [
            {
              title: 'Cài đặt Chat',
              description: 'Tùy chỉnh trải nghiệm trên Emso Chat',
              styleTitle: { margin: '0px 11px' }
            }
          ],
          [
            {
              label: `Âm thanh cuộc gọi đến: ${
                settingsChat.allow_call_sound ? 'đang bật' : 'đang tắt'
              }`,
              id: 'soundCall',
              icon: 'fa-light fa-phone-volume',
              action: () => {
                handleChangeSettingChat('soundCall');
              }
            },
            {
              label: `Âm thanh tin nhắn: ${
                settingsChat.allow_chat_sound ? 'đang bật' : 'đang tắt'
              }`,
              id: 'soundChat',
              icon: 'fa-light fa-volume',
              action: () => {
                handleChangeSettingChat('soundChat');
              }
            },
            {
              label: `Tự động mở tin nhắn mới: ${
                settingsChat.auto_open_chatbox ? 'đang bật' : 'đang tắt'
              }`,
              id: 'autoOpenChatBox',
              icon: 'fa-light fa-ball-pile',
              action: () => {
                handleChangeSettingChat('openChatBox');
              }
            }
          ],
          [
            {
              label: `Trạng thái hoạt động: ${
                active === 'online' ? 'đang bật' : 'đang tắt'
              }`,
              id: 'statusActive',
              icon: 'fa-light fa-rss',
              styleIcon: {
                fontSize: 18,
                color: theme.palette.mode === 'dark' ? '#f9f9f9' : '#464646',
                margin: '3px'
              },
              action: () => {
                handleChangeStatusActive();
              }
            },
            {
              label: 'Tin nhắn chờ',
              id: 'waitingMessages',
              icon: 'fa-light fa-messages',
              styleIcon: {
                fontSize: 18,
                color: theme.palette.mode === 'dark' ? '#f9f9f9' : '#464646',
                margin: '3px'
              },
              action: () => {
                setOpenAwaitMessage(true);
              }
            },
            // {
            //   label: 'Đoạn chat lưu trữ',
            //   id: 'savedConversation',
            //   icon: 'fa-light fa-box-archive',
            //   styleIcon: {
            //     fontSize: 18,
            //     color: theme.palette.mode === 'dark' ? '#f9f9f9' : '#464646',
            //     margin: '3px'
            //   }
            // },
            // {
            //   label: 'Cài đặt gửi tin nhắn',
            //   id: 'settingMessage',
            //   icon: 'fa-light fa-gear',
            //   styleIcon: {
            //     fontSize: 18,
            //     color: theme.palette.mode === 'dark' ? '#f9f9f9' : '#464646',
            //     margin: '3px'
            //   }
            // },
            {
              label: 'Cài đặt chặn',
              id: 'blockSetting',
              icon: 'fa-light fa-ban',
              styleIcon: {
                fontSize: 18,
                color: theme.palette.mode === 'dark' ? '#f9f9f9' : '#464646',
                margin: '3px'
              },
              action: () => {
                history.push('/settings/block');
              }
            }
          ]
        ]
      },
      type !== 'fullScreen'
        ? {
            title: 'Xem tất cả trong EmsoChat',
            icon: 'fa-solid fa-maximize',
            action: () => {
              history.push(
                listConversations.length > 0
                  ? `/messages/${listConversations[0]?.rid}`
                  : '/messages'
              );
            }
          }
        : {
            styleButtonHeader: {
              display: 'none'
            }
          },
      // {
      //   title: 'Tạo phòng họp mặt mới',
      //   icon: 'fa-solid fa-video-plus',
      //   action: () => {
      //     // console.log('create new room');
      //   }
      // },
      type === 'fullScreen'
        ? {
            title: 'Tạo tin nhắn mới',
            icon: 'fa-solid fa-pen-to-square',
            action: () => {
              history.replace(`/messages/news`);
            }
          }
        : {
            styleButtonHeader: {
              display: 'none'
            }
          }
    ];

    return (
      <Box
        className={classes.header}
        sx={{ backgroundColor: 'background.primary' }}
      >
        <Typography fontWeight="700" fontSize="24px">
          Chat
        </Typography>
        <div style={{ display: isShowMatches, alignItems: 'center' }}>
          {actionHeaders.map((el: any, index: any) => {
            return (
              <Tooltip
                key={index}
                title={String(el.title ? el.title : '')}
                sx={{
                  width: '40px',
                  height: '44px',
                  margin: 0,
                  padding: '6px 6px'
                }}
              >
                <div>
                  <IconButtonOptions
                    icon={
                      <i
                        style={{
                          fontSize: '18px',
                          color:
                            theme.palette.mode === 'light'
                              ? '#3f434a'
                              : '#a8abaf',
                          padding: '3px'
                        }}
                        className={el.icon}
                      />
                    }
                    openPopup={el.options}
                    options={el.options}
                    style={{
                      width: '32px !important',
                      height: '32px !important',
                      borderRadius: '50% !important',
                      backgroundColor:
                        theme.palette.mode === 'light' ? '#fff' : '#242526',
                      '&:hover': {
                        backgroundColor:
                          theme.palette.mode === 'light' ? '#fff' : '#242526'
                      },
                      ...el.styleButtonHeader
                    }}
                    styleListMenu={{
                      width: '350px',
                      padding: '10px 3px 15px'
                    }}
                    stylePopup={{ paddingTop: '10px', zIndex: 10000 }}
                    disablePortal={false}
                    action={el.action}
                  />
                </div>
              </Tooltip>
            );
          })}
        </div>
      </Box>
    );
  };
  const isShowMatches =
    matches && (type === 'fullScreen' || 'chatPage')
      ? 'flex'
      : type === undefined
      ? 'flex'
      : 'none';

  let [countLoadMoreOffSetChatPage, setCountLoadMoreOffSetChatPage] =
    React.useState<number>(0);

  const funcLoadMoreConversations = async () => {
    let limit: number = 10;
    if (hasMore) {
      dispatch(getListConversationReq(limit, lastUpdated));
    }
  };
  const loadMorePendingMessage = async () => {
    let data = {
      currentPage: numberCurrentPage + 1
    };
    if (hasMoreMessagePending) {
      getListNewMessagePending(data);
    }
  };
  const funcLoadMoreConversationsChatPage = () => {
    setCountLoadMoreOffSetChatPage(countLoadMoreOffSetChatPage++);
    const offset = countLoadMoreOffSetChatPage * 10;

    let count: number = 10;
    if (hasMore) {
      dispatch(getListConversationsChatPageReq(pageId, count, offset));
    }
  };

  const funcLoad = () => {
    type === 'chatPage'
      ? funcLoadMoreConversationsChatPage()
      : funcLoadMoreConversations();
  };

  const debounceSearchRocketChat = React.useCallback(
    _.debounce(async function (q) {
      let trimString = q.trim();
      if (type !== 'chatPage' && trimString) {
        let response = await searchConversation(trimString);
        let responseGr = await searchConversationGroup(trimString);
        if (response.status === 200 && responseGr.status === 200) {
          let listConversations = response.data.map(el => {
            const draftElementResponse = { ...el };
            if (el.t && el.t === 'p') {
              draftElementResponse.type = 'group';
              draftElementResponse.avatar_media = {
                avatar_top: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.uids[0]}`,
                avatar_bottom: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.uids[1]}`
              };
              draftElementResponse.rid = el._id;
              draftElementResponse.fname = el.real_name ?? el.fname;
            } else {
              draftElementResponse.fname = el.name;
              draftElementResponse.avatar_media = {
                show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el._id}`
              };
              draftElementResponse.userId = el._id;
              draftElementResponse.type = 'user';
            }

            return draftElementResponse;
          });
          setIsLoadingSearchConversation(false);
          setListResultSearch(listConversations);
        }
        searchInput.current?.focus();
      } else {
        try {
          const response = await searchConversationChatPage(q, pageId);
          if (response.status === 200) {
            const listConversationsCP = response.data.map(el => {
              el.displayName = el.u?.name;
              el.avatar_media = {
                show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el.u._id}`
              };
              el.is_user_chat = true;
              el.userId = el.u._id;

              return el;
            });
            setIsLoadingSearchConversation(false);
            setListResultSearch(listConversationsCP);

            // dispatch(
            //   getListConversationsChatPageSuccess(listConversationsCP, pageId)
            // );
          }
          searchInput.current?.focus();
        } catch (e) {}
      }
    }, 500),
    []
  );
  const handleSearch = () => {
    debounceSearchRocketChat(keyword);
    searchInput.current?.focus();
  };

  const getInfoChat = async () => {
    try {
      const response = await getInfoMeChat();

      if (response.status === 200) {
        dispatch(
          getInfoMeChatSuccess({
            status: response.data.status,
            id: meInfo.id
          })
        );
      }
    } catch (error) {}
  };
  const getListNewMessagePending = async data => {
    try {
      const response = await getListNewPendingMessages(data);
      if (response.status === 200) {
        if (
          response.data.Subscriptions.fullInfoSubscription.length ===
          response.data.Subscriptions.totalCount
        ) {
          setHasMoreMessagePending(false);
        }
        setListNewMessagePending(
          response.data.Subscriptions.fullInfoSubscription
        );
      }
    } catch (error) {
      console.log('errr', error);
    }
  };

  React.useEffect(() => {
    let data = {
      currentPage: numberCurrentPage
    };
    getListNewMessagePending(data);
  }, []);
  const handleChangeStatusActive = () => {
    const nextStatus = active === 'online' ? 'invisible' : 'online';
    changeStatusActive(nextStatus);
  };

  let setting;
  const handleChangeSettingChat = async (type: string) => {
    switch (type) {
      case 'soundCall':
        setting = await {
          allow_call_sound: settingsChat.allow_call_sound ? false : true
        };
        try {
          const response = await postStatusUser(setting);

          if (response.status === 200) {
            dispatch(
              settingChat({
                allow_call_sound: !settingsChat.allow_call_sound,
                allow_chat_sound: settingsChat.allow_chat_sound,
                auto_open_chatbox: settingsChat.auto_open_chatbox
              })
            );
          }
        } catch (error) {
          console.log('[]', error);
        }
        break;
      case 'soundChat':
        setting = await {
          allow_chat_sound: settingsChat.allow_chat_sound ? false : true
        };
        try {
          const response = await postStatusUser(setting);

          if (response.status === 200) {
            dispatch(
              settingChat({
                allow_call_sound: settingsChat.allow_call_sound,
                allow_chat_sound: !settingsChat.allow_chat_sound,
                auto_open_chatbox: settingsChat.auto_open_chatbox
              })
            );
          }
        } catch (error) {
          console.log('[]', error);
        }
        break;
      case 'openChatBox':
        setting = await {
          auto_open_chatbox: settingsChat.auto_open_chatbox ? false : true
        };
        try {
          const response = await postStatusUser(setting);

          if (response.status === 200) {
            dispatch(
              settingChat({
                allow_call_sound: settingsChat.allow_call_sound,
                allow_chat_sound: settingsChat.allow_chat_sound,
                auto_open_chatbox: !settingsChat.auto_open_chatbox
              })
            );
          }
        } catch (error) {
          console.log('[]', error);
        }
        break;
      default:
        console.log('type not found');
    }
  };

  const handleDelete = chipToDelete => {
    setFilterConversationChatPageLabel(chips =>
      chips.filter(chip => chip.title !== chipToDelete.title)
    );

    setChecked(prev => prev.filter(chip => chip !== chipToDelete.title));
  };

  const fetchFilterConversationChatPage = async () => {
    try {
      const label = filterConversationChatPageLabel.map(el => el.title);
      dispatch(getListConversationsChatPageReq(pageId, undefined, 0, label));
    } catch (error) {
      console.log({ error });
    }
  };

  React.useEffect(() => {
    if (
      type === 'chatPage' &&
      pageId &&
      filterConversationChatPageLabel &&
      filterConversationChatPageLabel.length > 0
    ) {
      fetchFilterConversationChatPage();
    } else if (
      type === 'chatPage' &&
      pageId &&
      filterConversationChatPageLabel.length === 0
    ) {
      dispatch(getListConversationsChatPageReq(pageId, 10));
    }
  }, [filterConversationChatPageLabel]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (type !== 'fullScreen' && type !== 'chatPage') {
          setAnchorEl(null);
        }
      }}
    >
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl === true ? null : anchorEl}
        placement="bottom"
        disablePortal={true}
        style={{
          borderRadius: '8px',
          paddingRight: '30px',
          zIndex: '1000',
          ...styleRoot
        }}
      >
        {openAwaitMessage ? (
          <Box
            className={classes.root}
            sx={{
              backgroundColor: 'background.primary',
              ...styleBox,
              minWidth:
                matches && (type === 'fullScreen' || 'chatPage')
                  ? '360px !important'
                  : type === undefined
                  ? '360px !important'
                  : '88px !important'
            }}
          >
            <Box
              className={classes.header}
              sx={{
                justifyContent: 'space-around !important',
                alignItems: 'center !important'
              }}
            >
              <IconButton
                sx={{ padding: '4px' }}
                onClick={() => setOpenAwaitMessage(false)}
              >
                <ArrowBackIcon sx={{ fontSize: 30 }} />
              </IconButton>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '700',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'
                }}
              >
                Tin nhắn đang chờ
              </Typography>
              <IconButton
                sx={{ padding: '4px' }}
                onClick={() =>
                  history.push(
                    listConversations.length > 0
                      ? `/messages/${listConversations[0]?.rid}`
                      : '/messages'
                  )
                }
              >
                <Tooltip title="Xem tất cả trong EmsoChat" arrow>
                  <i
                    style={{ fontSize: '24px' }}
                    className="fa-solid fa-maximize"
                  ></i>
                </Tooltip>
              </IconButton>
            </Box>
            <Box
              className={classes.body}
              id="scrollableDivGlobalChat"
              style={
                (type === 'fullScreen' && listConversations.length === 0) ||
                (type === 'chatPage' &&
                  listConversationsChatPage.length === 0 &&
                  filterConversationChatPageLabel.length === 0)
                  ? {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      ...styleBody
                    }
                  : { ...styleBody }
              }
            >
              <List
                id="scrollableDivGlobalChat"
                className={classes.styleScrollBar}
                ref={listRef}
              >
                {/* feat tin nhắn chờ */}
                {listNewMessagePending.length ? (
                  <InfiniteScroll
                    dataLength={listNewMessagePending.length}
                    next={loadMorePendingMessage}
                    hasMore={hasMoreMessagePending}
                    loader={<SuspenseConversation />}
                    scrollableTarget="scrollableDivGlobalChat"
                    scrollThreshold={0.8}
                    style={{
                      display: 'flex',
                      overflow: 'hidden',
                      flexDirection: 'column'
                    }}
                  >
                    {type !== 'chatPage' && (
                      <Box>
                        {listNewMessagePending.map((el: any, index) => {
                          const newConversation = {
                            ...el,
                            avatar_media: {
                              show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${el?.lastMessage?.u?._id}`
                            }
                          };
                          return (
                            <PopupState
                              variant="popover"
                              popupId={popupId}
                              key={index}
                            >
                              {PopupState => (
                                <Box sx={{ width: '100%', height: 'auto' }}>
                                  <Conversation
                                    handleClosePreview={handleClosePreview}
                                    conversation={newConversation}
                                    key={index}
                                    handleClose={() =>
                                      setAnchorEl(prev => (prev = null))
                                    }
                                    type={'mess-pending'}
                                    listConversations={listNewMessagePending}
                                    setListNewMessagePending={
                                      setListNewMessagePending
                                    }
                                    pageId={pageId}
                                  />
                                </Box>
                              )}
                            </PopupState>
                          );
                        })}
                      </Box>
                    )}
                  </InfiniteScroll>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    Không tìm thấy tin nhắn.
                  </div>
                )}
              </List>
            </Box>

            {/* footer */}

            <Divider />
            <div
              className={classes.footer}
              onClick={() => {
                if (type !== 'fullScreen' && type !== 'chatPage') {
                  setAnchorEl(null);
                  history.push(
                    listConversations.length > 0
                      ? `/messages/${listConversations[0]?.rid}`
                      : '/messages'
                  );
                }
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: buttonColor.backgroundColor,
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'none'
                  }
                }}
              >
                {type !== 'chatPage' ? (
                  matches ? (
                    'Xem tất cả trong Chat'
                  ) : (
                    <i className="fa-solid fa-desktop-arrow-down"></i>
                  )
                ) : null}
              </Typography>
            </div>
          </Box>
        ) : (
          <Box
            className={classes.root}
            sx={{
              backgroundColor: 'background.primary',
              ...styleBox,
              width:
                matches && (type === 'fullScreen' || 'chatPage')
                  ? '360px !important'
                  : type === undefined
                  ? '360px !important'
                  : '100px !important',

              border: 'unset !important',
              boxShadow:
                '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)'
            }}
          >
            {type !== 'chatPage' && <Header />}

            <SearchBox
              classes={classes}
              keyword={keyword}
              setKeyword={setKeyword}
              isShowMatches={isShowMatches}
              searchInput={searchInput}
              type={type}
              pageId={pageId}
              setFilterConversationChatPageLabel={
                setFilterConversationChatPageLabel
              }
              handleDelete={handleDelete}
              filterConversationChatPageLabel={filterConversationChatPageLabel}
              setChecked={setChecked}
              checked={checked}
            />
            {type !== 'chatPage' && <Divider />}
            <Box
              className={classes.body}
              id="scrollableDivGlobalChat"
              style={
                (type === 'fullScreen' && listConversations.length === 0) ||
                (type === 'chatPage' &&
                  listConversationsChatPage.length === 0 &&
                  filterConversationChatPageLabel.length === 0)
                  ? {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      ...styleBody
                    }
                  : { ...styleBody }
              }
            >
              <List
                id="scrollableDivGlobalChat"
                className={classes.styleScrollBar}
                ref={listRef}
              >
                {keyword.length < 1 ? (
                  listConversations.length ||
                  listConversationsChatPage.length > 0 ? (
                    <InfiniteScroll
                      dataLength={
                        type === 'chatPage'
                          ? listConversationsChatPage?.length
                          : listConversations?.length
                      }
                      next={funcLoad}
                      hasMore={hasMore}
                      loader={<SuspenseConversation />}
                      scrollableTarget="scrollableDivGlobalChat"
                      scrollThreshold={0.8}
                      style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column'
                      }}
                    >
                      {type !== 'chatPage' &&
                      listNewMessagePending.length > 0 &&
                      matches ? (
                        <Box
                          sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '8px 4px',
                            '&:hover': {
                              backgroundColor:
                                theme.palette.mode === 'dark'
                                  ? 'rgba(255,255,255,.1)'
                                  : '#E8E8EB',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              '& #iconMessage': {
                                backgroundColor:
                                  theme.palette.mode === 'dark'
                                    ? 'rgba(255,255,255,.1)'
                                    : '#F1F1F9 !important'
                              }
                            }
                          }}
                          onClick={() => {
                            setOpenAwaitMessage(true);
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <Box
                                sx={{
                                  width: '56px',
                                  height: '56px',
                                  borderRadius: '100%',
                                  backgroundColor:
                                    theme.palette.mode === 'dark'
                                      ? 'rgba(255,255,255,.1)'
                                      : '#E4E6EB',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                                id="iconMessage"
                              >
                                <i className="fa-solid fa-messages fa-lg"></i>
                              </Box>
                            </Box>
                            <Box sx={{ marginLeft: '12px' }}>
                              <Typography
                                sx={{ fontWeight: 500, fontSize: '14px' }}
                              >
                                Tin nhắn đang chờ mới
                              </Typography>
                              <Box>
                                <Typography
                                  sx={{ fontWeight: 400, fontSize: '13px' }}
                                >
                                  Từ{' '}
                                  {`${listNewMessagePending[0].fname} ${
                                    listNewMessagePending.length >= 2
                                      ? `và ${
                                          listNewMessagePending.length - 1
                                        } người khác`
                                      : ``
                                  }`}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>

                          <Box sx={{ paddingRight: '8px' }}>
                            <i className="fa-solid fa-angle-right"></i>
                          </Box>
                        </Box>
                      ) : null}
                      {type !== 'chatPage' &&
                      listConversations.filter((el: any) => el.f === true)
                        .length > 0 ? (
                        <Box>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              padding: '4px 12px'
                            }}
                          >
                            Quan trọng
                          </Typography>
                          {listConversations
                            .filter((el: any) => el.f === true)
                            .map((el: any, index: any) => {
                              return (
                                <PopupState
                                  variant="popover"
                                  popupId={popupId}
                                  key={index}
                                >
                                  {PopupState => (
                                    <Box sx={{ width: '100%', height: 'auto' }}>
                                      <Conversation
                                        handleClosePreview={handleClosePreview}
                                        conversation={el}
                                        key={index}
                                        handleClose={() =>
                                          setAnchorEl(prev => (prev = null))
                                        }
                                        type={type}
                                        listConversations={
                                          type === 'chatPage'
                                            ? listConversationsChatPage
                                            : listConversations
                                        }
                                        pageId={pageId}
                                        typeBoxChat={type}
                                      />
                                    </Box>
                                  )}
                                </PopupState>
                              );
                            })}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2
                          }}
                        >
                          {filterConversationChatPageLabel.map(
                            (option: any, index) => (
                              <Chip
                                sx={{
                                  '& .MuiChip-labe': {
                                    fontSize: '15px',
                                    fontWeight: 450
                                  }
                                }}
                                icon={
                                  <LocalOfferIcon
                                    sx={{
                                      width: '18px',
                                      height: '18px',
                                      color: `${option.color} !important`
                                    }}
                                  />
                                }
                                onDelete={() => handleDelete(option)}
                                deleteIcon={
                                  <Tooltip title="Xóa khỏi bộ lọc">
                                    <DeleteOutlined
                                      style={{ color: option.color }}
                                    />
                                  </Tooltip>
                                }
                                label={option.title}
                              />
                            )
                          )}
                        </Box>
                      )}

                      <Box>
                        {(type === 'chatPage'
                          ? listConversationsChatPage
                          : listConversations
                        ).filter(el => el.f !== true) &&
                        ((type !== 'chatPage' &&
                          listConversations.length > 0) ||
                          (type === 'chatPage' &&
                            listConversationsChatPage.length > 0 &&
                            filterConversationChatPageLabel.length === 0)) ? (
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              padding: '4px 12px'
                            }}
                          >
                            Cuộc hội thoại
                          </Typography>
                        ) : (
                          type === 'chatPage' &&
                          filterConversationChatPageLabel.length > 0 &&
                          listConversationsChatPage.length === 0 && (
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                padding: '4px 12px',
                                width: '100%',
                                textAlign: 'center'
                              }}
                            >
                              Không có kết quả nào khớp với nhãn của bạn
                            </Typography>
                          )
                        )}
                        {(type === 'chatPage'
                          ? listConversationsChatPage
                          : listConversations
                        )
                          .filter(el => el.f !== true)
                          .map((el, index) => {
                            return (
                              <PopupState
                                variant="popover"
                                popupId={popupId}
                                key={index}
                              >
                                {PopupState => (
                                  <Box sx={{ width: '100%', height: 'auto' }}>
                                    <Conversation
                                      handleClosePreview={handleClosePreview}
                                      conversation={el}
                                      key={index}
                                      handleClose={() =>
                                        setAnchorEl(prev => (prev = null))
                                      }
                                      type={type}
                                      listConversations={
                                        type === 'chatPage'
                                          ? listConversationsChatPage
                                          : listConversations
                                      }
                                      pageId={pageId}
                                      typeBoxChat={type}
                                    />
                                  </Box>
                                )}
                              </PopupState>
                            );
                          })}
                      </Box>
                    </InfiniteScroll>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      Không tìm thấy tin nhắn.
                    </div>
                  )
                ) : (
                  <>
                    {isLoadingSearchConversation && keyword ? (
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          gap: 1,
                          flexDirection: 'column',
                          justifyContent: 'center',
                          overflow: 'hidden'
                        }}
                      >
                        <SuspenseConversation />
                        <SuspenseConversation />
                        <SuspenseConversation />
                      </Box>
                    ) : listResultSearch.length > 0 ? (
                      listResultSearch.map((el, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{ width: '100%', height: 'auto' }}
                          >
                            <Conversation
                              handleClosePreview={handleClosePreview}
                              type="search"
                              conversation={el}
                              key={index}
                              handleClose={() =>
                                setAnchorEl && setAnchorEl(null)
                              }
                              typeBoxChat={type}
                              styleConversation={{ overflow: 'hidden' }}
                            />
                          </Box>
                        );
                      })
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        Không tìm thấy cuộc trò chuyện.
                      </div>
                    )}
                  </>
                )}
              </List>
            </Box>

            {/* footer */}

            <Divider />
            {/* <div
              className={classes.footer}
              onClick={() => {
                if (type !== 'fullScreen' && type !== 'chatPage') {
                  setAnchorEl(null);
                  history.push(
                    listConversations.length > 0
                      ? `/messages/${listConversations[0]?.rid}`
                      : '/messages'
                  );
                }
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: buttonColor.backgroundColor,
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'none'
                  }
                }}
              >
                {type !== 'chatPage' ? (
                  matches ? (
                    'Xem tất cả trong Chat'
                  ) : (
                    <i className="fa-solid fa-desktop-arrow-down"></i>
                  )
                ) : null}
              </Typography>
            </div> */}
          </Box>
        )}
      </Popper>
    </ClickAwayListener>
  );
}

export default React.memo(GlobalPopupChat);
