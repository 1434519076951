import React from 'react';
import _ from 'lodash';
import {
  Chip,
  Theme,
  Typography,
  TextField,
  ListItem,
  ListItemButton,
  Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { urlRocketChat } from 'src/util/config';
import { collapseString } from 'src/common/string';
import {
  sendMessageApi,
  createDirectMessage,
  sendMessageUploadFile,
  createGroupChat
} from 'src/apis/socialChat.apis';
import { searchUserGlobal } from 'src/apis/socialSearch.api';
import {
  closeBoxCreate,
  eventMessageFromChatPage,
  receiveMessage,
  selectedUserBoxChat
} from 'src/store/action/socialChatAction';

import { boxShadow, buttonColor } from 'src/constants/styles';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import ThumbListFile from 'src/pages/Chat/BoxChat/ThumbListFile';
import Footer from 'src/pages/Chat/BoxChat/Footer';
import IconButtonOptions from 'src/components/Button/IconButtonOption';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '338px',
    height: '445px',
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px 8px 0 0',
    justifyContent: 'space-between',
    zIndex: 10000
  },
  rootHeader: {
    width: '100%',
    height: 'auto',
    boxShadow: '0px 2px #d8d8df',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '48px'
  },
  headerSearch: {
    display: 'flex',
    width: '100%',
    height: 'auto'
  },
  inputSearch: {
    maxWidth: 'calc(100% - 60px)'
  },
  responseSearch: {
    // minWidth: '260px',
    // maxWidth: '305px',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
      borderRadius: '100px'
    },
    display: 'flex',
    flexDirection: 'column'
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    backgroundColor: '#e7f3ff',
    color: buttonColor.backgroundColor,
    fontWeight: '700',
    fontSize: '14px'
  },
  rootFooter: {
    width: '100%',
    // height: '60px',
    maxHeight: '164px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: 0,
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    bottom: 0
  }
}));

function BoxCreateChat() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [key, setKey] = React.useState('');
  const [listConversation, setListConversation] = React.useState<any>([]);
  const [listConversationSelected, setListConversationSelected] =
    React.useState<any>([]);
  const [gifSelected, setGifSelected] = React.useState<any>(null);
  const [stickySelected, setStickySelected] = React.useState<any>(null);
  const [currentUserSelect, setCurrentUserSelect] = React.useState(0);
  const inputRef: any = React.useRef();

  const initialConversation = useSelector(
    (state: any) => state.socialChatReducer.initialConversationBoxChatCreate
  );

  React.useEffect(() => {
    if (Object.keys(initialConversation).length !== 0) {
      setListConversationSelected([
        ...listConversationSelected,
        initialConversation
      ]);
    }
  }, [JSON.stringify(initialConversation)]);

  const handleCloseBox = () => {
    dispatch(closeBoxCreate());
  };

  const debounceSearchChat = React.useCallback(
    _.debounce(async function (q) {
      let params = {
        type: 'accounts',
        q,
        offset: 1,
        limits: 20
      };
      let response: any = await searchUserGlobal(params);
      if (response.status === 200) {
        let listConversations = _.cloneDeep(response.data.accounts).map(el => {
          el.fname = el.display_name;
          el.userName = el.acct;
          el.userId = el.id;
          return el;
        });
        setListConversation(listConversations);
      }
    }, 400),
    []
  );

  const handleSelectConversation = item => {
    setListConversation([]);
    setKey('');
    if (!listConversationSelected.map(el => el.id).includes(item.id)) {
      setListConversationSelected([...listConversationSelected, item]);
    }
    setCurrentUserSelect(0);
  };
  React.useEffect(() => {
    setListConversation([]);
  }, [listConversationSelected.length]);
  const handleRemoveConversation = item => {
    setListConversationSelected(pre => {
      const newState = pre.filter(el => el.id !== item.id);
      return newState;
    });
  };

  const renderChips = () => {
    return (
      <>
        {listConversationSelected.map(item => (
          <Box key={item?._id}>
            <Chip
              sx={{
                height: '32px !important',
                maxWidth: '230px',
                backgroundColor: 'background.custom',
                color: buttonColor.backgroundColor,
                fontWeight: '500',
                fontSize: '14px',
                borderRadius: '6px',
                margin: '4px 4px 4px 0px'
              }}
              tabIndex={-1}
              label={collapseString(item.fname, 30)}
              className={classes.chip}
              onDelete={() => handleRemoveConversation(item)}
              deleteIcon={
                <i
                  style={{ fontSize: '14px' }}
                  className="fa-solid fa-xmark"
                ></i>
              }
            />
          </Box>
        ))}
      </>
    );
  };

  const formik = useFormik({
    initialValues: {
      files: []
    },
    onSubmit: values => {
      if (values.files.length > 0) {
        values.files.map((el: any) => {
          handleUploadFileApi(el);
        });
      }
      formik.resetForm();
    }
  });

  const handleSendMessage = async contentMessage => {
    const id = uuidv4();

    if (contentMessage) {
      handleSendMessageApi(contentMessage, id);
    }
    formik.submitForm();
  };
  function removeSpecialCharactersFromArray(inputArray) {
    const regex = /\s*[\uD800-\uDBFF][\uDC00-\uDFFF]\s*/g;
    return inputArray.replace(regex, '');
  }
  const handleCreateGroupChat = async () => {
    try {
      const roomName = listConversationSelected
        .map(el => {
          return removeSpecialCharactersFromArray(el.fname).split(' ')[
            removeSpecialCharactersFromArray(el.fname).split(' ').length - 1
          ];
        })
        .join(', ');
      const listMember = listConversationSelected.map(el => el.username);
      const response = await createGroupChat(roomName, listMember);

      if (response.status === 200) {
        return response.data.group;
      }
    } catch (error) {
      return null;
    }
  };
  const handleSendMessageApi = async (message: any, id: any) => {
    try {
      if (listConversationSelected.length < 1) {
        return;
      } else if (listConversationSelected.length === 1) {
        const _response = await createDirectMessage(
          listConversationSelected[0]?.username
        );
        if (_response.status === 200) {
          const roomId = JSON.parse(_response.data.message).result.rid;
          const convertIso8601ToTimestamp = timestampString => {
            const timestamp = Date.parse(timestampString);
            return timestamp;
          };
          const response = await sendMessageApi(
            message,
            roomId,
            undefined,
            undefined,
            id
          );
          if (response.status === 200) {
            const selectUseChat = JSON.parse(_response.data.message).result;

            const conversationSelected = {
              ...selectUseChat,
              fname: listConversationSelected[0].fname,
              avatar_media: {
                show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
                  listConversationSelected[0]?.id ??
                  listConversationSelected[0]?.userId
                }`
              },
              rid: roomId,
              lastMessage: {
                ...response?.data?.message,
                ts: {
                  $date: convertIso8601ToTimestamp(response.data.message.ts)
                },
                _updatedAt: {
                  $date: convertIso8601ToTimestamp(
                    response.data.message._updatedAt
                  )
                }
              },
              blocked: false,
              blocker: 0,
              userId: listConversationSelected[0].userId,
              username: listConversationSelected[0].username,
              type: 'user',
              t: 'd',
              unread: 1,
              createBoxChat: true
            };

            dispatch(selectedUserBoxChat(conversationSelected));

            handleCloseBox();
          }
        }
      } else {
        const room = await handleCreateGroupChat();
        if (room) {
          const _response = await sendMessageApi(message, room._id);
          const convertIso8601ToTimestamp = timestampString => {
            const timestamp = Date.parse(timestampString);
            return timestamp;
          };
          if (_response.status === 200) {
            dispatch(
              selectedUserBoxChat({
                fname: room.fname,
                avatar_media: {
                  avatar_top: {
                    show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${room?.uids?.[0]}`
                  },
                  avatar_bottom: {
                    show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${room?.uids?.[1]}`
                  }
                },
                lastMessage: {
                  ..._response.data.message,
                  ts: {
                    $date: convertIso8601ToTimestamp(_response.data.message.ts)
                  },
                  _updatedAt: {
                    $date: convertIso8601ToTimestamp(
                      _response.data.message._updatedAt
                    )
                  }
                },
                blocked: false,
                blocker: 0,
                rid: room._id,
                _id: room._id,
                type: 'group',
                t: 'p',
                createBoxChat: true,
                unread: 1
              })
            );
            handleCloseBox();
          }
        }
      }
    } catch (error) {
      console.log('error sendMessage', error);
    }
  };

  const handleUploadFileApi = async file => {
    try {
      let room;
      if (listConversationSelected.length > 1) {
        room = await handleCreateGroupChat();
      } else {
        let response = await createDirectMessage(
          listConversationSelected[0]?.username
        );

        room = JSON.parse(response.data.message).result.rid;
        const conversationSelected = {
          fname: listConversationSelected[0].fname,
          avatar_media: {
            show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${
              listConversationSelected[0]?.id ??
              listConversationSelected[0]?.userId
            }`
          },
          rid: room,
          lastMessage: {
            msg: '',
            unread: true
          },
          blocked: false,
          blocker: 0,
          userId: listConversationSelected[0].userId,
          username: listConversationSelected[0].username,
          type: 'user',
          t: 'd',
          unread: 1,
          ...JSON.parse(response.data.message).result
        };
        dispatch(selectedUserBoxChat(conversationSelected));
      }

      if (room) {
        const response = await sendMessageUploadFile(
          '',
          file,
          listConversationSelected.length > 1 ? room._id : room
        );

        if (response.status === 200) {
          if (listConversationSelected.length > 1) {
            dispatch(
              selectedUserBoxChat({
                fname: room.fname,
                avatar_media: {
                  show_url: `${urlRocketChat}/avatar/${room.name}}`
                },
                rid: room._id,
                _id: room._id,
                type: 'group'
              })
            );
          }
          handleCloseBox();
        }
      }
    } catch (error) {
      console.log(' error:', error);
    }
  };
  React.useEffect(() => {
    const id = uuidv4();

    if (gifSelected) {
      handleSendMessageApi(gifSelected, id);
      setGifSelected(null);
    } else if (stickySelected) {
      handleSendMessageApi(stickySelected.url, id);
      setStickySelected(null);
    }
  }, [JSON.stringify(gifSelected), JSON.stringify(stickySelected)]);
  const { getRootProps, getInputProps, open } = useDropzone({
    // accept: '*',
    maxFiles: 5,
    noClick: true,
    onDrop: (acceptedFiles: any) => {
      setGifSelected(null);
      setStickySelected(null);
      let fileSelected: any = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      let preFiles = formik.values.files;
      formik.setFieldValue('files', [...preFiles, ...fileSelected]);
    }
  });

  const handleRemoveFile = (file: any) => {
    const newFiles: any = formik.values.files;
    newFiles.splice(newFiles.indexOf(file), 1);
    formik.setFieldValue('files', newFiles);
  };
  const handleOnKeyDown = event => {
    if (event.keyCode === 13 || event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      //enter

      handleSelectConversation(listConversation[currentUserSelect]);
    } else if (event.keyCode === 38) {
      setCurrentUserSelect(prev =>
        prev === 0 ? listConversation.length - 1 : prev - 1
      );
    } else if (event.keyCode === 40) {
      setCurrentUserSelect(prev =>
        prev === listConversation.length - 1 ? 0 : prev + 1
      );
    }
  };

  const calculatedWidth = `${Math.max(key.length * 10, 80)}px`;
  return (
    <Box
      className={classes.root}
      sx={{
        backgroundColor: 'background.primary',
        boxShadow:
          '0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)'
      }}
    >
      <div className={classes.rootHeader}>
        <div className={classes.headerTitle}>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '14px',
              marginLeft: '10px'
            }}
          >
            Tin nhắn mới
          </Typography>
          <IconButtonOptions
            icon={
              <i
                className="fa-solid fa-xmark"
                style={{
                  color: '#909395',
                  fontSize: 13
                }}
              ></i>
            }
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '15px',
              alignItems: 'center',
              backgroundColor: 'button.secondary.background',
              right: '8px',
              margin: 0
            }}
            action={handleCloseBox}
          ></IconButtonOptions>
        </div>
        <div className={classes.headerSearch}>
          <div style={{ display: 'flex' }}>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '15px',
                marginLeft: '10px',
                width: '60px',
                display: 'flex',
                padding:
                  listConversationSelected?.length > 0
                    ? '10px 0px 0px 4px'
                    : '5px 0px 0px 0px'
              }}
            >
              Đến :
            </Typography>
          </div>
          <Box sx={{}}>
            {renderChips()}
            <TextField
              autoFocus
              variant="standard"
              aria-autocomplete="list"
              sx={{
                '& .MuiInput-root': {
                  alignItems: 'end',
                  width: calculatedWidth,
                  maxWidth: '200px',
                  '&::before': {
                    borderBottom: 'unset !important'
                  },
                  '&::after': {
                    borderBottom: 'unset !important'
                  }
                }
              }}
              onChange={e => {
                setKey(e.target.value);

                debounceSearchChat(e.target.value);
              }}
              onKeyDown={(event: any) => {
                handleOnKeyDown(event);
              }}
              value={key}
              className={classes.inputSearch}
            />
          </Box>
        </div>
      </div>

      <div className={classes.responseSearch}>
        {listConversation.map((el: any, index) => {
          return (
            <ListItem
              key={el.id}
              sx={{ height: '52px', width: '100%', padding: '0' }}
            >
              <ListItemButton
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  padding: '8px',
                  backgroundColor:
                    index === currentUserSelect ? 'background.secondary' : null
                }}
                onClick={() => handleSelectConversation(el)}
              >
                <AvatarSocial
                  style={{ width: '36px', height: '36px', marginTop: 0 }}
                  avatarObj={el.avatar_media}
                />
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    marginLeft: '-10px'
                  }}
                >
                  {el.fname}
                </Typography>
              </ListItemButton>
            </ListItem>
          );
        })}
      </div>

      {formik.values.files.length > 0 && (
        <ThumbListFile
          files={formik.values.files}
          handleRemoveFile={file => handleRemoveFile(file)}
        />
      )}

      {listConversationSelected.length > 0 ? (
        <div className={classes.rootFooter}>
          <Footer
            handleSendMessage={handleSendMessage}
            formik={formik}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            open={open}
            setGifSelected={setGifSelected}
            setStickySelected={setStickySelected}
            gifSelected={gifSelected}
            stickySelected={stickySelected}
            listConversationSelected={listConversationSelected}
            createBoxChat={true}
            handleCreateGroupChat={handleCreateGroupChat}
            inputRef={inputRef}
          />
        </div>
      ) : null}
    </Box>
  );
}

export default BoxCreateChat;
