import { Badge, IconButton, Tooltip } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GlobalPopupChat from 'src/pages/Chat/GlobalPopupChat/GlobalPopupChat';
import SocialCutImage from 'src/components/SocialCutImage/SocialCutImage';
import noti from 'src/assets/images/Noti.svg';
import { getUser } from 'src/apis/socialChat.apis';
import { isSafari } from 'react-device-detect';
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      btnRightHeader: {
        margin: '0 3px !important',
        width: theme.spacing(5),
        height: theme.spacing(5),
        fontSize: '17px'
      }
    }),
  { index: 1 }
);

interface PropsType {
  _setting?: any;
  removeNotiChatConversationId?: any;
  addNotiChatConversationId?: any;
  _notiChat?: any;
  handleClose?: any;
  meInfo?: any;
}

function MessageIcon(props: PropsType) {
  const { handleClose, meInfo } = props;
  const classes = useStyles();
  const [count, setCount] = React.useState<number>(0);
  const [dataOfMe, setDataOfMe] = React.useState<any>({});
  const [listIncomingMessage, setListIncomingMessage] = React.useState<any>([]);
  const listConversations = useSelector(
    (state: any) => state.socialChatReducer.listConversations
  );
  const listPopupChat = useSelector(
    (state: any) => state.socialChatReducer.listPopupChat
  );
  const getDataOfMe = async (id: any) => {
    try {
      const response = await getUser(id);
      if (response.status === 200) {
        setDataOfMe(response.data.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (Object.keys(meInfo).length > 0) {
      getDataOfMe(meInfo.id);
    }
  }, [meInfo.id]);
  const theme = useTheme();
  React.useEffect(() => {
    if (listConversations) {
      let countConversationUnread = 0;
      let data: any = [];
      const timestampLogin = new Date(dataOfMe?.lastLogin).getTime();
      listConversations.map(el => {
        if (
          el?.unread > 0 &&
          el?.lastMessage?.u?._id !== meInfo?.id &&
          !el?.disableNotifications
        ) {
          countConversationUnread += 1;
        }
        if (
          el?.lastMessage?.u?._id !== meInfo?.id &&
          el?.lastMessage?._updatedAt?.$date > timestampLogin &&
          el?.unread
        ) {
          data.push(el);
        }
      });

      setListIncomingMessage(data);
      setCount(countConversationUnread);
    }
  }, [JSON.stringify(listConversations)]);

  React.useEffect(() => {
    if (listIncomingMessage && listIncomingMessage.length) {
      if (
        listPopupChat.length > 0 &&
        listPopupChat[0]?.conversation?.disableNotifications
      ) {
        if (listIncomingMessage?.[0]?.lastMessage?.u.name === 'Poll') {
          const displayNameUser =
            listConversations[
              listConversations.indexOf(listIncomingMessage[0]._id)
            ].member[
              listConversations[
                listConversations.indexOf(listIncomingMessage[0]._id)
              ].member.indexOf(listIncomingMessage?.[0]?.u.username)
            ].name.split(' ')[0];
          document.title = `(${listIncomingMessage.length}) ${displayNameUser} đang vote poll trong nhóm ${listIncomingMessage?.[0]?.fname}`;
        } else {
          document.title = `(${listIncomingMessage.length}) ${
            listIncomingMessage?.[0].type === 'chat_page'
              ? listIncomingMessage?.[0]?.displayName
              : listIncomingMessage?.[0]?.lastMessage?.u.name.split(' ')[0]
          } đã nhắn tin đến ${
            listIncomingMessage?.[0].type === 'group'
              ? listIncomingMessage?.[0]?.fname
              : 'bạn'
          }`;
        }
      }
    } else {
      document.title = 'Mạng xã hội Emso - Mạng xã hội vì người Việt';
    }
  }, [listIncomingMessage, listConversations, listPopupChat]);

  const dispatch = useDispatch();

  const [openPopupConversation, setOpenPopupConversation] =
    React.useState<null | HTMLElement>(null);

  const handleClick = e => {
    if (document.title !== 'Mạng xã hội Emso - Mạng xã hội vì người Việt') {
      document.title = 'Mạng xã hội Emso - Mạng xã hội vì người Việt';
    }
    setCount(0);
    setOpenPopupConversation(e.currentTarget);
  };

  return (
    <>
      <Tooltip title="Tin nhắn">
        <IconButton
          className={classes.btnRightHeader}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            handleClick(event);
          }}
          sx={{
            backgroundColor: 'button.secondary.background',
            '&:hover': {
              backgroundColor: 'button.primary.hover'
            }
          }}
        >
          <Badge badgeContent={count} color="error">
            <SocialCutImage
              image={noti}
              item={{
                positionX: 0,
                positionY:
                  theme.palette.mode === 'dark'
                    ? isSafari
                      ? -655
                      : -663
                    : isSafari
                    ? -88
                    : -83
              }}
              zoom={0.6}
              style={{ height: '35px', width: '36px' }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      {Boolean(openPopupConversation) ? (
        <GlobalPopupChat
          anchorEl={openPopupConversation}
          setAnchorEl={setOpenPopupConversation}
          handleClosePreview={handleClose}
        />
      ) : null}
    </>
  );
}
export default React.memo(MessageIcon);
